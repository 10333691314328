import React from 'react'
import { useUid } from 'hooks/use-uid'
import { NotificationExistsOverlay } from 'components/widgets/notification-exists-overlay'
import { NavigateButton } from 'containers/navigation-bar'
import { useData } from 'hooks/use-data'
import { select } from 'util/select'
import { ProfileImage } from 'components/widgets/profile-image'
import { Link } from 'components/link'
import { useSearchBar } from 'hooks/use-search-bar'
import { useViewportWidth } from 'hooks/use-viewport-dimension'
import { breakpoints } from 'src/theme'
import { useNotificationExists } from 'hooks/use-notification-exists'
import { useIsFullSSR } from 'hooks/use-is-full-ssr'
import { useSearchHelperPanel } from 'containers/search-helper-panel'
import { usePathname } from 'hooks/use-location'

import CreateIcon from 'assets/images/svgs/create-new.svg'
import NotificationIcon from 'assets/images/svgs/notification-new.svg'
import LogoIcon from 'assets/images/svgs/logo.svg'

import * as navigationBarStyles from 'containers/navigation-bar/style.module.less'
import * as styles from './style.module.less'

interface TopNavigationBarProps {
  searchValue: string
  defaultSearchValue?: string
  onSearchValue?: (value: string) => void
}

export const TopNavigationBar = (props: TopNavigationBarProps) => {
  const pathname = usePathname()
  const { searchValue, onSearchValue, defaultSearchValue } = props
  const isFullSSR = useIsFullSSR()
  const uid = useUid()
  const [userData] = useData(uid ? `/users/${uid}` : null, {
    cacheReadConsistency: `session`,
  })

  const [headerBottomElement, onIsSearchingChange] = useSearchHelperPanel(
    searchValue,
    true,
  )

  const [searchBar] = useSearchBar({
    searchValue,
    onSearchValue,
    defaultSearchValue,
    onIsSearchingChange,
    formId: `top-navigation-bar-search-form`,
  })

  const profileImage = select(userData, `public.profile.profileImage`)

  const rootRef = React.useRef(null)

  const {
    activity: activityExists,
    commerce: commerceExists,
    reward: rewardExists,
  } = useNotificationExists()

  let activityType = `activity`
  if (activityExists) {
    activityType = `activity`
  } else if (commerceExists) {
    activityType = `commerce`
  } else if (rewardExists) {
    activityType = `reward`
  }

  const viewportWidth = useViewportWidth()
  if (viewportWidth < breakpoints.smallTablet && !isFullSSR) {
    // if ssr, render all and process visibility by mediaQuery.
    // if not ssr, since screen side doesn't change most of the time, skip rendering of the top navigation bar.
    return null
  }
  return (
    <>
      <div className={styles.topNavigationBarContainerPaddingDummy} />
      <header ref={rootRef} className={styles.rootContainer}>
        <nav className={styles.widthWrapper}>
          <div className={styles.topContainer}>
            <div className={styles.leftContainer}>
              <a
                href="/"
                className={styles.logoIconLink}
                aria-label="홈 화면으로 돌아가고 새로고침 하기"
              >
                <LogoIcon width="114px" height="30px" />
              </a>
              <Link
                to="/"
                aria-label="홈 화면으로 이동하기"
                className={`${styles.homeLink} ${
                  pathname === `/` || pathname === `/shopping`
                    ? styles.selected
                    : ``
                }`}
              >
                홈
              </Link>
              <Link
                to="/story"
                aria-label="스토리 홈 화면으로 이동하기"
                className={`${styles.homeLink} ${
                  pathname === `/story` ? styles.selected : ``
                }`}
              >
                스토리
              </Link>
            </div>
            <div className={styles.rightContainer}>
              <div className={`${styles.search} ${styles.desktop}`}>
                {searchBar}
                <div className={styles.searchHelperPanelAnchor}>
                  {headerBottomElement}
                </div>
              </div>
              <NavigateButton
                displayLabel={false}
                OnIcon={CreateIcon}
                OffIcon={CreateIcon}
                to="/p/create"
                label="공유하기"
                rel="nofollow"
                isAuthRequired
                className={navigationBarStyles.createIcon}
              />
              <NavigateButton
                displayLabel={false}
                OnIcon={NotificationIcon}
                OffIcon={NotificationIcon}
                label="활동"
                to={`/activities?type=${activityType}`}
                rel="nofollow"
                isAuthRequired
                className={navigationBarStyles.notificationIcon}
              >
                <NotificationExistsOverlay top={0} right={-2} />
              </NavigateButton>
              {uid ? (
                <ProfileImage
                  src={profileImage}
                  size={24}
                  to="/me"
                  aria-label="내 루트"
                  alt="내 프로필 사진"
                  rel="nofollow"
                  className={`${navigationBarStyles.profileImage} ${
                    pathname === `/me` ? navigationBarStyles.match : ``
                  }`}
                />
              ) : (
                <Link
                  to="/join"
                  className={styles.signinBlock}
                  aria-label="로그인하기"
                >
                  로그인
                </Link>
              )}
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}
