import { RateApp } from 'capacitor-rate-app'
import { Capacitor } from '@capacitor/core'
import { getUid } from 'model/app/auth'
import { Preferences } from '@capacitor/preferences'
import { dayjs } from 'util/time'
import { checkIfVersionSatisfied } from 'hooks/use-app-info'

declare global {
  interface Window {
    requestReview: () => void
  }
}

export const requestReview = async () => {
  if (await checkIfVersionSatisfied(`1.1.0`)) {
    return RateApp.requestReview()
  }
}

if (typeof window !== `undefined`) {
  // debug purpose
  window.requestReview = requestReview
}

export const getLastReviewRequestedAtMilliseconds = async () => {
  let lastReviewRequestedAtMilliseconds = Date.now()

  const { value: lastReviewRequestedAtMillisecondsString } =
    await Preferences.get({
      key: `lastReviewRequestedAtMillisecondsString`,
    })
  if (lastReviewRequestedAtMillisecondsString) {
    try {
      lastReviewRequestedAtMilliseconds = Number(
        lastReviewRequestedAtMillisecondsString,
      )
    } catch (e) {
      // no-op
    }
  }

  return lastReviewRequestedAtMilliseconds
}

export const getFirstEnteredAtMilliseconds = async () => {
  const { value: firstEnteredAtMillisecondsString } = await Preferences.get({
    key: `firstEnteredAtMillisecondsString`,
  })

  let firstEnteredAtMilliseconds = Date.now()
  if (firstEnteredAtMillisecondsString) {
    try {
      firstEnteredAtMilliseconds = Number(firstEnteredAtMillisecondsString)
    } catch (e) {
      // no-op
    }
  }

  return firstEnteredAtMilliseconds
}

let locked = false

export const onMainMenuEnter = async () => {
  if (!Capacitor.isNativePlatform()) {
    return
  }

  if (await checkIfVersionSatisfied(`1.1.0`)) {
    return
  }

  if (!getUid()) {
    return
  }

  if (locked) {
    return
  }
  try {
    locked = true

    const firstEnteredAtMilliseconds = await getFirstEnteredAtMilliseconds()
    const lastReviewRequestedAtMilliseconds =
      await getLastReviewRequestedAtMilliseconds()

    const currentTime = Date.now()
    if (
      // 3 days after install, 3 days after last review request
      currentTime >= firstEnteredAtMilliseconds + 3 * 24 * 60 * 60 * 1000 &&
      currentTime >= lastReviewRequestedAtMilliseconds + 3 * 24 * 60 * 60 * 1000
    ) {
      // request review!
      await Preferences.set({
        key: `lastReviewRequestedAtMillisecondsString`,
        value: `${currentTime}`,
      })
      console.log(`requesting review!`)
      await requestReview()
    }
  } finally {
    locked = false
  }
}

// debug method
export const testRequestReviewState = async () => {
  const firstEnteredAtMilliseconds = await getFirstEnteredAtMilliseconds()
  const lastReviewRequestedAtMilliseconds =
    await getLastReviewRequestedAtMilliseconds()

  const firstEnteredAtString = dayjs(firstEnteredAtMilliseconds).format(
    `YYYY-MM-DD HH:mm`,
  )
  const lastReviewRequestedAtString = dayjs(
    lastReviewRequestedAtMilliseconds,
  ).format(`YYYY-MM-DD HH:mm`)
  const confirmed = window.confirm(
    `firstEntered : ${firstEnteredAtString}, lastReviewRequested : ${lastReviewRequestedAtString}`,
  )
  if (confirmed) {
    await requestReview()
  }
}

const trySetFirstEnteredAtMillisecondsString = async () => {
  const { value: firstEnteredAtMillisecondsString } = await Preferences.get({
    key: `firstEnteredAtMillisecondsString`,
  })

  if (!firstEnteredAtMillisecondsString) {
    console.log(`setting firstEnteredAtMillisecondsString`)
    await Preferences.set({
      key: `firstEnteredAtMillisecondsString`,
      value: `${Date.now()}`,
    })
  }
}

if (typeof window !== `undefined` && Capacitor.isNativePlatform()) {
  trySetFirstEnteredAtMillisecondsString()
}
