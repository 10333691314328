import React from 'react'
import { breakpoints } from 'src/theme'
import { useViewportWidth } from 'hooks/use-viewport-dimension'
import { SearchHelperUserList } from 'containers/search-helper-user-list'
import { SearchHelperCategoryList } from 'containers/search-helper-category-list'
import { useRouteId } from 'hooks/use-route-id'
import { hookRefreshesWith } from 'util/hook-refreshes-with'
import { useSynchronousState } from 'hooks/use-synchronous-state'
import { SubmittingSpinner } from 'components/widgets/submitting-spinner'

import * as styles from './style.module.less'

/*
  1. if is desktop, render background, padding, etc.
  2. if its not desktop, else.


  If searchValue exists, render auto completion
  else, render

*/

type ListValueState = `empty` | `loading` | `exists`

export const useSearchHelperPanel = (
  searchValue: string,
  isDesktop: boolean,
): [React.ReactNode, (value: boolean) => void] => {
  const trimmedSearchValue = (searchValue && searchValue.trim()) || ``
  const [isSearchHeaderFocused, setIsSearchHeaderFocused] =
    React.useState(false)

  const routeId = useRouteId()
  React.useEffect(() => {
    hookRefreshesWith(routeId)
    setIsSearchHeaderFocused(false)
  }, [routeId])

  const viewportWidth = useViewportWidth()

  const [categoryListValueStateBox, setCategoryListValueState] =
    useSynchronousState<ListValueState>(`loading`)
  const [userListValueStateBox, setUserListValueState] =
    useSynchronousState<ListValueState>(`loading`)
  React.useMemo(() => {
    hookRefreshesWith(trimmedSearchValue)
    setCategoryListValueState(`loading`, true)
    setUserListValueState(`loading`, true)
  }, [trimmedSearchValue, setCategoryListValueState, setUserListValueState])

  const onCategoryListLoadingComplete = React.useCallback(
    (valueExists: boolean) => {
      setCategoryListValueState(valueExists ? `exists` : `empty`)
    },
    [setCategoryListValueState],
  )

  const onUserListLoadingComplete = React.useCallback(
    (valueExists: boolean) => {
      setUserListValueState(valueExists ? `exists` : `empty`)
    },
    [setUserListValueState],
  )

  const isEmpty =
    categoryListValueStateBox.current === `empty` &&
    userListValueStateBox.current === `empty`
  const isLoading =
    categoryListValueStateBox.current === `loading` ||
    userListValueStateBox.current === `loading`

  const element = React.useMemo(() => {
    if (!isSearchHeaderFocused) {
      return null
    }

    const shouldReturnNull =
      (isDesktop && viewportWidth < breakpoints.smallTablet) ||
      (!isDesktop && viewportWidth >= breakpoints.smallTablet) ||
      !trimmedSearchValue

    if (shouldReturnNull) return null

    return (
      <div className={`${styles.rootContainer} ${isEmpty ? styles.empty : ``}`}>
        {isLoading ? (
          <div className={styles.submittingSpinnerContainer}>
            <SubmittingSpinner size={12} className={styles.submittingSpinner} />
          </div>
        ) : null}
        <SearchHelperCategoryList
          searchValue={trimmedSearchValue}
          onLoadComplete={onCategoryListLoadingComplete}
        />
        <SearchHelperUserList
          searchValue={trimmedSearchValue}
          onLoadComplete={onUserListLoadingComplete}
        />
      </div>
    )
  }, [
    isDesktop,
    isSearchHeaderFocused,
    onCategoryListLoadingComplete,
    onUserListLoadingComplete,
    trimmedSearchValue,
    viewportWidth,
    isEmpty,
    isLoading,
  ])

  return [element, setIsSearchHeaderFocused]
}
