import React from 'react'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { Listener } from 'util/listener'

// vvv service worker logic
// TODO change to update button + auto update on Link

/*
  https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby/cache-dir/register-service-worker.js
  https://www.gatsbyjs.com/docs/how-to/performance/add-offline-support-with-a-service-worker/ 

  Footer information : 
    - always display webpack current version
      - commit hash
      - build date
    - serviceWorker state
      - up-to-date
      - 
    - serviceWorker action button

  create checkUpdater
  checkUpdateOn
    + fresh reload (gatsby does it on webpack load)
    + timeout
      register timeout on fresh reload! 
    + route update (internal navigation)
      - skip for first load


  onServiceWorkerUpdateFound
    => service worker is installing
      need to notify in footer.
  onServiceWorkerInstalled
    => service worker installed for the first time. No need to handle.
  onServiceWorkerUpdateReady
    => service worker download is complete and is waiting for confirmation. 
      need to fire postmessage ACTIVATE
    : initialize with reg.waiting + update with onServiceWorkerUpdateReady
      1. on navigation
        - navigation : onRouteChange // or preRouteChange
        - site enter : onClientEntry
      2. on activate button bottom of the footer
      3. on overlay message click
      on Click, start spinner that indicates activation reloading.

  # gatsby tries reload on navigation, but if any other tab is open and until calling skipWaiting, the reloaded page serves old service worker.

  onServiceWorkerActive &
    - If I'm the tab that triggered update
  controllerChange listener : 
    - If I'm another tab
      : No initial value, since new module context will use new serviceWorker's cached pages.
      : also, 
    => waiting state moved to active. triggered after skipWaiting
      reload without hesitation :)


*/

/*
    DEFAULT
        - no service worker 
        - activated with no waiting

    WAITING_ACTIVATION :
        2. activate button bottom of the footer
            => footer. on click, activateWaitingServiceWorker()
        3. overlay message click
            on Click, start spinner that indicates activation reloading.
            => overlay-message. on click, activateWaitingServiceWorker()

    ACTIVATING : 
        footer and overlay-message : display spinner!


    INSTALLING : 
        1. footer status : installing
        2. footer spinner
*/
type ServiceWorkerState =
  | `WAITING_ACTIVATION`
  | `DEFAULT`
  | `INSTALLING`
  | `ACTIVATING`

const serviceWorkerStateListener = new Listener<ServiceWorkerState>(`DEFAULT`)

declare global {
  interface Window {
    serviceWorkerStateListener?: Listener<ServiceWorkerState>
  }
}
if (typeof window !== `undefined`) {
  // debug purpose!
  window.serviceWorkerStateListener = serviceWorkerStateListener
}

// no-one using right now.
export const useServiceWorkerState = () => {
  const getIsMounted = useGetIsMounted()
  const [value, setValue] = React.useState(() =>
    serviceWorkerStateListener.getValue(),
  )

  React.useEffect(() => {
    return serviceWorkerStateListener.addListener((v) => {
      if (getIsMounted()) {
        setValue(v)
      }
    })
  }, [getIsMounted])

  return value
}
/*
    Service worker states : 

*/

/*
      1. on navigation
        - navigation : onRouteChange // or preRouteChange
        - site enter : onClientEntry
      2. on activate button bottom of the footer
      3. on overlay message click
      on Click, start spinner that indicates activation reloading.
*/
// onRouteUpdate way below.
let isActivatingWaitingServiceWorker = false
export const activateWaitingServiceWorker = async () => {
  if (isActivatingWaitingServiceWorker) {
    console.error(`already activating serviceWorker update`)
    return
  }
  isActivatingWaitingServiceWorker = true
  try {
    console.log(`activateWaitingServiceWorker`)
    const reg = await navigator?.serviceWorker?.ready
    if (reg) {
      if (reg.waiting) {
        console.log(
          `postMessaging ACTIVATE to service worker. Expecting skipWaiting.`,
        )
        serviceWorkerStateListener.setValue(`ACTIVATING`)
        reg.waiting.postMessage(`ACTIVATE`)
      } else {
        console.error(`No service worker registration is waiting`)
      }
    }
  } catch (e) {
    console.error(e)
  } finally {
    isActivatingWaitingServiceWorker = false
  }
}

export const tryActivateWaitingServiceWorker = () => {
  const currentServiceWorkerState = serviceWorkerStateListener.getValue()
  if (
    currentServiceWorkerState === `WAITING_ACTIVATION` ||
    currentServiceWorkerState === `ACTIVATING` // activating is for UI purpose :)
  ) {
    activateWaitingServiceWorker()
  }
}

/*

  onServiceWorkerActive &
    - If I'm the tab that triggered update
  controllerChange listener : 
    - If I'm another tab
      : No initial value, since new module context will use new serviceWorker's cached pages.
      : also, 
    => waiting state moved to active. triggered after skipWaiting
      reload without hesitation :)

*/
let activateReloaded = false
const reloadPageAndUpdate = () => {
  if (!activateReloaded) {
    activateReloaded = true
    // acknowledge
    // alert(`루트가 업데이트되어 페이지를 새로고침합니다.`)
    // console.log(`루트가 업데이트되어 페이지를 새로고침합니다.`)
    console.log(`루트가 서비스워커가 업데이트됐습니다.`)
    // window.location.reload()
  } else {
    console.error(`reload already activated.`)
  }
}

/*

  checkUpdateOn
    + fresh reload (gatsby does it on webpack load)
    + timeout
      register timeout on fresh reload! 
    + route update (internal navigation)
      - skip for first load
*/
let checkForUpdateIntervalHandle: number | null = null
let isCheckingForUpdate = false
let lastUpdatedAt = 0
export const checkForUpdate = async () => {
  if (isCheckingForUpdate) {
    console.error(`already checking for serviceWorker update`)
    return
  }

  if (lastUpdatedAt + 1000 * 60 * 5 > Date.now()) {
    // checkForUpdate cooldown!
    isCheckingForUpdate = false
    return
  }

  lastUpdatedAt = Date.now()
  isCheckingForUpdate = true
  try {
    console.log(`check for serviceWorker update`)
    const reg = await navigator?.serviceWorker?.ready
    if (reg) {
      await reg.update()
      console.log(`service worker registration check resolve`)
    }
  } catch (e) {
    console.error(e)
  } finally {
    isCheckingForUpdate = false
  }
}

/*
onServiceWorkerUpdateReady
    => service worker download is complete and is waiting for confirmation. 
      need to fire postmessage ACTIVATE
    : initialize with reg.waiting + update with onServiceWorkerUpdateReady
      1. on navigation
        - navigation : onRouteChange // or preRouteChange
        - site enter : onClientEntry
      2. on activate button bottom of the footer
      3. on overlay message click
      on Click, start spinner that indicates activation reloading.
*/

// must be called from gatsby-browser.ts
export const onServiceWorkerUpdateReady = () => {
  // updates state : WAITING_ACTIVATION
  console.log(
    `service worker install complete. Setting WAITING_ACTIVATION. source : onServiceWorkerUpdateReady`,
  )
  serviceWorkerStateListener.setValue(`WAITING_ACTIVATION`)
}

/*
    => service worker is installing
      need to notify in footer.
      display spinner.
*/
// must be called from gatsby-browser.ts
export const onServiceWorkerUpdateFound = () => {
  console.log(`serviceWorker update found. Installing.`)
  serviceWorkerStateListener.setValue(`INSTALLING`)
}

// must be called from gatsby-browser.ts
export const onServiceWorkerActive = () => {
  console.log(
    `reloading to load page with new serviceWorker. source : onServiceWorkerActive`,
  )
  reloadPageAndUpdate()
}

// must be called from gatsby-browser.ts
export const onRouteUpdate = () => {
  checkForUpdate() // update
  /*
    Service worker install was too abrupt!!
    If service worker handle parts need to be updated, this one is.
  */
  tryActivateWaitingServiceWorker() // postMessage
}

if (typeof window !== `undefined` && typeof navigator !== `undefined`) {
  // update
  if (!checkForUpdateIntervalHandle) {
    checkForUpdateIntervalHandle = window.setInterval(() => {
      checkForUpdate()
    }, 1000 * 60 * 60)
  }

  tryActivateWaitingServiceWorker() // postMessage

  // reload
  /*
    reload only if controller existsted and new one activated.
  */
  try {
    console.log(
      `registering navigator serviceworker event listeners and waiting for registration`,
    )
    navigator?.serviceWorker?.addEventListener(`controllerchange`, () => {
      console.log(
        `reloading to load page with new serviceWorker. source : controllerchange event`,
      )
      reloadPageAndUpdate()
    })

    // detect already waiting serviceWorker
    navigator?.serviceWorker?.ready.then((reg) => {
      if (reg.waiting) {
        console.log(
          `service worker install complete. Setting WAITING_ACTIVATION. source : intializer reg.waiting check`,
        )
        serviceWorkerStateListener.setValue(`WAITING_ACTIVATION`)
      }
    })
  } catch (e) {
    console.error(e)
  }
}
