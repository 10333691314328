import React from 'react'
import { Listener } from 'util/listener'
import { useGetter } from 'hooks/use-getter'

export const onPreNavigateCounterListener = new Listener<number>(0)

export const useOnPreNavigate = (callback: () => void) => {
  const getCallback = useGetter(callback)

  React.useEffect(() => {
    return onPreNavigateCounterListener.addListener(() => {
      getCallback()()
    })
  }, [getCallback])
}

export const triggerOnPreNavigate = () => {
  onPreNavigateCounterListener.setValue(
    onPreNavigateCounterListener.getValue() + 1,
  )
}
