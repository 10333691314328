import { request } from 'util/request'
import deploymentConfig from 'values/deployment.json'

const { apiServerHost } = deploymentConfig

/*
  Later need to get in detail with label click & label search etc.
*/
export const onSearch = async (text: string) => {
  try {
    await request(
      `POST`,
      apiServerHost,
      `/action-log/search?text=${encodeURIComponent(text)}`,
      {
        auth: true,
      },
    )
  } catch (e) {
    console.error(`error on action log : onSearch text : ${text}`)
    console.error(e)
  }
}

export const onCategoryPageLand = async ({
  labelType,
  labelId,
}: /*
  no dependencies, since category pages doesnt have nay dependencies!
  */
{
  labelType: Label.LabelType
  labelId: string
}) => {
  try {
    const params = new URLSearchParams()
    params.set(`labelType`, labelType)
    params.set(`labelId`, labelId)

    await request(
      `POST`,
      apiServerHost,
      `/action-log/category-page-land?${params}`,
      {
        auth: true,
      },
    )
  } catch (e) {
    console.error(e)
  }
}
