// import React from 'react'
// import { useGetter } from 'hooks/use-getter'
import { onSessionAuth, onLogOut } from 'src/services/analytics/events/auth'

import { Listener } from 'util/listener'
// import { useGetIsMounted } from 'hooks/use-get-is-mounted/index'

declare global {
  interface Window {
    // used from request-box-fetch and batch-fetch.
    onAuthChange: (uid?: string, token?: string) => void
  }
}

/*
 */
let memoryCachedUid: string | null = null
let memoryCachedToken: string | null = null

if (typeof window !== `undefined`) {
  memoryCachedUid = localStorage.getItem(`cachedUid`) || null
  memoryCachedToken = localStorage.getItem(`cachedToken`) || null
}

export const uidListener = new Listener<string | null>(memoryCachedUid)

export const onAuthChange = (uid?: string, token?: string) => {
  memoryCachedUid = uid || null
  memoryCachedToken = token || null
  if (memoryCachedUid) {
    localStorage.setItem(`cachedUid`, memoryCachedUid)
  } else {
    localStorage.removeItem(`cachedUid`)
  }

  if (memoryCachedToken) {
    localStorage.setItem(`cachedToken`, memoryCachedToken)
  } else {
    localStorage.removeItem(`cachedToken`)
  }
  uidListener.setValue(memoryCachedUid)
}

if (typeof window !== `undefined`) {
  /*
    requestbox sets cachedUid and calls onAuthChange.
  */
  window.onAuthChange = onAuthChange
}

/*
  TODO : potential race condition :
    if signout between a token update request, the client will signin right after signout.
*/
export const signOut = () => {
  memoryCachedUid = null
  memoryCachedToken = null

  window.localStorage.clear()
  uidListener.setValue(null)

  onLogOut()
}

export const getUid = (): string | null => {
  return memoryCachedUid
}

/*
  This should call getIdToken to refresh if expired
*/
export const getIdToken = () => {
  return memoryCachedToken
}

uidListener.addListener((uid) => {
  if (uid) {
    // send analytics
    onSessionAuth({ userId: uid })
  }
})

if (typeof window !== `undefined`) {
  // reserved from error handler to refresh state
  if (window.localStorage.getItem(`reserveSignout`) === `true`) {
    console.log(`executing reservedSignout`)
    try {
      signOut()
    } catch (e) {
      console.error(`reserved signout execution fail`)
      console.error(e)
    } finally {
      window.localStorage.clear()
      window.location.reload()
    }
  }
}

// depends on uidListener :)
/*
export const useOnFirstAuthLoad = (callback: (uid: string | null) => void) => {
  const consumed = React.useRef(false)
  const getCallback = useGetter(callback)
  const getIsMounted = useGetIsMounted()

  /*
    if uid exists, call right away!
  /
  React.useEffect(() => {
    const uid = getUid()
    if (uid && !consumed.current) {
      consumed.current = true
      getCallback()(uid)
      return
    }

    return uidListener.addListener(() => {
      if (getIsMounted()) {
        const uid = getUid()
        if (uid && !consumed.current) {
          consumed.current = true
          getCallback()(uid)
        }
      }
    })
  }, [getCallback, getIsMounted])
}

*/
