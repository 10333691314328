import analyticsConfig from 'values/analytics.json'

const { enableAnalytics } = analyticsConfig

// eslint-disable-next-line
export const onSearch = (value: string) => {
  if (!enableAnalytics) {
    console.log(`onSearch`)
  }

  if (enableAnalytics) {
    // needs custom implementation
    if (window.logAnalytics) {
      console.log(`onSearch`)
      console.log(value)
    }

    window.gtmDataLayer.push({
      search: null,
    })

    window.gtmDataLayer.push({
      event: `search`,
      search: {
        search_term: value,
      },
    })
  }
}
