import select from '../../util/select/index.js'

const emptyObject = {}
export const getWeightCountData = (data, { orderCount = 1 } = emptyObject) => {
  const [
    weight,
    weightUnit,
    weightOption,
    count,
    minCount,
    maxCount,
    countOption,
    price,
    deliveryFee,
    deliveryFeeType,
    combinedDeliveryFee,
    countUnit,
    origin,
    processingPlace,
    category,
  ] = select(data, [
    `public.content.weight`,
    `public.content.weightUnit`,
    `public.content.weightOption`,
    `public.content.count`,
    `public.content.minCount`,
    `public.content.maxCount`,
    `public.content.countOption`,
    `public.content.price`,
    `public.content.deliveryFee`,
    `public.content.deliveryFeeType`,
    `_populated.owner.public.data.commerce.combinedDeliveryFee`,
    `public.content.countUnit`,
    `public.content.origin`,
    `public.content.processingPlace`,
    `public.content.category`,
  ])

  let totalWeight
  let minTotalWeight
  let maxTotalWeight
  let individualWeight
  let minIndividualWeight
  let maxIndividualWeight

  let mainWeightCountText
  let detailWeightCountText
  let totalWeightText
  let individualWeightText
  let countText
  let pricePerCountText
  let pricePerWeightText
  let orderPricePerCountText
  let orderPricePerWeightText

  let pricePerCount
  let minPricePerCount
  let maxPricePerCount

  let orderPricePerCount
  let orderMinPricePerCount
  let orderMaxPricePerCount

  let pricePerWeight
  let minPricePerWeight
  let maxPricePerWeight

  let orderPricePerWeight
  let orderMinPricePerWeight
  let orderMaxPricePerWeight

  let pricePerUnitText
  let orderPricePerUnitText

  const deliveryFeePaid =
    deliveryFeeType === `combined` ? combinedDeliveryFee : deliveryFee || 0
  const totalPrice = price + deliveryFeePaid
  const orderTotalPrice = price + Math.floor(deliveryFeePaid / orderCount)

  const weightUnitText =
    {
      gram: `g`,
      kilogram: `kg`,
      liter: `l`,
      milliliter: `ml`,
    }[weightUnit] || ``

  const convertUnit = (value, withUnit = true) => {
    if (weightUnit === `kilogram` && value < 1) {
      return `${Math.round(value * 1000).toLocaleString()}${
        withUnit ? 'g' : ''
      }`
    }
    if (weightUnit === `gram` && value >= 1000) {
      return `${(Math.round(value / 10) / 100).toLocaleString()}${
        withUnit ? 'kg' : ''
      }`
    }
    if (weightUnit === `liter` && value < 1) {
      return `${Math.round(value * 1000).toLocaleString()}${
        withUnit ? 'ml' : ''
      }`
    }
    if (weightUnit === `milliliter` && value >= 1000) {
      return `${(Math.round(value / 10) / 100).toLocaleString()}${
        withUnit ? 'l' : ''
      }`
    }

    return `${value}${weightUnitText}`
  }

  if (weightOption === `total`) {
    pricePerWeight = totalPrice / weight
    orderPricePerWeight = orderTotalPrice / weight
    totalWeight = weight
  } else if (weightOption === `individual`) {
    individualWeight = weight
  }

  if (countOption === `range`) {
    minPricePerCount = totalPrice / maxCount
    maxPricePerCount = totalPrice / minCount
    orderMinPricePerCount = orderTotalPrice / maxCount
    orderMaxPricePerCount = orderTotalPrice / minCount
  } else if (countOption === `exact`) {
    pricePerCount = totalPrice / count
    orderPricePerCount = orderTotalPrice / count
  }

  if (countOption !== `notavailable` && weightOption !== `notavailable`) {
    // both count and weight provided! calculate individual count!

    if (weightOption === `total`) {
      if (countOption === `range`) {
        minIndividualWeight = weight / maxCount
        maxIndividualWeight = weight / minCount
      } else {
        individualWeight = weight / count
      }
    } else if (countOption === `range`) {
      minTotalWeight = weight * minCount
      maxTotalWeight = weight * maxCount
      minPricePerWeight = totalPrice / maxTotalWeight
      maxPricePerWeight = totalPrice / minTotalWeight
      orderMinPricePerWeight = orderTotalPrice / maxTotalWeight
      orderMaxPricePerWeight = orderTotalPrice / minTotalWeight
    } else {
      totalWeight = weight * count
      pricePerWeight = totalPrice / totalWeight
      orderPricePerWeight = orderTotalPrice / totalWeight
    }
  }

  if (totalWeight) {
    totalWeightText = `${convertUnit(totalWeight)}`
  } else if (minTotalWeight && maxTotalWeight) {
    totalWeightText = `${convertUnit(minTotalWeight, false)}~${convertUnit(
      maxTotalWeight,
    )}`
  }

  if (individualWeight) {
    individualWeightText = `${convertUnit(individualWeight)}`
  } else if (minIndividualWeight && maxIndividualWeight) {
    // 여기가 맞음
    individualWeightText = `${convertUnit(
      minIndividualWeight,
      false,
    )}~${convertUnit(maxIndividualWeight)}`
  }

  if (countOption !== `notavailable`) {
    if (count) {
      countText = `${count.toLocaleString()}${countUnit}`
    } else if (minCount && maxCount) {
      countText = `${minCount.toLocaleString()}~${maxCount.toLocaleString()}${countUnit}`
    }
  }

  if (weightOption === `individual` && countOption !== `notavailable`) {
    mainWeightCountText = `${individualWeightText} x ${countText}`
    detailWeightCountText = mainWeightCountText
  } else if (totalWeightText) {
    mainWeightCountText = totalWeightText
    if (countText) {
      detailWeightCountText = `${totalWeightText} / ${countText}`
    } else {
      detailWeightCountText = totalWeightText
    }
  } else if (countText) {
    mainWeightCountText = countText
    detailWeightCountText = mainWeightCountText
  }

  if (pricePerCount) {
    // unit : 300w
    pricePerCountText = `${Math.round(
      pricePerCount,
    ).toLocaleString()}원/1${countUnit}`
    orderPricePerCountText = `${Math.round(
      orderPricePerCount,
    ).toLocaleString()}원/1${countUnit}`
  } else if (minPricePerCount && maxPricePerCount) {
    pricePerCountText = `${Math.round(
      minPricePerCount,
    ).toLocaleString()}~${Math.round(
      maxPricePerCount,
    ).toLocaleString()}원/1${countUnit}`
    orderPricePerCountText = `${Math.round(
      orderMinPricePerCount,
    ).toLocaleString()}~${Math.round(
      orderMaxPricePerCount,
    ).toLocaleString()}원/1${countUnit}`
  }

  if (pricePerWeight) {
    switch (weightUnit) {
      case `gram`:
        pricePerWeightText = `${Math.round(
          pricePerWeight * 100,
        ).toLocaleString()}원/100g`
        orderPricePerWeightText = `${Math.round(
          orderPricePerWeight * 100,
        ).toLocaleString()}원/100g`
        break
      case `kilogram`:
        pricePerWeightText = `${Math.round(
          pricePerWeight / 10,
        ).toLocaleString()}원/100g`
        orderPricePerWeightText = `${Math.round(
          orderPricePerWeight / 10,
        ).toLocaleString()}원/100g`
        break
      case `milliliter`:
        pricePerWeightText = `${Math.round(
          pricePerWeight * 100,
        ).toLocaleString()}원/100ml`
        orderPricePerWeightText = `${Math.round(
          orderPricePerWeight * 100,
        ).toLocaleString()}원/100ml`
        break
      case `liter`:
        pricePerWeightText = `${Math.round(
          pricePerWeight / 10,
        ).toLocaleString()}원/100ml`
        orderPricePerWeightText = `${Math.round(
          orderPricePerWeight / 10,
        ).toLocaleString()}원/100ml`
        break
      default:
        break
    }
  } else if (minPricePerWeight && maxPricePerWeight) {
    switch (weightUnit) {
      case `gram`:
        pricePerWeightText = `${Math.round(
          minPricePerWeight * 100,
        )}~${Math.round(maxPricePerWeight * 100).toLocaleString()}원/100g`
        orderPricePerWeightText = `${Math.round(
          orderMinPricePerWeight * 100,
        )}~${Math.round(orderMaxPricePerWeight * 100).toLocaleString()}원/100g`
        break
      case `kilogram`:
        pricePerWeightText = `${Math.round(
          minPricePerWeight / 10,
        )}~${Math.round(maxPricePerWeight / 10).toLocaleString()}원/100g`
        orderPricePerWeightText = `${Math.round(
          orderMinPricePerWeight / 10,
        )}~${Math.round(orderMaxPricePerWeight / 10).toLocaleString()}원/100g`
        break
      case `milliliter`:
        pricePerWeightText = `${Math.round(
          minPricePerWeight * 100,
        )}~${Math.round(maxPricePerWeight * 100).toLocaleString()}원/100ml`
        orderPricePerWeightText = `${Math.round(
          orderMinPricePerWeight * 100,
        )}~${Math.round(orderMaxPricePerWeight * 100).toLocaleString()}원/100ml`
        break
      case `liter`:
        pricePerWeightText = `${Math.round(
          minPricePerWeight / 10,
        )}~${Math.round(maxPricePerWeight / 10).toLocaleString()}원/100ml`
        orderPricePerWeightText = `${Math.round(
          orderMinPricePerWeight / 10,
        )}~${Math.round(orderMaxPricePerWeight / 10).toLocaleString()}원/100ml`
        break
      default:
        break
    }
  }

  /* eslint-disable-next-line prefer-const */
  pricePerUnitText = pricePerWeightText || pricePerCountText
  /* eslint-disable-next-line prefer-const */
  orderPricePerUnitText = orderPricePerWeightText || orderPricePerCountText

  let originText = ``
  let originTextShort = ``

  if (processingPlace) {
    originText += `${processingPlace.full} ${
      category === 'aquatic' ? '덕장' : '생산지'
    }`
    originTextShort += `${(processingPlace.full || ``)
      .split(` `)
      .splice(-2)
      .join(` `)} ${category === 'aquatic' ? '덕장' : '생산지'}`
  }

  if (origin && origin.length > 0) {
    if (originText) {
      originText += `, `
      originTextShort += `, `
    }
    originText += origin.map(({ full }) => full).join(`, `)
    originText += `산`

    originTextShort += origin
      .map(({ full }) => (full || ``).split(` `).splice(-2).join(` `))
      .join(`, `)
    originTextShort += `산`
  }

  return {
    mainWeightCountText,
    detailWeightCountText,
    totalWeightText,
    individualWeightText,
    countText,
    pricePerCountText,
    pricePerWeightText,
    pricePerUnitText,
    orderPricePerCountText,
    orderPricePerWeightText,
    orderPricePerUnitText,
    totalWeight,
    minTotalWeight,
    maxTotalWeight,
    individualWeight,
    minIndividualWeight,
    maxIndividualWeight,
    count,
    minCount,
    maxCount,
    countOption,
    weightOption,
    pricePerCount,
    minPricePerCount,
    maxPricePerCount,
    orderPricePerCount,
    orderMinPricePerCount,
    orderMaxPricePerCount,
    pricePerWeight,
    minPricePerWeight,
    maxPricePerWeight,
    orderPricePerWeight,
    orderMinPricePerWeight,
    orderMaxPricePerWeight,
    originText,
    originTextShort,
  }
}

// vvv deprecated below
export const getItemName = (productData) => {
  const [productName] = select(productData, [`public.content.productName`])

  const { detailWeightCountText } = getWeightCountData(productData)

  const itemName = `${productName}${
    detailWeightCountText ? ` ${detailWeightCountText}` : ``
  }`
  return itemName
}

export const getItemDeliveryFee = (productData) => {
  const [deliveryFee = 0, deliveryFeeType, combinedDeliveryFee] = select(
    productData,
    [
      `public.content.deliveryFee`,
      `public.content.deliveryFeeType`,
      `_populated.owner.public.data.commerce.combinedDeliveryFee`,
    ],
  )
  return deliveryFeeType === `combined` ? combinedDeliveryFee : deliveryFee
}

export const getItemTotalPrice = (productData) => {
  const [price, deliveryFee = 0, deliveryFeeType, combinedDeliveryFee] = select(
    productData,
    [
      `public.content.price`,
      `public.content.deliveryFee`,
      `public.content.deliveryFeeType`,
      `_populated.owner.public.data.commerce.combinedDeliveryFee`,
    ],
  )

  const displayPrice =
    price + (deliveryFeeType === `combined` ? combinedDeliveryFee : deliveryFee)

  return displayPrice
}
