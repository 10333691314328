import React from 'react'

export interface TouchGestureBindMethodContainer<T> {
  onMouseDown?: (e: React.MouseEvent<T>) => void
  onMouseMove?: (e: React.MouseEvent<T>) => void
  onMouseUp?: (e: React.MouseEvent<T>) => void
  onTouchStart?: (e: React.TouchEvent<T>) => void
  onTouchMove?: (e: React.TouchEvent<T>) => void
  onTouchEnd?: (e: React.TouchEvent<T>) => void
  onTouchCancel?: (e: React.TouchEvent<T>) => void
}

export type Tuple = [number, number]

interface TouchState {
  initialX: number
  initialY: number
  lastX: number
  lastY: number
}

export type TouchEventType = `start` | `move` | `end`

type TouchHappenCallback<T> = (options: {
  key: string
  initial: Tuple
  xy: Tuple
  movement: Tuple
  distance: number
  target: HTMLElement
  currentTarget: T
}) => void

/*
  TODO onMove for mouse event should keep track for mouse down and up events.
  currently it fires any time mouse hovered
*/

export const useTouchGestures = <T,>({
  onStart,
  onMove,
  onEnd,
  skipMouseEvents = false,
}: {
  onStart?: TouchHappenCallback<T>
  onMove?: TouchHappenCallback<T>
  onEnd?: TouchHappenCallback<T>
  skipMouseEvents?: boolean
} = {}) => {
  const store = React.useRef<{
    [key: string]: TouchState
  }>({})

  // don't use closure!
  const bindFunction = React.useCallback(
    (key: string) => {
      store.current[key] = store.current[key] || {
        initialX: 0,
        initialY: 0,
        lastX: 0,
        lastY: 0,
      }

      const wrap = (callback: TouchHappenCallback<T>, type: TouchEventType) => {
        return (e: React.TouchEvent<T> | React.MouseEvent<T>) => {
          const { targetTouches } = e as React.TouchEvent<T>

          let { initialX, initialY } = store.current[key]
          if (type === `end`) {
            const { lastX, lastY } = store.current[key]
            store.current[key].initialX = 0
            store.current[key].initialY = 0
            store.current[key].lastX = 0
            store.current[key].lastY = 0
            const distance = Math.sqrt(
              (lastX - initialX) ** 2 + (lastY - initialY) ** 2,
            )
            ;(callback as TouchHappenCallback<T>)({
              initial: [initialX, initialY],
              xy: [lastX, lastY],
              movement: [lastX - initialX, lastY - initialY],
              key,
              distance,
              target: e.target as HTMLElement,
              currentTarget: e.currentTarget as T,
            })
            return
          }

          let x
          let y
          if (targetTouches) {
            ;({ clientX: x, clientY: y } = targetTouches[0])
          } else {
            const { clientX, clientY } = e as React.MouseEvent<T>
            x = clientX
            y = clientY
          }

          store.current[key].lastX = x
          store.current[key].lastY = y

          if (type === `start`) {
            initialX = x
            initialY = y
            store.current[key].initialX = x
            store.current[key].initialY = y
          }

          const distance = Math.sqrt((x - initialX) ** 2 + (y - initialY) ** 2)
          ;(callback as TouchHappenCallback<T>)({
            initial: [initialX, initialY],
            xy: [x, y],
            movement: [x - initialX, y - initialY],
            key,
            distance,
            target: e.target as HTMLElement,
            currentTarget: e.currentTarget as T,
          })
        }
      }

      const bind: TouchGestureBindMethodContainer<T> = {}

      if (onStart) {
        if (!skipMouseEvents) {
          bind.onMouseDown = wrap(onStart, `start`)
        }
        bind.onTouchStart = wrap(onStart, `start`)
      }

      if (onMove) {
        if (!skipMouseEvents) {
          bind.onMouseMove = wrap(onMove, `move`)
        }
        bind.onTouchMove = wrap(onMove, `move`)
      }

      if (onEnd) {
        if (!skipMouseEvents) {
          bind.onMouseUp = wrap(onEnd, `end`)
        }
        bind.onTouchEnd = wrap(onEnd, `end`)
        bind.onTouchCancel = wrap(onEnd, `end`)
      }

      return bind
    },
    [onEnd, onMove, onStart, skipMouseEvents],
  )

  return bindFunction
}
