// import React from 'react'
import { Capacitor } from '@capacitor/core'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { navigate } from 'hooks/use-location'
// why not integrated to hooks?
// 1. no context depdendency
// once per bundle load!

const bundleLoadedAtMilliseconds = Date.now()
let lastConsumeResumeRequestAtMilliseconds = Date.now()
let consumeResumeNavigationRequestAtMilliseconds = 0

const bundleActiveTimeoutMilliseconds = 1 * 60 * 60 * 1000

export const consumeResumeNavigationRequest = (slug: string) => {
  //
  console.log(`@consumeResumeNavigationRequest : ${slug}`)
  consumeResumeNavigationRequestAtMilliseconds = Date.now()
  // one hour :)
  const isBundleOld =
    Date.now() - bundleLoadedAtMilliseconds > bundleActiveTimeoutMilliseconds

  const origin = window.location.origin

  const targetURL = new URL(`${origin}${slug}`)
  // const currentURL = new URL(window.location.href)

  // why not navigate? => lets see how slow it is for page reload.

  if (isBundleOld) {
    window.location.href = targetURL.href
  } else {
    // bundle fresh! just navigate.
    navigate(slug)
  }
}

export const consumeResumeRequest = () => {
  console.log(`@consumeResumeRequest`)
  const hasEnoughTimePassedSinceLastConsumeRequest =
    Date.now() - lastConsumeResumeRequestAtMilliseconds >
    bundleActiveTimeoutMilliseconds

  // update last consume resume
  lastConsumeResumeRequestAtMilliseconds = Date.now()

  if (!hasEnoughTimePassedSinceLastConsumeRequest) {
    // not enough time passed, just skip reloads.
    return
  }

  if (window.location.pathname !== `/p/create`) {
    // not create!
    window.setTimeout(() => {
      // reload after 17ms
      if (Date.now() - consumeResumeNavigationRequestAtMilliseconds < 1000) {
        console.log(
          `skip consumeResumeRequest since we consumed navigation request!`,
        )
        return
      }

      console.log(`consumeResumeRequest reload!`)
      window.location.reload()
    }, 100)
  }
}

// TODO : might reload the page if the session is too old (or new client binary version exists)
// or might override navigate method / link to reload the session if too old,,, hmm??
let appUrlRegistered = false
export const registerAppUrl = () => {
  if (typeof window !== `undefined` && Capacitor.isNativePlatform()) {
    if (!appUrlRegistered) {
      //
      // React.useEffect(() => {
      App.addListener(`appUrlOpen`, (event: URLOpenListenerEvent) => {
        // https://capacitorjs.com/docs/guides/deep-links

        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2

        const target = `roout.co.kr`
        const url = event.url
        const index = url.indexOf(target)
        if (index !== -1) {
          const slug = url.substring(index + target.length)
          if (slug) {
            console.log(
              `deep link consumption : url : ${url} -> slug : ${slug}`,
            )
            // it was previously navigate, but we change this to page reload!!
            consumeResumeNavigationRequest(slug)
          }
        }

        // If no match, do nothing - let regular routing
        // logic take over
      })

      // https://capacitorjs.com/docs/apis/app#addlistenerpause-
      /*
        On iOS it's fired when the native UIApplication.willResignActiveNotification and UIApplication.didBecomeActiveNotification events get fired. 
        On Android it's fired when the Capacitor's Activity onResume and onStop methods gets called.
      */
      App.addListener(`appStateChange`, ({ isActive }) => {
        console.log(`appStateChange! isActive : ${isActive}`)
        if (isActive) {
          //
          consumeResumeRequest()
        } else {
          /* no-op */
        }
      })

      /*
        On iOS it's fired when the native UIApplication.didEnterBackgroundNotification event gets fired. 
        On Android it's fired when the Capacitor's Activity onPause method gets called.
      */
      App.addListener(`pause`, () => {
        /* no-op */

        console.log(`pause!`)
      })

      /*
        On iOS it's fired when the native UIApplication.willEnterForegroundNotification event gets fired. 
        On Android it's fired when the Capacitor's Activity onResume method gets called, but only after resume has fired first. 
      */
      App.addListener(`resume`, () => {
        /* no-op */
        console.log(`resume!`)
      })

      console.log(`app url consumer registered!`)
      //  }, [])
      appUrlRegistered = true
    }
  }
}
