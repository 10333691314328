import React from 'react'
import { useData } from 'hooks/use-data'
import { useGetter } from 'hooks/use-getter'
import { useAsyncCallback } from 'hooks/use-async-callback'

interface WalletUpdateHandle {
  refreshWallet: () => void
}

const WalletRefreshKeyContext = React.createContext<number>(0)
const WalletUpdateHandleContext = React.createContext<WalletUpdateHandle>({
  refreshWallet: () => {
    console.error(`@refreshWallet wallet handle not initialized!`)
  },
})

export const WalletRefreshProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [walletRefreshKey, setWalletRefreshKey] = React.useState(0)

  const walletUpdateHandle = React.useMemo(() => {
    return {
      refreshWallet: () => {
        setWalletRefreshKey(Date.now())
      },
    }
  }, [])

  return (
    <WalletUpdateHandleContext.Provider value={walletUpdateHandle}>
      <WalletRefreshKeyContext.Provider value={walletRefreshKey}>
        {children}
      </WalletRefreshKeyContext.Provider>
    </WalletUpdateHandleContext.Provider>
  )
}

export const useWalletData = (userId: string) => {
  const [currentWalletData, setWalletData] = React.useState<Wallet | null>(null)

  const [networkWalletData, , reloadNetworkWalletData] = useData<Wallet>(
    userId ? `/${userId}/wallet` : null,
    {
      forceRevalidate: true,
      reloadOnNavigate: true,
      cacheReadConsistency: `route`,
    },
  )

  // on networkWalletData update, update!
  React.useEffect(() => {
    if (userId) {
      setWalletData(networkWalletData)
    }
  }, [userId, networkWalletData])

  const walletRefreshKey = React.useContext(WalletRefreshKeyContext)

  const reloadWalletData = useAsyncCallback(async () => {
    if (!userId) {
      return
    }

    await reloadNetworkWalletData()
  }, [userId, reloadNetworkWalletData])

  const getReloadWalletData = useGetter(reloadWalletData)
  React.useEffect(() => {
    getReloadWalletData()()
  }, [getReloadWalletData, walletRefreshKey])

  return currentWalletData
}

export const useWalletUpdateHandle = () => {
  return React.useContext(WalletUpdateHandleContext)
}
