import React from 'react'
import { useIsSSR } from 'hooks/use-is-ssr'
/*
    uid still needs to use listener since it's intiialized independently from react
*/
import { uidListener } from 'model/app/auth'

const UidContext = React.createContext<string | null>(uidListener.getValue())

export const UidProvider = ({ children }: { children: React.ReactNode }) => {
  const isSSR = useIsSSR()
  const [uid, setUid] = React.useState(uidListener.getValue())
  /*
    Do we need to check for getIsMounted?
  */
  React.useEffect(() => {
    return uidListener.addListener(setUid)
  }, [])

  return (
    <UidContext.Provider value={isSSR ? null : uid}>
      {children}
    </UidContext.Provider>
  )
}

export const useUid = () => {
  return React.useContext(UidContext)
}
