export type ListenerFunction<T> = (value: T) => void

export class Listener<T> {
  value: T

  counter: number

  listeners: Record<string, ListenerFunction<T>>

  constructor(initialValue: T) {
    this.value = initialValue
    this.counter = 0
    this.listeners = {}
  }

  addListener(listener: ListenerFunction<T>) {
    const id = `${this.counter}`
    this.counter += 1
    this.listeners[id] = listener
    return () => {
      delete this.listeners[id]
    }
  }

  setValue(value: T) {
    if (this.value !== value) {
      this.value = value
      Object.keys(this.listeners).forEach((key: string) => {
        try {
          const value = this.value
          const listener = this.listeners[key]
          if (listener) {
            listener(value)
          }
        } catch (e) {
          console.error(e)
        }
      })
    }
  }

  getValue() {
    return this.value
  }
}
