import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useHasFCP } from 'hooks/use-has-fcp'
import { install } from 'src/services/analytics/tagmanager'

import analyticsConfig from 'values/analytics.json'

const { enableAnalytics } = analyticsConfig

/* eslint-disable jsx-a11y/iframe-has-title, jsx-a11y/alt-text */
export const useAnalytics = () => {
  const hasFCP = useHasFCP()

  React.useEffect(() => {
    if (hasFCP) {
      install()
    }
  }, [hasFCP])

  if (!enableAnalytics) {
    return null
  }

  return (
    <Helmet>
      {enableAnalytics ? (
        <meta
          name="facebook-domain-verification"
          content="v8qb40btuokbhpwzrkkqj5ls6umzjb"
        />
      ) : null}
    </Helmet>
  )
}
