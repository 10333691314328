import React from 'react'
import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { useHasFCP } from 'hooks/use-has-fcp'
import {
  onSessionEnter,
  OnSessionEnterParams,
} from 'model/app/action-log/on-session-enter'
import { useQuery } from 'hooks/use-query'
import { replaceQuery } from 'util/url'
import { useGetter } from 'hooks/use-getter'
import {
  screenWidthListener,
  screenHeightListener,
} from 'hooks/use-viewport-dimension'

export const useOnSessionEnter = () => {
  const hasFCP = useHasFCP()
  const referringSessionId = useQuery(`s`)

  const getReferringSessionId = useGetter(referringSessionId)

  React.useEffect(() => {
    if (hasFCP) {
      /*

        */
      ;(async () => {
        if (typeof window !== `undefined`) {
          const referringSessionId = getReferringSessionId() || undefined
          const screenWidth = screenWidthListener.getValue()
          const screenHeight = screenHeightListener.getValue()
          const landingUrl = window?.location?.href

          const paramsObject: OnSessionEnterParams = {
            referringSessionId,
            landingUrl,
            screenWidth,
            screenHeight,
          }

          if (Capacitor.isNativePlatform()) {
            const [deviceId, deviceInfo] = await Promise.all([
              Device.getId(),
              Device.getInfo(),
            ])

            paramsObject.deviceId = deviceId.identifier
            paramsObject.platform = deviceInfo.platform
            paramsObject.operatingSystem = deviceInfo.operatingSystem
            paramsObject.osVersion = deviceInfo.osVersion
            paramsObject.manufacturer = deviceInfo.manufacturer
            paramsObject.webViewVersion = deviceInfo.webViewVersion
          }

          onSessionEnter(paramsObject)

          if (referringSessionId) {
            replaceQuery(`s`) // remove
          }
          // after fire, remove the referringSessionId.
        }
      })()

      // remove referringsessionId
    }
  }, [hasFCP, getReferringSessionId])
}
