import React from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import * as styles from './style.module.less'

// Module scope errors
let errorMessages: string[] = []

/*
  1. if error.code
  2. if error.stack
    else if error.message
  3. JSON.stringify(error, null, 2)
*/
const errorHandler = (error: any, info: { componentStack: string }) => {
  let errorMessage = ``
  try {
    const code = error?.code
    const stack = error?.stack
    const message = error?.message
    const componentStack = info?.componentStack
    if (code) {
      errorMessage += `\n\n에러 코드--------------\n\n${code}`
    }
    if (stack) {
      errorMessage += `\n\n에러 코드 위치--------------\n\n${stack}`
    } else if (message) {
      errorMessage += `\n\n에러 문자--------------\n\n${message}`
    } else {
      errorMessage += `\n\n에러 객체--------------\n\n${JSON.stringify(
        error,
        null,
        2,
      )}`
    }

    if (componentStack) {
      errorMessage += `\n\n에러 요소 위치--------------\n\n${componentStack}`
    }
  } catch (e: any) {
    errorMessage += `\n\n에러 실패 코드 위치--------------\n\n${e?.stack}`
  }

  errorMessages = [...errorMessages, errorMessage]
  console.error(errorMessage)
}

const ErrorFallback = () => {
  /*
    error

    에러 메세지 보기 <길 수 있어요!>
  */

  const [currentErrorMessages, setCurrentErrorMessages] = React.useState<
    string[]
  >(() => errorMessages)

  let isLoggedIn = false
  let errorPath = ``
  if (typeof window !== `undefined`) {
    isLoggedIn = !!window?.localStorage.getItem(`cachedUid`)
    // not using url's getCurrentPath because we don't want dependency
    // error boundary should have minimal dependency in order to reduce risk
    errorPath = window?.location?.href.replace(window?.location?.origin, ``)
  }

  return (
    <div className={styles.rootContainer}>
      <h1 className="error-fallback-header">에러가 발생했어요!</h1>
      <a
        className={styles.errorAction}
        onClick={(e) => {
          e?.preventDefault()
          window?.location.reload()
        }}
        href=""
      >
        마지막 화면으로 돌아가기
      </a>
      <a className={styles.errorAction} href="/">
        루트 메인 화면으로 이동하기
      </a>
      {isLoggedIn ? (
        <a
          className={styles.errorAction}
          onClick={() => {
            if (typeof window !== `undefined`) {
              window?.localStorage.clear()
              window?.localStorage.setItem(`reserveSignout`, `true`)
            }
          }}
          href="/"
        >
          로그아웃하고 루트 메인 화면으로 이동하기
        </a>
      ) : null}
      <p>불편을 끼쳐드려 너무 죄송합니다. 😔</p>
      <p>
        에러 메세지를 루트에게 알려주세요. <br />
        루트가 빠른 시일 내에 해결하도록 노력하겠습니다. <br />
        감사합니다. 🙏
      </p>
      <p>
        에러가 계속 발생한다면 브라우저 창을 모두 닫고, 브라우저(카카오톡,
        인스타그램, 네이버 등 포함)를 재시작 한 후 다시 시도해주세요. <br />
      </p>
      <p>
        <a
          href="http://pf.kakao.com/_DyxjmK"
          aria-label="루트 공식 카카오톡"
          target="_blank"
          rel="noreferrer"
        >
          카카오톡 - roout_루트
        </a>
        <br />
        <a
          href="https://instagram.com/roout_official"
          aria-label="루트 공식 인스타그램"
          target="_blank"
          rel="noreferrer"
        >
          인스타그램 - roout_official
        </a>
        <br />
        이메일 - help@roout.co.kr
        <br />
        전화 - 02-406-2228
      </p>
      <p className={styles.errorPath}>
        에러가 발생한 페이지 :
        <br />
        {errorPath}
      </p>
      <ul className={styles.errorList}>
        <button
          type="button"
          className="render-error-button"
          onClick={() => setCurrentErrorMessages(errorMessages)}
        >
          에러 메세지 열기
        </button>
        {currentErrorMessages.map((errorMessage, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li className={styles.errorListBlock} key={index}>
            {errorMessage
              .split(`\n`)
              // eslint-disable-next-line react/no-array-index-key
              .map((e, index) => [e, <br key={index} />])}
          </li>
        ))}
      </ul>
    </div>
  )
}

export const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={errorHandler}
    >
      {children}
    </ReactErrorBoundary>
  )
}
