import React from 'react'
import { useUid } from 'hooks/use-uid'
import { useQuery } from 'hooks/use-query'
import { useData } from 'hooks/use-data'

export type RewardState = `requestable` | `unrequestable`

const RewardStateContext = React.createContext<RewardState>(`unrequestable`)
const RewardStateRefreshHandleContext = React.createContext<() => void>(() => {
  /* no-op */
})

export const RewardStateProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const userQuery = useQuery(`user`)
  const uid = useUid()
  const targetUid = userQuery || uid
  const [rewardState, , reload] = useData<RewardState>(
    targetUid ? `/rms?user=${targetUid}` : null,
    {
      cacheReadConsistency: `strong`,
    },
  )

  return (
    <RewardStateContext.Provider value={rewardState || `unrequestable`}>
      <RewardStateRefreshHandleContext.Provider value={reload}>
        {children}
      </RewardStateRefreshHandleContext.Provider>
    </RewardStateContext.Provider>
  )
}

export const useRewardStateRefreshHandle = () => {
  return React.useContext(RewardStateRefreshHandleContext)
}

// reward here means content reward
export const useRewardState = () => {
  return React.useContext(RewardStateContext)
}
