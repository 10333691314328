import React from 'react'
import { useUid } from 'hooks/use-uid'
import { useQuery } from 'hooks/use-query'
import { useData } from 'hooks/use-data'

export type ReviewableTargets = {
  totalRewardAmount: number
  totalReviewRewardAmount: number
  totalRateRewardAmount: number
  totalReviewableOrdersCount: number
  totalRatableOrdersCount: number
  reviewableOrders: Array<{
    orderId: string
    rewardAmount: number
  }>
  ratableOrders: Array<{
    orderId: string
    rewardAmount: number
  }>
}

const defaultReviewableTargets = {
  totalRewardAmount: 0,
  totalReviewRewardAmount: 0,
  totalRateRewardAmount: 0,
  totalReviewableOrdersCount: 0,
  totalRatableOrdersCount: 0,
  reviewableOrders: [],
  ratableOrders: [],
}

export const ReviewableTargetsContext = React.createContext<ReviewableTargets>(
  defaultReviewableTargets,
)
const ReviewableTargetsRefreshHandleContext = React.createContext<() => void>(
  () => {
    /* no-op */
  },
)

export const ReviewableTargetsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const userQuery = useQuery(`user`)
  const uid = useUid()
  const targetUid = userQuery || uid

  const [reviewableTargets, , reload] = useData<ReviewableTargets>(
    targetUid ? `/reviewable-targets?user=${targetUid}` : null,
    {
      cacheReadConsistency: `strong`,
    },
  )

  return (
    <ReviewableTargetsContext.Provider
      value={reviewableTargets || defaultReviewableTargets}
    >
      <ReviewableTargetsRefreshHandleContext.Provider value={reload}>
        {children}
      </ReviewableTargetsRefreshHandleContext.Provider>
    </ReviewableTargetsContext.Provider>
  )
}

// VV here
export const useReviewableTargetsRefreshHandle = () => {
  return React.useContext(ReviewableTargetsRefreshHandleContext)
}

// reward here means content reward
export const useReviewableTargets = () => {
  return React.useContext(ReviewableTargetsContext)
}

let hasSeenReviewableTargets = false
export const onReviewableTargetView = () => {
  // disable red dot for a day
  hasSeenReviewableTargets = true
}

export const getShouldDisplayReviewableTargetDot = () => {
  return !hasSeenReviewableTargets
}
