import React from 'react'
import { useNotificationExists } from 'hooks/use-notification-exists/index'
import * as styles from './style.module.less'

export const NotificationExistsOverlay = ({
  className = ``,
  activity = true,
  commerce = true,
  reward = true,
  top,
  right,
}: {
  className?: string
  activity?: boolean
  commerce?: boolean
  reward?: boolean
  top: number
  right: number
}) => {
  const {
    activity: activityExists,
    commerce: commerceExists,
    reward: rewardExists,
  } = useNotificationExists()

  return (
    <div
      style={{ top: `${top}px`, right: `${right}px` }}
      className={`${className} ${
        (activity && activityExists) ||
        (commerce && commerceExists) ||
        (reward && rewardExists)
          ? styles.exists
          : styles.notExists
      }`}
    />
  )
}
