import React from 'react'
import { useQuery } from 'hooks/use-query'
import { useUid } from 'hooks/use-uid'
import { useIsSSR } from 'hooks/use-is-ssr'
import { replaceQuery } from 'util/url'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { useAsyncEffect } from 'hooks/use-async-effect'
import { Capacitor } from '@capacitor/core'
import { useGetter } from 'hooks/use-getter'
import { openInApp } from 'util/open-in-app'
import { asyncCatch } from 'util/async-catch'
import { useAuthBarrier } from 'hooks/use-auth-barrier'
import { submitRewardCode } from 'model/app/reward-code'

export const useRewardCodeConumser = () => {
  const isSSR = useIsSSR()
  const getIsMounted = useGetIsMounted()
  const uid = useUid()
  const queryRewardCode = useQuery(`reward-code`)
  const isSubmittingBox = React.useRef(false)
  const getQueryRewardCode = useGetter(queryRewardCode)
  const setAuthBarrier = useAuthBarrier()

  // eslint-disable-next-line
  useAsyncEffect(async () => {
    if (isSSR) {
      return
    }

    if (Capacitor.isNativePlatform()) {
      if (!uid) {
        // store to session storage for consumption later.
        if (queryRewardCode) {
          sessionStorage.setItem(`reward-code`, queryRewardCode)
          replaceQuery(`reward-code`) // delete
          setAuthBarrier(`로그인하고 리워드를 수령하세요`, true, true)
        }
      } else {
        // consume right now.
        const rewardCode =
          queryRewardCode || sessionStorage.getItem(`reward-code`)
        if (rewardCode && !isSubmittingBox.current) {
          // do consume!
          isSubmittingBox.current = true
          try {
            // await submitCode! great!
            const [err, res] = await asyncCatch(submitRewardCode(rewardCode))
            // do we need to use getIsMounted?
            if (err) {
              console.error(err)
              alert(`문제가 발생했어요!`)
            } else {
              alert(res.message)
            }
          } finally {
            // remove lock
            isSubmittingBox.current = false
            // remove query
            if (getQueryRewardCode() === rewardCode) {
              replaceQuery(`reward-code`)
            }

            // remove reward-code in sessionStorage
            if (sessionStorage.getItem(`reward-code`) === rewardCode) {
              sessionStorage.removeItem(`reward-code`)
            }
          }
        }
      }
    } else if (queryRewardCode) {
      // if web and reward-code exists, openInApp
      const rewardCodeStoreNavigation = sessionStorage.getItem(
        `reward-code-store-navigation`,
      )
      if (rewardCodeStoreNavigation !== `true`) {
        sessionStorage.setItem(`reward-code-store-navigation`, `true`)
        if (
          window.confirm(
            `루트 앱에서 코드를 사용하세요! 코드: ${queryRewardCode}`,
          )
        ) {
          openInApp()
        }
      }
    }
  }, [
    isSSR,
    uid,
    queryRewardCode,
    getIsMounted,
    getQueryRewardCode,
    setAuthBarrier,
  ])
}

export const RewardCodeConsumer = () => {
  useRewardCodeConumser()
  return null
}
