module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fcfdfd","categories":["shopping","food","lifestyle"],"description":"루트는 농어부님들과 우리 모두를 연결해주는 '수수료 없는 진짜 농수산물 직거래 플랫폼' 입니다. 농장에서 우리집까지 하루만에 도착하는 신선한 농수산물의 재배과정을 직접 보고 구매하세요!","display":"standalone","icons":[{"src":"/icons/manifest-icon-192-3.png","sizes":"192x192","type":"image/png","purpose":"maskable any"},{"src":"/icons/manifest-icon-512-3.png","sizes":"512x512","type":"image/png","purpose":"maskable any"}],"lang":"ko-KR","name":"루트 - 수수료 없는 진짜 농수산물 직거래 플랫폼","orientation":"portrait","prefer_related_applications":true,"related_applications":[{"platform":"play","url":"https://play.google.com/store/apps/details?id=kr.co.roout.app","id":"kr.co.roout.app"},{"platform":"itunes","url":"https://apps.apple.com/kr/app/id6446498982","id":"6446498982"},{"platform":"webapp","url":"https://roout.co.kr/manifest.webmanifest"}],"short_name":"루트","start_url":"/","theme_color":"#fcfdfd","theme_color_in_head":false,"include_favicon":false,"cache_busting_mode":"none","legacy":false,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
