import type { GatsbyBrowser } from 'gatsby'
import { onRouteChange, getCurrentPath } from 'util/url'
import {
  onRouteUpdate as swOnRouteUpdate,
  onServiceWorkerUpdateReady as swOnServiceWorkerUpdateReady,
  onServiceWorkerActive as swOnServiceWorkerActive,
  onServiceWorkerUpdateFound as swOnServiceWorkerUpdateFound,
} from 'hooks/use-service-worker-state'
import { prefetchRouteData } from 'src/services/prefetch'
import { PrefetchRouteDataOptions } from 'src/services/prefetch/types'
import { wrapPageElement, wrapRootElement } from './src/containers/root'

// gatsby browser rendered after webpack load

// most elements are dynamically rendered, so scroll position doesn't matter.
export const shouldUpdateScroll: GatsbyBrowser[`shouldUpdateScroll`] = () => {
  // return true // restore & jump might be better
  // scroll to zero and handle after elements load..!
  /*
  window.scrollTo(0, 0)
  const body = document.getElementsByTagName(`body`)[0]
  body.scrollTop = 0
  return false
  */
  return true
}
type NavigateDirection = `forward` | `backward`
declare global {
  interface Window {
    routeUpdatedAt?: number

    /*
      usually updates with routeUpdate, but when prefetch happens for the route, fetchRouteUpdatedAt changes quicker than routeUpdatedAt.
      used for preventing prefetch / caching twice
    */
    fetchRouteUpdatedAt?: number
    lastPrefetchPath?: string
    sessionUpdatedAt?: number
    prefetchRouteData?: (
      to?: string,
      navigateDirection?: NavigateDirection,
      options?: PrefetchRouteDataOptions,
    ) => void // prefetcher
    SERVICE_WORKER_UPDATE_CHECK_INTERVAL: number
  }
}

// if prefetcher not registered, set sessionUpdatedAt
// webpack code runs after the html code.
if (typeof window !== `undefined` && !window.sessionUpdatedAt) {
  // initializer!
  if (!window.sessionUpdatedAt) {
    window.sessionUpdatedAt = Date.now()
  }
  if (!window.routeUpdatedAt) {
    window.routeUpdatedAt = Date.now()
  }
  if (!window.fetchRouteUpdatedAt) {
    window.fetchRouteUpdatedAt = Date.now()
  }
}

/*
  TODO only fire when route actually changes???? hmm,,,
  hash change triggering the route update might not be good,? dunno.
  Had problems with onPopState, went through by removing real time anchor(hash) replace.
*/
// skip route update once! (prefetcher might have set it!)
let isInitialPreRouteUpdate = true
export const onPreRouteUpdate: GatsbyBrowser[`onPreRouteUpdate`] = () => {
  if (!isInitialPreRouteUpdate) {
    // on route change!
    window.routeUpdatedAt = Date.now()
    onRouteChange(window.routeUpdatedAt)
  } else {
    isInitialPreRouteUpdate = false
  }

  // If inline prefetcher is not installed, prefetch should be called within preRouteUpdate :)
  // location of onPreRouteUpdate should be the new Route's one.
  /*
    prefetchRouteData updates fetchRouteUpdatedAt.
  */
  prefetchRouteData()

  const currentPath = getCurrentPath()

  // did prefetch code fail? update fetchRouteUpdatedAt in order to flush route caches vvv
  // if prefetchRouteData is not registered
  if (!window.prefetchRouteData) {
    window.fetchRouteUpdatedAt = Date.now()
  }

  if (!window.lastPrefetchPath) {
    // Don't prefetch for current path!!
    window.lastPrefetchPath = currentPath
  }

  /*
    add url flush.
  */
}

export const registerServiceWorker: GatsbyBrowser[`registerServiceWorker`] =
  () => process.env.GATSBY_IS_PREVIEW !== `true`

export const onRouteUpdate: GatsbyBrowser[`onRouteUpdate`] = () => {
  swOnRouteUpdate()
}

export const onServiceWorkerUpdateFound: GatsbyBrowser[`onServiceWorkerUpdateFound`] =
  () => {
    swOnServiceWorkerUpdateFound()
  }

// send skipWaiting to set active!
export const onServiceWorkerUpdateReady: GatsbyBrowser[`onServiceWorkerUpdateReady`] =
  () => {
    swOnServiceWorkerUpdateReady()
  }

export const onServiceWorkerActive: GatsbyBrowser[`onServiceWorkerActive`] =
  () => {
    swOnServiceWorkerActive()
  }

// Root Wrapper
export { wrapPageElement, wrapRootElement }
