import React from 'react'
import { useGetter } from 'hooks/use-getter'

export const KeyboardPoppedContext = React.createContext(false)
export const FieldIsFocusedHandleContext = React.createContext(
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  (fieldId: string, focused: boolean) => {
    /* no-op */
  },
)

export const KeyboardPoppedProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [latestFocusedFieldId, setLatestFocusedFieldId] = React.useState<
    string | null
  >(null)
  const [keyboardPopped, setKeyboardPopped] = React.useState(false)

  const getLatestFocusedFieldId = useGetter(latestFocusedFieldId)

  const onFieldIsFocused = React.useCallback(
    (fieldId: string, focused: boolean) => {
      if (focused) {
        setLatestFocusedFieldId(fieldId)
        setKeyboardPopped(true)
        // we'll just leave latest field change overrides.
        /* eslint-disable-next-line no-constant-condition */
      } else if (true || getLatestFocusedFieldId() === fieldId) {
        setKeyboardPopped(false)
      }
    },
    [getLatestFocusedFieldId],
  )

  return (
    <FieldIsFocusedHandleContext.Provider value={onFieldIsFocused}>
      <KeyboardPoppedContext.Provider value={keyboardPopped}>
        {children}
      </KeyboardPoppedContext.Provider>
    </FieldIsFocusedHandleContext.Provider>
  )
}

export const useKeyboardPopped = () => {
  return React.useContext(KeyboardPoppedContext)
}

export const useFieldIsFocusedHandle = () => {
  return React.useContext(FieldIsFocusedHandleContext)
}
