import React from 'react'
import { AuthType } from 'components/auth'

// global

export const AuthConcierge = ({
  type,
  isSeller,
}: {
  type: AuthType
  isSeller: boolean
}) => {
  const userDescription = (
    <div className="description t">
      농어부님들과 우리 모두를 연결해주는 진짜 농수산물 산지직송 직거래 플랫폼,
      루트입니다.
      <br />
      농장에서 우리집까지 하루만에 도착하는 신선함을 경험하세요!
    </div>
  )
  const sellerDescription = (
    <div className="description t">
      1. &quot;진짜&quot; 농어부만 가입할 수 있어요!
      <br />
      2. 상품 등록까지 단 3분 안에 할 수 있어요!
      <br />
      3. 소비자와 직접 소통하고 판매해보세요!
      <br />
      4. 이야기를 자주 올리면 상위 노출 기회가 생겨요!
    </div>
  )

  const descriptionAndExpandButton = isSeller
    ? sellerDescription
    : userDescription

  if (type === `signin`) {
    return (
      <>
        <div className="concierge t3">
          재방문 전화번호입니다.
          <br />
          비밀번호를 입력해주세요!
        </div>
        {/* {descriptionAndExpandButton} */}
      </>
    )
  }

  if (type === `password-reset`) {
    return (
      <>
        <div className="concierge t3">
          인증을 완료하여
          <br />
          비밀번호를 새로 설정하세요!
        </div>
        {/* {descriptionAndExpandButton} */}
      </>
    )
  }

  if (type === `signup`) {
    return (
      <div className="concierge t3">
        <span className="concierge-task">루트</span>를 전화번호만으로
        <br />
        <span className="concierge-task">간편하게</span> 사용해보세요!
      </div>
    )
  }

  return (
    <>
      <div className="concierge t3">
        <span className="concierge-task">루트</span>를 전화번호만으로
        <br />
        <span className="concierge-task">간편하게</span> 사용해보세요!
      </div>
      {descriptionAndExpandButton}
    </>
  )
}
