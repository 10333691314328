import React from 'react'
import type { TouchGestureBindMethodContainer } from 'util/gesture'
import * as styles from './style.module.less'

export const DarkBackground = ({
  children,
  className,
  // vv touchGestureBindMethodContainer
  onMouseDown,
  onMouseMove,
  onMouseUp,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
  onTouchCancel,
}: {
  children?: React.ReactNode
  className?: string
} & TouchGestureBindMethodContainer<HTMLDivElement>) => {
  return (
    /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
    <div
      className={`${styles.rootContainer} ${className || ``}`}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onTouchCancel={onTouchCancel}
    >
      {children}
    </div>
  )
}
