export const getExtension = (src) => {
  let extension = src.match(/\.[^.]*?$/)
  if (extension) {
    extension = extension[0].replace('.', '')
    return extension
  }
  return null
}

export const isImageExtension = (src) => {
  const extension = getExtension(src)
  if (extension) {
    return extension.match(/jpg|jpeg|png|gif|svg|ico|webp/)
  }

  return false
}

export const isVideoExtension = (src) => {
  const extension = getExtension(src)
  if (extension) {
    return extension.match(/mp4|ogg|webm/)
  }

  return false
}
