import React from 'react'
import { prefetchRouteData } from 'src/services/prefetch'
import { getCurrentPath } from 'util/url'
import {
  getSlugMatchingComponent,
  useOpenOverlayHandle,
} from 'hooks/use-overlay'
import { Link as GatsbyLink } from 'gatsby'
import { triggerOnPreNavigate } from 'hooks/use-on-pre-navigate'

export const OverlayLink = ({
  children,
  id,
  className,
  'aria-label': ariaLabel,
  onClick,
  to,
}: {
  children: React.ReactNode
  id?: string
  className?: string
  'aria-label': string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  to: string | null
}) => {
  const openOverlayHandle = useOpenOverlayHandle()
  return (
    <button
      type="button"
      id={id}
      className={className}
      aria-label={ariaLabel}
      onClick={(e) => {
        if (onClick) {
          onClick(e)
        }

        if (!e.defaultPrevented) {
          if (to) {
            openOverlayHandle(to)
          }
        }
      }}
    >
      {children}
    </button>
  )
}

export const Link = ({
  children,
  state,
  replace = false, // was leavePrevUrl
  useOverlay = false,
  to,
  overlayTo: _overlayTo,
  id,
  className,
  style,
  onClick,
  'aria-label': ariaLabel,
  rel,
  // microdata
  itemProp,
  property,
  //
  itemScope,
  itemType,
  vocab,
  typeof: _typeof,
  //
  resource,
  itemID,
}: {
  children: React.ReactNode
  state?: Navigation.State
  to: string | null
  overlayTo?: string
  replace?: boolean
  useOverlay?: boolean
  /*
    Need to change when we implement label overlay, product overlay.
  */
  id?: string
  className?: string
  style?: React.CSSProperties
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  'aria-label': string
  rel?: string

  //
  itemProp?: string
  property?: string
  itemScope?: boolean
  itemType?: string
  vocab?: string
  typeof?: string
  resource?: string
  itemID?: string
}) => {
  const openOverlayHandle = useOpenOverlayHandle()

  if (!to) {
    return (
      <div id={id} className={className}>
        {children}
      </div>
    )
  }

  /*
    prefetch required : get TO
  */
  /*
  We expect link to be mounted on route change.
 */
  return (
    <GatsbyLink
      rel={rel}
      to={to}
      state={{
        innerNavigated: true,
        prevPath: getCurrentPath(),
        ...(state || null),
      }}
      replace={replace}
      id={id}
      className={className}
      style={style}
      onClick={(e) => {
        if (onClick) {
          onClick(e)
        }

        if (!e.defaultPrevented) {
          const overlayTo = _overlayTo || to
          if (useOverlay && getSlugMatchingComponent(overlayTo)) {
            // open overlay instead of following link
            e.preventDefault()
            openOverlayHandle(overlayTo)
          } else {
            prefetchRouteData(to, `forward`)
            triggerOnPreNavigate()
          }
        }
      }}
      aria-label={ariaLabel}
      //
      itemProp={itemProp}
      property={property}
      itemScope={itemScope}
      itemType={itemType}
      vocab={vocab}
      typeof={_typeof}
      resource={resource}
      itemID={itemID}
    >
      {children}
    </GatsbyLink>
  )
}
