/*
  There are dependencies that use this legacy theme.
  make sure that they match the values in less theme.
*/

type Breakpoint =
  | `smallPhone`
  | `largePhone`
  | `smallTablet`
  | `bigTablet`
  | `horizontalTablet`

type MediaQueryCollectionEntry = Record<Breakpoint, string>
interface MediaQueryCollection {
  below: MediaQueryCollectionEntry
  above: MediaQueryCollectionEntry
}

export const breakpoints: Record<Breakpoint, number> = {
  smallPhone: 400,
  largePhone: 500,
  smallTablet: 768,
  bigTablet: 1000,
  horizontalTablet: 1400,
}

const buildMediaQuery: () => MediaQueryCollection = () => {
  const below: Partial<MediaQueryCollectionEntry> = {}
  const above: Partial<MediaQueryCollectionEntry> = {}

  Object.entries(breakpoints).forEach(([k, v]) => {
    below[k as Breakpoint] = `(max-width: ${v - 1}px)`
    above[k as Breakpoint] = `(min-width: ${v}px)`
  })

  return {
    below,
    above,
  } as MediaQueryCollection
}
export const mediaQuery = buildMediaQuery()

export const palette = {
  primary: `#fc421d`,
  secondary: `#ded9cf`,
  agricultural: `#d4b16f`,
  livestock: `#3f4537`,
  aquatic: `#011e41`,
  textGray: `#666666`,
  textGrayMisc: `#999999`,

  textGrayMenu: `#786e5b`,
  textGrayBorder: `#eeeeee`,
  grayBackground: `#ded9cf`,
  background: `#FFFFFF`,
  black: `#111111`,

  link: `#006cda`,
  mint: `#25bba0`,
  error: `#ED4337`,
  hairline: `#aaa`,
}

export const general = {
  contentColor: `#fc421d`,
  contentStyle: `translucent`,
}

export const safeArea = {
  top: `max(var(--safe-area-inset-top, 0px), env(safe-area-inset-top, 0px))`,
  bottom: `env(safe-area-inset-bottom, 0px)`, // We're not using transparent navigation bar in android.
  /*
    Q : why not using transparent navigation bar in android? 
    A : 
      Fullscreen should be set in order to use transparent status bar or navigation bar.
      Webview needs resize when keyboard popped, if fullscreen set. 
      We use @capacitor/keyboard to handle the resizing.
      However, handling keyboard resizing of the webview doesnt work well with the navigation bar system boundary.
      Therefore we give up navigation bar transparency.
  */
  //  bottom: `max(var(--safe-area-inset-bottom, 0px), env(safe-area-inset-bottom, 0px))`,
}
