import React from 'react'
import { select } from 'util/select'
import { getWeightCountData } from 'logic/content/product'
// import serviceInformationConfig from 'values/service-information.json'
export const getDisplayProductName = (data: Product | null) => {
  if (!data) {
    return ``
  }
  const [productName = ``]: [string] = select(data, [
    `public.content.productName`,
  ])

  const { mainWeightCountText } = getWeightCountData(data)

  if (mainWeightCountText) {
    // 5kg x 3개 -> 5kg
    // 5kg -> 5kg
    // 3개 -> 3개
    const [majorSegment] = mainWeightCountText.split(` `)
    if (productName.indexOf(majorSegment) !== -1) {
      return `${productName}${mainWeightCountText.replace(majorSegment, ``)}`
    }
    return `${productName} ${mainWeightCountText}`
  }

  return productName
}

export const getDeliveryFeeNumber = (data: Product | null) => {
  if (!data) {
    return -1
  }

  const [deliveryFee = 0, deliveryFeeType, combinedDeliveryFee = 0]: [
    number,
    DeliveryFeeType,
    number,
  ] = select(data, [
    `public.content.deliveryFee`,
    `public.content.deliveryFeeType`,
    `_populated.owner.public.data.commerce.combinedDeliveryFee`,
  ])

  switch (deliveryFeeType) {
    case `combined`:
      return combinedDeliveryFee
    case `free`:
      return 0
    case `separate`:
      return deliveryFee
    default:
      return -1
  }
}

export const getDeliveryFeeElement = (
  data: Product | null,
  isJeju = false,
  isIslandAndMountain = false,
  delimiter: React.ReactNode = <br />,
) => {
  if (!data) {
    return ``
  }

  const [
    deliveryFeeType,
    banJejuDelivery,
    banIslandAndMountainDelivery,
    jejuExtraDeliveryFee,
    islandAndMountainExtraDeliveryFee,
  ]: [DeliveryFeeType, boolean, boolean, number, number] = select(data, [
    `public.content.deliveryFeeType`,
    `public.content.banJejuDelivery`,
    `public.content.banIslandAndMountainDelivery`,
    `_populated.owner.public.data.commerce.jejuExtraDeliveryFee`,
    `_populated.owner.public.data.commerce.islandAndMountainExtraDeliveryFee`,
  ])

  const isJejuBanned =
    banJejuDelivery || typeof jejuExtraDeliveryFee === `undefined`
  const isIslandAndMountainBanned =
    banIslandAndMountainDelivery ||
    typeof islandAndMountainExtraDeliveryFee === `undefined`
  const deliveryFeeNumber = getDeliveryFeeNumber(data)

  let baseDeliveryFeeMessage = ``

  switch (deliveryFeeType) {
    case `combined`:
      baseDeliveryFeeMessage = `${deliveryFeeNumber.toLocaleString()}원 묶음배송`
      break
    case `free`:
      baseDeliveryFeeMessage = `무료배송`
      break
    case `separate`:
      baseDeliveryFeeMessage = `${deliveryFeeNumber.toLocaleString()}원 개별배송`
      break
    default:
      return ``
  }

  const jejuElement = isJeju ? (
    isJejuBanned ? (
      <>
        {delimiter}
        제주도 배송불가
      </>
    ) : (
      <>
        {delimiter}
        {`${jejuExtraDeliveryFee?.toLocaleString()}원 제주도 추가`}
      </>
    )
  ) : null

  const islandAndMountainElement = isIslandAndMountain ? (
    isIslandAndMountainBanned ? (
      <>
        {delimiter}
        도서산간 배송불가
      </>
    ) : (
      <>
        {delimiter}
        {`${islandAndMountainExtraDeliveryFee?.toLocaleString()}원 도서산간 추가`}
      </>
    )
  ) : null

  return (
    <>
      {baseDeliveryFeeMessage}
      {jejuElement}
      {islandAndMountainElement}
    </>
  )
}

export const getItemTotalPrice = (data: Product | null) => {
  if (!data) {
    return -1
  }

  const [price = 0] = select(data, [`public.content.price`])

  const deliveryFee = getDeliveryFeeNumber(data)
  return price + deliveryFee
}
