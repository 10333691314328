import React from 'react'
import { joinPath } from 'util/url'
import { imageWidthBreakpoints, compressReservedWords } from 'logic/media/image' // TODO typescriptify

import deploymentConfig from 'values/deployment.json'

import * as styles from './style.module.less'

const { mediaServerHost, siteDomain } = deploymentConfig

/*
 * Loads image from storage cdn
 *
 * */

export const Image = React.forwardRef<
  HTMLImageElement,
  {
    src: string
    sizes: string
    widths?: number[]
    alt: string
    width: number
    height: number
    id?: string
    className?: string
    lazy?: boolean
    //
    itemProp?: string
    property?: string
  }
>(
  (
    {
      src = ``,
      sizes,
      widths,
      id,
      className,
      alt,
      width,
      height,
      itemProp,
      property,
      lazy = true,
    },
    ref,
  ) => {
    let isExternal = false

    if (src.indexOf(`http://`) !== -1 && src.indexOf(`https://`) !== -1) {
      isExternal = true
    }

    if (!isExternal) {
      let translatedSrc: string

      const prefixedSrc = compressReservedWords(joinPath(`/media`, src))

      if (mediaServerHost === siteDomain) {
        // use relative url.
        /*
          This code is not robust.
          slashes, schema, etc alterations to either mediaServerHost or siteDomain will fail to use relative urls.
        */
        translatedSrc = prefixedSrc
      } else {
        translatedSrc = joinPath(`https://${mediaServerHost}`, prefixedSrc)
      }
      /*
        If src contains query, this code breaks!!!
        :)
      */
      let srcSetWidths: number[]
      if (widths) {
        const tempWidths = [...widths].sort()
        srcSetWidths = []
        for (let i = 0; i < tempWidths.length; i += 1) {
          if (i !== tempWidths.length) {
            if (tempWidths[i + 1] <= tempWidths[i] * 1.2) {
              // skip!
              // not a good algo, since increasing widths will eventually skip every window.
              // However, it does its job now, so we'll leave it.
              // eslint-disable-next-line no-continue
              continue
            }
          }
          srcSetWidths.push(tempWidths[i])
        }
      } else {
        // default to all size
        srcSetWidths = imageWidthBreakpoints
      }

      const srcSet = srcSetWidths
        .map((width) => `${translatedSrc}?w=${width} ${width}w`)
        .join(`, `)

      return (
        <img
          ref={ref}
          src={translatedSrc}
          srcSet={srcSet}
          sizes={sizes}
          loading={lazy ? `lazy` : undefined}
          // optional?
          id={id}
          className={`${styles.rootContainer} ${className || ``}`}
          alt={alt}
          width={width}
          height={height}
          //
          itemProp={itemProp}
          property={property}
        />
      )
    }

    return (
      <img
        ref={ref}
        src={src}
        id={id}
        className={`${styles.rootContainer} ${className || ``}`}
        loading={lazy ? `lazy` : undefined}
        alt={alt}
        width={width}
        height={height}
        //
        itemProp={itemProp}
        property={property}
      />
    )
  },
)
