import React from 'react'

/*
    yield to other promises. // not really tested well
    yield to react render
    yield to frame

    quantize

    execute after all waiting tasks.
    skip render frame.
*/

// quantized callback handler runs!
let callbackWaitList: (() => void)[] = []

let quantizedLoopEnabled = false
let quantizedLoopExecuting = false
let currentQuantizedLoopCallbackIndexBoundary = 0
let quantizedLoopExecutedAtMilliseconds = 0
const quantizedLoopCooldownMilliseconds = 250 // quantize 250ms

export type ExecuteLastType = `next-frame` | `deferred` | `direct` | `quantized`
export const executeLast = (
  callback: () => void,
  executeLastType: ExecuteLastType = `deferred`,
) => {
  switch (executeLastType) {
    case `deferred`:
      React.startTransition(() => {
        callback()
      })
      break
    case `next-frame`:
      React.startTransition(() => {
        window.setTimeout(() => {
          callback()
        }, 17)
      })
      break
    case `quantized`:
      callbackWaitList.push(callback)

      // if can execute,
      if (
        !quantizedLoopExecuting &&
        quantizedLoopExecutedAtMilliseconds +
          quantizedLoopCooldownMilliseconds <
          Date.now()
      ) {
        // add cooldown to defer!
        quantizedLoopExecutedAtMilliseconds = Date.now()
      }
      break
    default:
      callback()
  }
}

const executeQuantizedLoop = () => {
  if (currentQuantizedLoopCallbackIndexBoundary === callbackWaitList.length) {
    return
  }

  quantizedLoopExecuting = true

  const callback = () => {
    const executingCallbackWaitList = callbackWaitList
    const prevQuantizedLoopCallbackIndexBoundary =
      currentQuantizedLoopCallbackIndexBoundary // call from prev to current length
    const targetQuantizedLoopCallbackIndexBoundary = callbackWaitList.length

    if (currentQuantizedLoopCallbackIndexBoundary > 200) {
      callbackWaitList = [] // replace callbackWaitList with new one so that next executions can be queued inside callback
    }
    currentQuantizedLoopCallbackIndexBoundary = callbackWaitList.length

    for (
      let i = prevQuantizedLoopCallbackIndexBoundary;
      i < targetQuantizedLoopCallbackIndexBoundary;
      i += 1
    ) {
      const callback = executingCallbackWaitList[i]
      try {
        callback()
      } catch (e) {
        // quantizedLoop must not die!
        console.error(e)
      }
    }

    quantizedLoopExecutedAtMilliseconds = Date.now()
    quantizedLoopExecuting = false
  }

  callback()
  // React.startTransition(() => {
  // yield to react
  //  window.setTimeout(callback, 17)
  // })
}

// cooldown after previous loop
export const enableQuantizedLoop = () => {
  if (quantizedLoopEnabled) {
    return
  }

  window.setInterval(() => {
    // not executing and cooldown passed
    if (
      !quantizedLoopExecuting &&
      quantizedLoopExecutedAtMilliseconds + quantizedLoopCooldownMilliseconds <
        Date.now()
    ) {
      executeQuantizedLoop()
    }
  }, 32)
  quantizedLoopEnabled = true
}
