import React from 'react'
import { useUid } from 'hooks/use-uid'
import { Capacitor } from '@capacitor/core'
import { useAsyncCallback } from 'hooks/use-async-callback'
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications'
import { syncPushTokenState, checkPermissions } from 'model/app/push'
import { hookRefreshesWith } from 'util/hook-refreshes-with'
import { consumeResumeNavigationRequest } from 'hooks/use-consume-app-url'

/*
  Whenever uid changes || root render, synchronizes push token state.
*/
export const useSyncPushTokenState = () => {
  const uid = useUid()

  const sync = useAsyncCallback(async () => {
    if (Capacitor.isNativePlatform()) {
      await checkPermissions()
      await syncPushTokenState()
    }
  }, [])

  React.useEffect(() => {
    hookRefreshesWith(uid)
    sync()
  }, [sync, uid])
}

/*
  TODO : RemoveDeliveredNotifications
  https://capacitorjs.com/docs/apis/push-notifications#removedeliverednotifications
  When app focused, we need to remove this! :)
*/

let pushRegistered = false
export const registerPush = () => {
  // test

  if (typeof window !== `undefined` && Capacitor.isNativePlatform()) {
    if (!pushRegistered) {
      PushNotifications.addListener(`registration`, (token: Token) => {
        console.log(`Push registration success, token: ${token.value}`)
        // this is register!!
        syncPushTokenState(token.value)
      })

      // Some issue with our setup and push will not work
      PushNotifications.addListener(`registrationError`, (error: any) => {
        console.log(
          `Push registration error, ${JSON.stringify(error, null, 2)}`,
        )
      })

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        `pushNotificationReceived`,
        (notification: PushNotificationSchema) => {
          console.log(`push received: ${JSON.stringify(notification)}`)
          // TODO show notification inside the app, send the user to right place!
          // Display an overlay and if tap, move to the link?? hmmm how to handle this elegant way?
        },
      )

      // Method called when tapping on a notification
      PushNotifications.addListener(
        `pushNotificationActionPerformed`,
        (notification: ActionPerformed) => {
          console.log(`Push action performed: ${JSON.stringify(notification)}`)

          const url = notification.notification.data?.url as string
          if (url) {
            const target = `roout.co.kr`
            const index = url.indexOf(target)
            if (index !== -1) {
              const slug = url.substring(index + target.length)
              if (slug) {
                console.log(
                  `push notification deep link consumption : url : ${url} -> slug : ${slug}`,
                )
                // it was previously navigate, but we change this to page reload!!
                consumeResumeNavigationRequest(slug)
              }
            }
          }
        },
      )

      console.log(`push register handlers registered!`)
      pushRegistered = true
    }
  }
}
