import { select } from 'util/select'
import {
  getDisplayProductName,
  getItemTotalPrice,
} from 'components/product-information'

import analyticsConfig from 'values/analytics.json'

const { enableAnalytics } = analyticsConfig

// TODO move to common types!!!

export interface GetCommerceObjectArgs {
  userData: User | UserCartFragment | null
  specification: Specification.Specification | null
  orders?: Order.Order[]
}

export interface GetProductDataArgs {
  productData: Product
  sectionData?: SectionData
  sectionIndex?: number
}

declare global {
  interface Window {
    logAnalytics: boolean
    gtmDataLayer: Analytics.DataLayerObject[] // hmm
  }
}

// TODO move to somewhere else? or section object? hmm?
const getSectionId = (sectionData: SectionData): string => {
  const { container, name } = sectionData
  return `${`${container}_` || ``}${name}`

  /*
  const { parameter, container, cursor, name } = sectionData
  if (!parameter) {
    return name
  }
  let res = `${container}_${name}?`

  if (parameter) {
    Object.entries(parameter).forEach(([k, v], index) => {
      if (index !== 0) {
        res += `&`
      }
      res += `${k}=${encodeURIComponent(v)}`
    })
  }
  if (cursor) {
    res += `#${encodeURIComponent(cursor)}`
  }
  return res
  */
}

const getSectionName = (sectionData: SectionData): string => {
  const { title, subtitle } = sectionData

  return `${title}${subtitle ? ` : ${subtitle}` : ``}`
}

export const handleDefaultEvent = (
  eventName: string,
  dataLayerObject: Analytics.DataLayerObject | null = null,
  {
    clearObject = true,
  }: {
    clearObject?: boolean
  } = {},
) => {
  const log = !enableAnalytics || window.logAnalytics

  if (log) {
    console.log(
      `request ${eventName}, dataLayerObject : \n${JSON.stringify(
        dataLayerObject,
        null,
        2,
      )}`,
    )
  }

  if (dataLayerObject && clearObject) {
    const clearDict: Analytics.DataLayerObject = {}
    Object.keys(dataLayerObject).forEach((key) => {
      clearDict[key as keyof Analytics.DataLayerObject] = null
    })

    if (log) {
      console.log(
        `clearing ${eventName}, clearDict : \n${JSON.stringify(
          clearDict,
          null,
          2,
        )}`,
      )
    }
    if (enableAnalytics) {
      window.gtmDataLayer.push(clearDict)
    }
  }

  const pushObject = {
    event: eventName,
    ...dataLayerObject,
  }

  if (log) {
    console.log(
      `pushing ${eventName}, pushObject : \n${JSON.stringify(
        pushObject,
        null,
        2,
      )}`,
    )
  }

  if (enableAnalytics) {
    window.gtmDataLayer.push(pushObject)
  }
}
export const getProductData = (
  { productData, sectionData, sectionIndex }: GetProductDataArgs,
  additionalData: {
    count: number
  } | null = null,
): Analytics.Product => {
  const [productId, owner] = select(productData, [
    `id`,
    `public.metadata.owner`,
  ])

  const itemName = getDisplayProductName(productData)
  const itemPrice = getItemTotalPrice(productData)

  const data: Analytics.Product = {
    item_name: itemName, // Name or ID is required.
    item_id: productId,
    item_brand: owner,
    price: itemPrice,
    currency: `KRW`,
    ...additionalData,
  }

  /*
  vvvvv 
    coupon
    discount
    item-category
    affiliation


    quantity,
  */

  if (sectionData) {
    const listId = getSectionId(sectionData)
    const listName = getSectionName(sectionData)
    data.item_list_name = listName
    data.item_list_id = listId

    if (typeof sectionIndex !== `undefined` && sectionIndex !== -1) {
      data.index = sectionIndex
    }
  }

  return data
}

const getEcommerceObjectFromOrders = (
  orders: Order.Order[],
): Analytics.EcommerceObject => {
  const anyOrder = orders[0]
  const [totalPrice, orderSetId]: [number, string] = select(anyOrder, [
    `private.specification.orderSet.totalPrice.totalAmount`,
    `private.metadata.orderSetId`,
  ])

  const products: Array<{
    productData: Product
    count: number
  }> = []

  orders.forEach((order) => {
    const [item, product] = select(order, [
      `private.specification.item`,
      `private.specification.item._populated.product`,
    ])
    const [count] = select(item, [`count`])

    products.push({ productData: product, count })
  })

  const items = products.map(({ productData, count }) =>
    getProductData({ productData }, { count }),
  )

  return {
    transaction_id: orderSetId,
    items,
    value: totalPrice,
    currency: `KRW`,
  }
}

export const getCommerceObject = ({
  userData,
  specification,
  orders,
}: GetCommerceObjectArgs): Analytics.EcommerceObject => {
  if (orders) {
    return getEcommerceObjectFromOrders(orders)
  }

  const products: Array<{
    productData: Product
    count: number
  }> = select(userData, `private.shopping.cart`).map((item: CartItem) => {
    const [productData, count] = select(item, [`_populated.product`, `count`])
    return { productData, count }
  })

  const items = products.map(({ productData, count }) =>
    getProductData({ productData }, { count }),
  )

  let totalPrice = 0
  let isSpecificationReady = false
  if (specification) {
    totalPrice = select(specification, `totalPrice.totalAmount`)
    isSpecificationReady = true
  } else {
    totalPrice = 0
    items.forEach(({ price, count }) => {
      if (count) {
        totalPrice += price * count
      }
    })
  }

  return {
    items,
    value: totalPrice,
    currency: `KRW`,
    is_specification_ready: isSpecificationReady,
  }
}
