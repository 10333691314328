import analyticsConfig from 'values/analytics.json'

let gtagAppended = false
const { gtmId, enableAnalytics } = analyticsConfig

if (typeof window !== `undefined`) {
  window.gtmDataLayer = []
}

export const install = () => {
  if (gtagAppended) {
    return
  }

  if (!enableAnalytics || !gtmId || gtmId === `EMPTY`) {
    console.log(`analytics disabled.`)
    return
  }

  try {
    /*
          <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PSBBXZF');</script>
<!-- End Google Tag Manager -->
          */

    /* eslint-disable */
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: `gtm.js` })
      const f = d.getElementsByTagName(s)[0]
      const j = d.createElement(s)
      const dl = l != `dataLayer` ? `&l=${l}` : ``
      j.async = true
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
      f.parentNode.insertBefore(j, f)
    })(window, document, `script`, `gtmDataLayer`, gtmId)
    /* eslint-enable */

    /* 
          <!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PSBBXZF"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
          */

    const element = document.createElement(`noscript`)
    const iframe = document.createElement(`iframe`)
    iframe.setAttribute(
      `src`,
      `https://www.googletagmanager.com/ns.html?id=${gtmId}`,
    )
    iframe.setAttribute(`height`, `0`)
    iframe.setAttribute(`width`, `0`)
    iframe.setAttribute(`style`, `display:none;visibility:hidden`)
    element.append(iframe)
    document.body.append(element)
  } catch (e) {
    console.error(e)
    console.log(`gtm failed to initialize.`)
  }
  gtagAppended = true
}
