import { createReducer } from '../reducer'

export const defineSimpleTextReducer = (key: string) =>
  createReducer((location) => {
    const givenValue = location.query[key]
    if (givenValue) {
      if (Array.isArray(givenValue)) {
        return givenValue[0]
      }
      return givenValue
    }

    return ``
  }, ``)
