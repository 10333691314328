import React from 'react'
import { useData } from 'hooks/use-data'
import { useInterval } from 'hooks/use-timed-callback'
import { useBoxedCallback } from 'hooks/use-boxed-callback'
import { useGetter } from 'hooks/use-getter'
import { select } from 'util/select'
import { useSynchronousState } from 'hooks/use-synchronous-state'
import { useIsSSR } from 'hooks/use-is-ssr'
import { objectEqual } from 'util/object-equal'
import { useForceUpdate } from 'hooks/use-force-update'

export type GlobalDiscountRegisterHandle = (
  productId: string,
  onUpdate: (discount: Discount.GlobalDiscount | null) => void,
) => () => void

export const GlobalDiscountRegisterHandleContext =
  React.createContext<GlobalDiscountRegisterHandle>(() => {
    /* no-op */
    return () => {
      /* no-op */
    }
  })

export type GlobalDiscountQueryResult = {
  globalDiscounts: Record<string, Discount.GlobalDiscount | null>
  queriedAtSeconds: number
}

const defaultGlobalDiscountQueryResult = {
  globalDiscounts: {},
  queriedAtSeconds: 0,
}

export const GlobalDiscountQueryResultContext =
  React.createContext<GlobalDiscountQueryResult>(
    defaultGlobalDiscountQueryResult,
  )

/*
  TODO : need new-discount-popping-up (although some card lists can set cacheReadConsistency strong)
  session level cached productData cannot show new discount;
  we might need a server list of all discounted productIds????? 
  Therefore we can implement popping of "new discounts" in user window on polling.
*/

/*
    New product components with globa discount registers the productId;
    the products batch updates global discount state.
    
    Instead of using the populated data, product components request global discount state from the context.
*/

export const GlobalDiscountProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  // 1. productIdRegisterHandle
  // 2.

  const targetProductIdsDictBox = React.useRef<Record<string, number>>({})
  const [targetProductIdsCounter, setTargetProductIdsCounter] =
    React.useState<number>(0)

  const onUpdateDictBox = React.useRef<
    Record<
      string,
      ((
        discount: Discount.GlobalDiscount | null,
        currentTimeSeconds?: number,
      ) => void)[]
    >
  >({})

  const latestQueriedTargetProductIdsCounterBox = React.useRef<number>(
    targetProductIdsCounter,
  )
  const getTargetProductIdsCounter = useGetter(targetProductIdsCounter)
  const [targetProductIdsQuery, setTargetProductIdsQuery] =
    React.useState<string>(``)

  // TODO : use this value if we need a dict! right now we're only using listenre
  const [globalDiscounts, , reload] = useData<
    Record<string, Discount.GlobalDiscount | null>
  >(`/global-discounts/batch?productIds=${targetProductIdsQuery}`, {
    cacheReadConsistency: `strong`,
  })

  const onInterval = useBoxedCallback(() => {
    // 1. update query if counter changed
    // 2. reload if
    const targetProductIdsCounter = getTargetProductIdsCounter()
    if (
      latestQueriedTargetProductIdsCounterBox.current ===
      targetProductIdsCounter
    ) {
      reload()
    } else {
      const keys = Object.keys(targetProductIdsDictBox.current)
      setTargetProductIdsQuery(keys.join(`,`))
    }
    latestQueriedTargetProductIdsCounterBox.current = targetProductIdsCounter
  }, [getTargetProductIdsCounter, reload])

  const [startInterval, endInterval] = useInterval<void>(onInterval, 5000) // ping every 5 seconds
  React.useEffect(() => {
    startInterval()
    return endInterval
  }, [startInterval, endInterval])

  const [globalDiscountQueryResultBox, setGlobalDiscountQueryResult] =
    useSynchronousState<GlobalDiscountQueryResult>(
      defaultGlobalDiscountQueryResult,
      false,
    )
  // updates when globalDiscount is set!
  React.useMemo(() => {
    if (globalDiscounts) {
      setGlobalDiscountQueryResult({
        globalDiscounts,
        queriedAtSeconds: Math.floor(Date.now() / 1000),
      })
    }
  }, [globalDiscounts, setGlobalDiscountQueryResult])

  React.useEffect(() => {
    // on globalDiscount change, iterate listeners and call callback!
    if (!globalDiscounts) {
      // globalDiscounts returns empty dict if none available.
      return
    }

    Object.entries(globalDiscounts).forEach(
      ([productId, globalDiscount]: [
        string,
        Discount.GlobalDiscount | null,
      ]) => {
        const onUpdateList = onUpdateDictBox.current[productId] || []
        onUpdateList.forEach((onUpdate) => {
          onUpdate(globalDiscount || null)
        })
      },
    )
  }, [globalDiscounts])

  const globalDiscountRegisterHandle = useBoxedCallback(
    (
      productId: string,
      onUpdate: (
        discount: Discount.GlobalDiscount | null,
        queriedAtSeconds?: number,
      ) => void,
    ) => {
      if (productId in targetProductIdsDictBox.current) {
        targetProductIdsDictBox.current[productId] += 1
      } else {
        targetProductIdsDictBox.current[productId] = 1
        setTargetProductIdsCounter(targetProductIdsCounter + 1)
      }

      if (productId in onUpdateDictBox.current) {
        onUpdateDictBox.current[productId].push(onUpdate)
      } else {
        onUpdateDictBox.current[productId] = [onUpdate]
      }

      return () => {
        if (productId in targetProductIdsDictBox.current) {
          const prevValue = targetProductIdsDictBox.current[productId]
          if (prevValue <= 1) {
            delete targetProductIdsDictBox.current[productId]

            setTargetProductIdsCounter(targetProductIdsCounter + 1)
          } else {
            targetProductIdsDictBox.current[productId] -= 1
          }
        }

        // remove refresh handle
        if (productId in onUpdateDictBox.current) {
          const newList = onUpdateDictBox.current[productId].filter(
            (e) => e !== onUpdate,
          )
          if (newList.length > 0) {
            onUpdateDictBox.current[productId] = newList
          } else {
            delete onUpdateDictBox.current[productId]
          }
        }
      }
    },

    [targetProductIdsCounter],
  )

  return (
    <GlobalDiscountRegisterHandleContext.Provider
      value={globalDiscountRegisterHandle}
    >
      <GlobalDiscountQueryResultContext.Provider
        value={globalDiscountQueryResultBox.current}
      >
        {children}
      </GlobalDiscountQueryResultContext.Provider>
    </GlobalDiscountRegisterHandleContext.Provider>
  )
}

export const useGlobalDiscountQueryResult = () => {
  return React.useContext(GlobalDiscountQueryResultContext)
}

/*
    It's better to regsiter refreshHandle!
*/
export const useGlobalDiscount = (
  productData: Product | null,
  enabled: boolean,
  forceListen?: boolean,
) => {
  const isSSR = useIsSSR()
  // request from the context, registers and deregisters. (only when the product has globalDiscount field populated.)
  // register only if prou

  const globalDiscountRegisterHandle = React.useContext(
    GlobalDiscountRegisterHandleContext,
  )

  const [
    productId,
    shouldRegister,
    productDataGlobalDiscount,
    productDataQueriedAtSeconds,
    isProductValid,
  ] = React.useMemo(() => {
    const [
      productId,
      globalDiscount,
      queriedAtSeconds,

      isHistory,
      visibility,
      isSellerValid,
      isProductValid,
      isOnSale,
    ]: [
      string,
      Discount.GlobalDiscount | null,
      number | undefined,
      boolean,
      Visibility,
      boolean,
      boolean,
      boolean,
    ] = select(productData, [
      `id`,
      `_populated.globalDiscount`,
      `_queriedAtSeconds`,
      `public.metadata.isHistory`,
      `_visibility`,
      `public.commerce.isSellerValid`,
      `public.commerce.isProductValid`,
      `public.commerce.isOnSale`,
    ])

    const isValid =
      !isHistory &&
      visibility === `visible` &&
      isSellerValid &&
      isProductValid &&
      isOnSale

    const shouldRegister = isValid && productId && !!globalDiscount // globalDiscount was provided, track it!
    return [
      productId,
      shouldRegister,
      globalDiscount,
      queriedAtSeconds,
      isValid,
    ]
  }, [productData])

  const [globalDiscountBox, _setGlobalDiscount] =
    useSynchronousState<Discount.GlobalDiscount | null>(null)

  const latestQueriedAtSecondsBox = React.useRef(0)
  const setGlobalDiscount = React.useCallback(
    (
      globalDiscount: Discount.GlobalDiscount | null,
      queriedAtSeconds?: number,
    ) => {
      if (
        typeof queriedAtSeconds === `undefined` ||
        queriedAtSeconds >= latestQueriedAtSecondsBox.current
      ) {
        const prevGlobalDiscount = globalDiscountBox.current
        // diff check
        const isDifferent = !objectEqual(globalDiscount, prevGlobalDiscount)

        if (isDifferent) {
          _setGlobalDiscount(globalDiscount)

          if (typeof queriedAtSeconds === `undefined`) {
            latestQueriedAtSecondsBox.current = Date.now() / 1000
          } else {
            latestQueriedAtSecondsBox.current = queriedAtSeconds
          }
        }
      }
    },
    [_setGlobalDiscount, globalDiscountBox],
  )
  React.useMemo(() => {
    if (isProductValid && enabled && productId) {
      setGlobalDiscount(productDataGlobalDiscount, productDataQueriedAtSeconds)
    }
  }, [
    isProductValid,
    setGlobalDiscount,
    productDataGlobalDiscount,
    productDataQueriedAtSeconds,
    enabled,
    productId,
  ])

  const globalDiscount = globalDiscountBox.current

  React.useEffect(() => {
    if (
      isProductValid &&
      enabled &&
      (forceListen || shouldRegister) &&
      productId
    ) {
      const unregister = globalDiscountRegisterHandle(
        productId,
        setGlobalDiscount,
      )

      return () => {
        unregister()
      }
    }
  }, [
    isProductValid,
    enabled,
    forceListen,
    productId,
    shouldRegister,
    globalDiscountRegisterHandle,
    setGlobalDiscount,
  ])

  if (!enabled || isSSR) {
    return null
  }

  return globalDiscount
}

// VV the code below rerenders the component every seconds.
// we must wrap it inside a new component!!!
// Therefore we cannot use it in specification;
export const useIsGlobalDiscountAvailable = (
  globalDiscount: Discount.GlobalDiscount | null,
  updateOnCurrentTimeChange = false,
): [boolean, number] => {
  const forceUpdate = useForceUpdate()
  const currentTimeSecondsBox = React.useRef<number>(0)

  const isGlobalDiscountAvailableBox = React.useRef<boolean>(false)
  const isGlobalDiscountAvailable = isGlobalDiscountAvailableBox.current
  const currentTimeSeconds = currentTimeSecondsBox.current

  const updateDiscountState = React.useCallback(
    (triggerRender = false) => {
      const newCurrentTimeSeconds = Math.floor(Date.now() / 1000)
      currentTimeSecondsBox.current = newCurrentTimeSeconds

      const [durationFromSeconds, durationToSeconds, isActive]: [
        number,
        number,
        boolean,
      ] = select(globalDiscount, [
        `public.duration.from._seconds`,
        `public.duration.to._seconds`,
        `public.state.isActive`,
      ])

      const isGlobalDiscountAvailable = !!(
        globalDiscount &&
        isActive &&
        durationFromSeconds <= currentTimeSecondsBox.current &&
        durationToSeconds >= currentTimeSecondsBox.current
      )

      if (
        triggerRender ||
        isGlobalDiscountAvailableBox.current !== isGlobalDiscountAvailable
      ) {
        // render!
        forceUpdate()
      }

      isGlobalDiscountAvailableBox.current = isGlobalDiscountAvailable
    },
    [globalDiscount, forceUpdate],
  )

  React.useEffect(() => {
    updateDiscountState(true)
  }, [updateDiscountState, globalDiscount])

  const onInterval = React.useCallback(() => {
    updateDiscountState(updateOnCurrentTimeChange)
  }, [updateOnCurrentTimeChange, updateDiscountState])

  const [startInterval, endInterval] = useInterval<void>(onInterval, 1000)

  React.useEffect(() => {
    if (isGlobalDiscountAvailable) {
      startInterval()
      return endInterval
    }
  }, [startInterval, endInterval, isGlobalDiscountAvailable])

  return [isGlobalDiscountAvailable, currentTimeSeconds]
}
