// mutates the resultObject
export default (targetObject, keys) => {
  let isSingleValue = false
  if (!Array.isArray(keys)) {
    keys = [keys]
    isSingleValue = true
  }

  const res = []
  keys.forEach((key) => {
    const arr = key.split(`.`)
    let value = targetObject
    for (let j = 0; j < arr.length; j += 1) {
      if (value && typeof value === 'object') {
        value = value[arr[j]]
      } else {
        value = undefined
      }
    }
    res.push(value)
  })
  if (isSingleValue) {
    return res[0]
  }

  return res
}
