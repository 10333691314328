import {
  AppTrackingTransparency,
  AppTrackingStatus,
} from 'capacitor-plugin-app-tracking-transparency'
import { Capacitor } from '@capacitor/core'
import { checkIfVersionSatisfied } from 'hooks/use-app-info'

let appTrackingStatus: AppTrackingStatus | null = null
// TODO read from appTrackingStatus before sending tracking data. (opt in for ios)
/*
  TODO Needs to get smarter about request timing!! 
*/
/*
    Checks status, if can request permission, request permission.
    TrackingPermissions is about data not leaving the device;
*/
export const registerTrackingPermission = async () => {
  //
  if (Capacitor.isNativePlatform()) {
    if (
      Capacitor.getPlatform() === `ios` &&
      (await checkIfVersionSatisfied(`1.1.0`))
    ) {
      const response = await AppTrackingTransparency.getStatus()
      appTrackingStatus = response.status
      if (appTrackingStatus === `notDetermined`) {
        const response = await AppTrackingTransparency.requestPermission()
        appTrackingStatus = response.status
      }
    } else if (Capacitor.getPlatform() === `android`) {
      appTrackingStatus = `authorized` // android authorized for default!
    }
  }
}
