import { request } from 'util/request'
import deploymentConfig from 'values/deployment.json'

const { apiServerHost } = deploymentConfig

/*
  https://capacitorjs.com/docs/apis/device

  deviceId // uuid
  model //
  platform,
  operatingSystem,
  osVersion,
  manufacturer, 
  webViewVersion,


*/

export type OnSessionEnterParams = {
  referringSessionId?: string
  landingUrl: string
  screenWidth: number
  screenHeight: number
  //
  deviceId?: string // uuid
  model?: string //
  platform?: string
  operatingSystem?: string
  osVersion?: string
  manufacturer?: string
  webViewVersion?: string
}

export const onSessionEnter = async ({
  referringSessionId,
  landingUrl,
  screenWidth,
  screenHeight,
  //
  deviceId, // uuid
  model, //
  platform,
  operatingSystem,
  osVersion,
  manufacturer,
  webViewVersion,
}: OnSessionEnterParams) => {
  const paramsDict: Record<string, string | undefined> = {
    referringSessionId,
    landingUrl,
    screenWidth: `${screenWidth}`,
    screenHeight: `${screenHeight}`,
    //
    deviceId, // uuid
    model, //
    platform,
    operatingSystem,
    osVersion,
    manufacturer,
    webViewVersion,
  }

  Object.entries(paramsDict).forEach(([k, v]) => {
    if (typeof v === `undefined`) {
      delete paramsDict[k as keyof typeof paramsDict]
    }
  })

  const paramsObject = new URLSearchParams(paramsDict as Record<string, string>)

  try {
    await request(
      `POST`,
      apiServerHost,
      `/action-log/session-enter?${paramsObject}`,
      {
        auth: true,
      },
    )
  } catch (e) {
    console.error(`error on action log : onSessionEnter`)
    console.error(e)
  }
}
