import React from 'react'
import { useGetter } from 'hooks/use-getter'
// import { IoIosMore } from '@react-icons/all-files/io/IoIosMore'
import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack'
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward'
import { useThrottledEvent } from 'hooks/use-throttled-event'
import { breakpoints } from 'src/theme'
import { useViewportWidth } from 'hooks/use-viewport-dimension'
import { useHasFCP } from 'hooks/use-has-fcp'
import { hookRefreshesWith } from 'util/hook-refreshes-with'

import * as styles from './style.module.less'

interface HorizontalScrollButtonBlockProps {
  listRef: React.MutableRefObject<HTMLElement | null>
  elementWidth?: number
  scrollAmount?: number
  overlay?: boolean
  refreshAlways?: boolean
  refreshId?: string
}

/*
  TODO getBoundingClientRect needs optimization / memoization / removal
*/

const HorizontalScrollButtonBlockComponent = ({
  listRef,
  elementWidth = 0,
  scrollAmount,
  overlay = false,
  refreshAlways = false,
  refreshId,
}: HorizontalScrollButtonBlockProps) => {
  const [displayForwardButton, setDisplayForwardButton] = React.useState(false)
  const [displayBackwardButton, setDisplayBackwardButton] =
    React.useState(false)

  const getDisplayForwardButton = useGetter(displayForwardButton)
  const getDisplayBackwardButton = useGetter(displayBackwardButton)
  // eslint-disable-next-line
  const refreshDependency = refreshAlways ? {} : null

  /* Checks whether or not to display buttons */
  const setDisplayButtons = React.useCallback(() => {
    const element = listRef.current

    const currentDisplayForwardButton = getDisplayForwardButton()
    const currentDisplayBackwardButton = getDisplayBackwardButton()
    if (!element) {
      if (currentDisplayForwardButton) {
        setDisplayForwardButton(false)
      }
      if (currentDisplayBackwardButton) {
        setDisplayBackwardButton(false)
      }
      return
    }
    const { width: boundingClientWidth } = element.getBoundingClientRect()
    const { scrollWidth, scrollLeft } = element
    const isScrollEnabled =
      scrollWidth > 0 &&
      boundingClientWidth > 0 &&
      scrollWidth > boundingClientWidth

    if (scrollLeft > 0 && !currentDisplayBackwardButton && isScrollEnabled) {
      setDisplayBackwardButton(true)
    }
    if ((scrollLeft <= 0 && currentDisplayBackwardButton) || !isScrollEnabled) {
      setDisplayBackwardButton(false)
    }

    if (
      scrollLeft + boundingClientWidth < scrollWidth &&
      !currentDisplayForwardButton &&
      isScrollEnabled
    ) {
      setDisplayForwardButton(true)
    }

    if (
      (scrollLeft + boundingClientWidth >= scrollWidth &&
        currentDisplayForwardButton) ||
      !isScrollEnabled
    ) {
      setDisplayForwardButton(false)
    }
  }, [listRef, getDisplayForwardButton, getDisplayBackwardButton])

  /*
    TODO optimize : 
    - use intersectionObserver
  */
  const onScroll = React.useCallback(() => {
    setDisplayButtons()
  }, [setDisplayButtons])

  React.useEffect(() => {
    hookRefreshesWith(refreshId)
    setDisplayButtons()
  }, [setDisplayButtons, refreshId])

  const throttledOnScroll = useThrottledEvent(
    `scroll`,
    onScroll,
    listRef.current,
    150,
    { passive: true },
  )

  // eslint-disable-next-line
  React.useEffect(throttledOnScroll, [])

  const scrollBackward = React.useCallback(() => {
    const element = listRef.current
    if (element) {
      const { width: boundingClientWidth } = element.getBoundingClientRect()
      element.scrollBy({
        left: scrollAmount
          ? -scrollAmount
          : -boundingClientWidth + elementWidth * 1.5,
        behavior: `smooth`,
      })
    }
  }, [listRef, elementWidth, scrollAmount])

  const scrollForward = React.useCallback(() => {
    const element = listRef.current
    if (element) {
      const { width: boundingClientWidth } = element.getBoundingClientRect()

      element.scrollBy({
        top: 0,
        left: scrollAmount || boundingClientWidth - elementWidth * 1.5,
        behavior: `smooth`,
      })
    }
  }, [listRef, elementWidth, scrollAmount])

  return (
    <div className={styles.rootContainer}>
      <button
        type="button"
        className={`${styles.backward} ${
          displayBackwardButton ? `` : styles.disabled
        } ${overlay ? styles.overlay : ``}`}
        onClick={scrollBackward}
      >
        <IoIosArrowBack size={24} />
      </button>
      <button
        type="button"
        className={`${styles.forward} ${
          displayForwardButton ? `` : styles.disabled
        } ${overlay ? styles.overlay : ``}`}
        onClick={scrollForward}
      >
        <IoIosArrowForward size={24} />
      </button>
    </div>
  )
}

/*
  Lazy!
*/
export const HorizontalScrollButtonBlock = (
  props: HorizontalScrollButtonBlockProps,
) => {
  const hasFCP = useHasFCP()
  const viewportWidth = useViewportWidth()

  if (viewportWidth <= breakpoints.bigTablet) {
    return null
  }

  if (!hasFCP) {
    return null
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <HorizontalScrollButtonBlockComponent {...props} />
}
