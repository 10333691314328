import { request } from 'util/request'
import { getUid } from 'model/app/auth'
import { asyncCatch } from 'util/async-catch'
import { Listener } from 'util/listener'
import deploymentConfig from 'values/deployment.json'

const { apiServerHost } = deploymentConfig

// have a method that pings server for notification..?
// listener

const notificationStateListener =
  new Listener<API.Notification.GetNotificationExistsResponse>({
    activity: false,
    commerce: false,
    reward: false,
    review: false,
  })

let notificationCheckInterval: number

export const checkNotification = async ({
  category,
}: {
  category: Notification.NotificationCategory
}) => {
  const uid = getUid()
  if (!uid) {
    return false
  }

  const [err] = await asyncCatch(
    request(
      `PUT`,
      apiServerHost,
      `/${uid}/notification/check?category=${category}`,
      {
        auth: true,
      },
    ),
  )

  if (err) {
    console.error(err)
    return null
  }

  const currentState = notificationStateListener.getValue()
  const updatedState = {
    ...currentState,
    [category]: false,
  }
  notificationStateListener.setValue(updatedState)

  return true
}

export const getNotificationExists = async () => {
  const uid = getUid()
  if (!uid) {
    return {}
  }

  const [err, res] = await asyncCatch(
    request<API.Notification.GetNotificationExistsResponse>(
      `GET`,
      apiServerHost,
      `/${uid}/notification/exists`,
      {
        auth: true,
      },
    ),
  )
  if (!res) {
    console.error(err)
    return null
  }

  const { body } = res

  notificationStateListener.setValue(body)
  return body
}

export const getNotificationExistsState = () => {
  return notificationStateListener.getValue()
}

export const registerNotificationStateChangeListener = (
  method: (state: API.Notification.GetNotificationExistsResponse) => void,
) => {
  if (!notificationCheckInterval && typeof window !== `undefined`) {
    console.log(`registering notification check interval!`)
    notificationCheckInterval = window.setInterval(getNotificationExists, 10000)
    getNotificationExists()
  }

  return notificationStateListener.addListener(method)
}
