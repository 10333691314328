export type Coordinate = [number, number]

export const getRelativeOffset = (
  element: HTMLElement,
  targetContainer: HTMLElement | Window | null = null,
  value: Coordinate = [0, 0],
): Coordinate | null => {
  const res: Coordinate = [
    element.offsetLeft + value[0],
    element.offsetTop + value[1],
  ]

  const parent = element.offsetParent

  if (!parent && element !== window.document.body) {
    return null
  }

  if (
    element === targetContainer ||
    !parent ||
    !(parent instanceof HTMLElement)
    /* 
    typeof parent.offsetTop === `undefined` ||
    typeof parent.offsetLeft === `undefined`
    */
  ) {
    return res
  }

  return getRelativeOffset(parent, targetContainer, res)
}

export const getScroll = (
  currentListElement: HTMLElement | Document | Window,
): Coordinate => {
  if (
    currentListElement instanceof Document ||
    currentListElement instanceof Window
  ) {
    return [window.scrollX, window.scrollY]
  }
  return [currentListElement.scrollLeft, currentListElement.scrollTop]
}
