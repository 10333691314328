import React from 'react'
import PuffLoader from 'react-spinners/PuffLoader'
import { palette } from 'src/theme'

import * as styles from './style.module.less'

export const SubmittingSpinner = ({
  size,
  id,
  className,
}: {
  size: number
  id?: string
  className?: string
}) => {
  return (
    <div
      style={{ width: `${size}px`, height: `${size}px` }}
      id={id}
      className={className}
    >
      <div className={styles.spinnerContainer}>
        <PuffLoader size={size * 0.8} color={palette.primary} />
      </div>
    </div>
  )
}
