import { customAlphabet } from 'nanoid/non-secure'
import { customAlphabet as customAlphabetSecure } from 'nanoid'

const BASE58 = `123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz`

export const createUuid = (length: number) => {
  return customAlphabet(BASE58, length)()
}

export const createUuidSecure = (length: number) => {
  return customAlphabetSecure(BASE58, length)()
}
