import React from 'react'
import { Helmet } from 'react-helmet-async'
// hash not preserved!
import { usePathnameSearch } from 'hooks/use-location'

/*
    TODO : we have an issue that smartbanners are rendering in wierd mode (stuck above ceiling, only visible when momentum scrolling)
    in IOS PWA (add to home) installations.
    We'll just leave it right now.
*/
export const SmartAppBanner = () => {
  const pathnameSearch = usePathnameSearch()

  return (
    <Helmet prioritizeSeoTags>
      <meta
        name="apple-itunes-app"
        content={`app-id=6446498982, app-argument=https://roout.co.kr${pathnameSearch}`}
      />
    </Helmet>
  )
}
