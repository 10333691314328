import React from 'react'
import { useGetter } from 'hooks/use-getter'
import { hookRefreshesWith } from 'util/hook-refreshes-with'
import { useForceUpdate } from 'hooks/use-force-update'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { useBoxedCallback } from 'hooks/use-boxed-callback'
import { useIsSSR } from 'hooks/use-is-ssr'

import { executeLast } from 'util/execute-last'

declare global {
  interface Window {
    logDeferredValue?: boolean
  }
}

const deferredValueLog = (e: string) => {
  if (typeof window !== `undefined`) {
    if (window?.logDeferredValue || window?.localStorage?.logDeferredValue) {
      console.log(e)
    }
  }
}

type DeferState =
  | `synchronized`
  | `waiting-render`
  | `waiting-priority`
  | `unmounted`

interface DeferHandle {
  getPriority: () => number | undefined
  getDeferState: () => DeferState
  triggerUpdate: () => void
}

let deferList: DeferHandle[] = []

const registerDeferHandle = (handle: DeferHandle) => {
  if (deferList.indexOf(handle) === -1) {
    deferList.push(handle)
  }
}

/*
      not so efficient.
      # of handles under 40 won't be a problem though.
  */
const removeDeferHandle = (handle: DeferHandle) => {
  if (deferList.indexOf(handle) !== -1) {
    deferList = deferList.filter((e) => e !== handle)
  }
}

/*
    for all handles in deferList,
    run triggerUpdate for all members of deferList.
*/
const flush = () => {
  let highestPriorityThatNeedsUpdate = 9999999
  // step 1. find highest priority that needs update
  deferList.forEach((handle) => {
    const { getPriority, getDeferState } = handle
    const priority = getPriority()
    const deferState = getDeferState()
    if (deferState === `unmounted`) {
      // if not mounted, skip!
      // remove update
      removeDeferHandle(handle)
    }

    if (deferState === `waiting-render` || deferState === `waiting-priority`) {
      if (
        typeof priority !== `undefined` &&
        priority <= highestPriorityThatNeedsUpdate
      ) {
        highestPriorityThatNeedsUpdate = priority
      }
    }
  })

  // trigger update with current priority
  deferList.forEach(({ getPriority, getDeferState, triggerUpdate }) => {
    const priority = getPriority()
    const deferState = getDeferState()
    if (
      typeof priority === `undefined` ||
      priority <= highestPriorityThatNeedsUpdate
    ) {
      if (deferState === `waiting-priority`) {
        deferredValueLog(`trigger update of priority : ${priority}`)
        triggerUpdate()
      }
    }
  })
}

/*
    On value change, add to defer handle list.

*/

export const usePriorityDeferredValue = <T>(
  value: T,
  {
    priority,
    direct,
    id = null,
  }: {
    priority?: number
    direct?: boolean
    id?: string | null
  } = {},
) => {
  const isSSR = useIsSSR()
  const forceUpdate = useForceUpdate()
  const getIsMounted = useGetIsMounted()
  const isInTransitionBox = React.useRef(false)
  const getGivenValue = useGetter(value)
  const getPriority = useGetter(priority)
  const valueBox = React.useRef<T>(value)
  const prevIdBox = React.useRef<string | null>(id)

  // if direct, set it directly!
  // if id change, set it directly!
  React.useMemo(() => {
    if (direct || isSSR || prevIdBox.current !== id) {
      valueBox.current = value
      prevIdBox.current = id
    }
  }, [value, direct, isSSR, id])

  const getDeferState = useBoxedCallback(() => {
    if (!getIsMounted()) {
      return `unmounted`
    }

    if (isInTransitionBox.current) {
      return `waiting-render`
    }

    if (getGivenValue() === valueBox.current) {
      return `synchronized`
    }

    return `waiting-priority`
  }, [getIsMounted, getGivenValue])

  const synchronize = useBoxedCallback(() => {
    valueBox.current = getGivenValue()
    forceUpdate()
  }, [getGivenValue, forceUpdate])

  const triggerUpdate = useBoxedCallback(() => {
    if (!isInTransitionBox.current) {
      isInTransitionBox.current = true
      const transitionStartTime = Date.now()
      executeLast(() => {
        deferredValueLog(
          `transition took ${Date.now() - transitionStartTime}ms`,
        )

        isInTransitionBox.current = false // transition complete
        if (getIsMounted()) {
          synchronize()
        }

        // transition complete! run flush for next priority defers!
        flush()
      }, `next-frame`)
    }
  }, [getIsMounted, synchronize])

  const deferHandle = React.useMemo<DeferHandle>(() => {
    return {
      getDeferState,
      getPriority,
      triggerUpdate,
    }
  }, [getDeferState, triggerUpdate, getPriority])

  // synchronously register
  React.useMemo(() => {
    if (!isSSR) {
      registerDeferHandle(deferHandle)
    }
  }, [deferHandle, isSSR])

  React.useEffect(() => {
    return () => {
      // unregister
      removeDeferHandle(deferHandle)
      flush()
    }
  }, [deferHandle])

  /*
    On value change, trigger flush!
  */
  React.useEffect(() => {
    if (!isSSR) {
      hookRefreshesWith(value)
      flush()
    }
  }, [value, isSSR])

  return valueBox.current
}
