// used both in prefetch context and webpack
import deploymentConfig from 'values/deployment.json'
// import { stringify } from './lib/json-stable-stringify'

import type {
  ResultHandleType,
  RequestBoxFetchArgs,
  RequestBoxFetchResult,
  RequestBoxFetchError,
  RequestBox,
  BatchOptions,
} from './types'

const { apiServerHost } = deploymentConfig

const apiServerOrigin = `https://${apiServerHost}`

declare global {
  interface Window {
    logRequestBox?: boolean
    onAuthChange: (uid?: string, token?: string) => void
    // requestBoxListeners?: Record<string, Array<(value: any) => void>>
    requestBox?: RequestBox
  }
}

const requestBoxLog = (e: string) => {
  if (typeof window !== `undefined`) {
    if (window?.logRequestBox || window?.localStorage?.logRequestBox) {
      console.log(e)
    }
  }
}

/* eslint-disable no-bitwise */
// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript/52171480#52171480
/*
const cyrb53 = (str: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed
  let h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < str.length; i += 1) {
    ch = str.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909)
  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}
*/
/* eslint-enable no-bitwise */

const fetchWrapper = async <T>(
  url: URL | string,
  fetchOptions: RequestBoxFetchArgs,
  batchOptions?: BatchOptions<T>,
): Promise<RequestBoxFetchResult<T>> => {
  let status: number
  let resHeaders: Record<string, string>
  let body: T
  // let bodyHash: number | null = null
  let processedContentType: ResultHandleType | null = null

  if (batchOptions) {
    // Currently headers not supported for batch!
    // resHeaders per batch requets :)
    ;({ status, body, resHeaders } = await batchOptions.fetch())
    if (!resHeaders) {
      resHeaders = {}
    }
  } else {
    const res = await fetch(url, fetchOptions)
    const resultHandleType = fetchOptions.resultHandleType
    ;({ status } = res)

    resHeaders = {}
    res.headers.forEach((v, k) => {
      resHeaders[k] = v
    })

    /*  
        result : {
          status : Response.status
          headers : Record<string, string>
          processedContentType : ProcessedContentType = ResultHandleType
          body : <T>
        }
      */
    const contentType = resHeaders[`content-type`]
    if (contentType) {
      if (contentType.indexOf(`application/json`) >= 0) {
        body = (await res.json()) as T
        processedContentType = `json`
      } else if (resultHandleType === `blob`) {
        body = (await res.blob()) as unknown as T
        processedContentType = `blob`
      } else {
        body = (await res.text()) as unknown as T
        processedContentType = `text`
      }
    } else {
      // error!
      throw new Error(`content type undefined! returning empty body!`)
    }
  }
  /*
  if (processedContentType === `json`) {
    bodyHash = cyrb53(stringify(body))
  }
  */

  const result: RequestBoxFetchResult<T> = {
    status,
    headers: resHeaders,
    body,
    // bodyHash,
    processedContentType,
  }

  // normalized from batch-fetch
  // uid not expected to change on authorization update
  const authorizationHeader = resHeaders[`x-authorization`]
  if (authorizationHeader) {
    if (authorizationHeader) {
      // need to update authorization!
      try {
        const [uid, token] = authorizationHeader.split(` `)
        if (!uid || !token) {
          throw new Error(`uid or token not returned : ${uid}, ${token}`)
        }

        localStorage.setItem(`cachedUid`, uid)
        localStorage.setItem(`cachedToken`, token)
        window.onAuthChange?.(uid, token)
      } catch (e) {
        console.error(e)
        console.error(`authorization header parse fail`)
        window.localStorage.clear()
        window.location.reload()
      }
    }
  }

  if (status >= 400) {
    const e = new Error(
      `Request box error : \nurl: ${url}\nstatus: ${status}\nresult: ${JSON.stringify(
        result,
        null,
        2,
      )}`,
    )
    ;(e as RequestBoxFetchError).result = result
    throw e
  }

  return result
}

/*
Dropping listeners, since we're already sharing & caching requests. no need to replace existing references. That will hurt experience.

const callListener = <T>(key: string, value: RequestBoxFetchResult<T>) => {
  if (window.requestBoxListeners) {
    const listeners = window.requestBoxListeners[key]
    if (listeners) {
      // Type check is weak this point!
      listeners.forEach((callback) => {
        try {
          callback(value)
        } catch (e) {
          console.error(`error on requestBox call listener key : ${key}`)
          console.error(e)
        }
      })
    }
  }
}

export const registerListener = <T>(
  key: string,
  callback: (value: T) => void,
) => {
  if (!window.requestBoxListeners) {
    window.requestBoxListeners = {}
  }
  const listenersDict = window.requestBoxListeners
  if (!listenersDict[key]) {
    listenersDict[key] = []
  }
  const listeners = listenersDict[key]

  if (listeners.indexOf(callback) === -1) {
    listeners.push(callback)
  }

  return () => {
    const index = listeners.indexOf(callback)
    if (index !== -1) {
      listeners.splice(index, 1)
    }
  }
}
*/

export const requestBoxFetch = async <T>(
  url: URL | string,
  fetchOptions: RequestBoxFetchArgs,
  batchOptions?: BatchOptions<T>,
): Promise<RequestBoxFetchResult<T>> => {
  const method = fetchOptions.method
  const cacheReadDuration = fetchOptions.cacheReadDuration
  const href = typeof url === `string` ? url : url.href
  const uid = fetchOptions.uid
  const authorization = fetchOptions.headers?.get(`Authorization`)

  const requestKey = href.replace(apiServerOrigin, ``)
  const requestedAt = Date.now()
  const sessionUpdatedAt = window.sessionUpdatedAt || 0
  const fetchRouteUpdatedAt = window.fetchRouteUpdatedAt || 0
  const forceRevalidate = fetchOptions.forceRevalidate

  const forceStrongConsistencyForAllRequests =
    window.forceStrongConsistencyForAllRequestsRouteId &&
    window.forceStrongConsistencyForAllRequestsRouteId === fetchRouteUpdatedAt
  const cacheReadConsistency = forceStrongConsistencyForAllRequests
    ? `strong`
    : fetchOptions.cacheReadConsistency || `shared-parallel`

  if (cacheReadConsistency === `duration` && !cacheReadDuration) {
    throw new Error(`must set duration if consistency is duration`)
  }

  if (
    method?.toUpperCase() !== `GET` ||
    href.indexOf(apiServerOrigin) !== 0 ||
    cacheReadConsistency === `strong` ||
    (authorization && !uid)
  ) {
    batchOptions?.resolve()

    // no batch
    // no cache
    return fetchWrapper(url, fetchOptions)
  }

  const authorizationKey = uid || `no-auth`

  let requestBox: RequestBox
  if (window.requestBox) {
    requestBox = window.requestBox
  } else {
    requestBox = {}
    window.requestBox = requestBox
  }

  if (!requestBox[authorizationKey]) {
    requestBox[authorizationKey] = {
      requestLocks: {},
      requestListeners: {},
      requestCache: {},
    }
  }

  // uid not expected to change on authorization update
  const { requestLocks, requestListeners, requestCache } =
    requestBox[authorizationKey]

  Object.keys(requestBox).forEach((authKey) => {
    if (authorizationKey !== authKey) {
      const box = requestBox[authKey]
      if (box) {
        // try expire!
        const requestLocks = requestBox[authKey].requestLocks
        let locked = false
        Object.values(requestLocks).forEach((e) => {
          locked = !!(locked || e)
        })
        if (!locked) {
          requestBoxLog(`delete request box auth key : ${authKey}`)
          delete requestBox[authKey]
        }
      }
    }
  })

  const currentTime = Date.now()
  Object.keys(requestCache).forEach((requestKey) => {
    const { resultAt } = requestCache[requestKey]
    if (currentTime > resultAt + 1000 * 60 * 3) {
      delete requestCache[requestKey]
    }
  })

  return new Promise<RequestBoxFetchResult<T>>((_resolve, _reject) => {
    let done = false
    const resolve = (res: RequestBoxFetchResult<T>) => {
      if (done) {
        return
      }
      done = true
      batchOptions?.resolve()
      _resolve(res)
    }

    const reject = (e: any) => {
      if (done) {
        return
      }

      done = true
      batchOptions?.resolve()
      _reject(e)
    }

    ;(async () => {
      const cacheObject = requestCache[requestKey]

      if (
        cacheObject &&
        [`session`, `route`, `duration`].indexOf(cacheReadConsistency) !== -1
      ) {
        const {
          result,
          sessionUpdatedAt: cacheSessionUpdatedAt,
          fetchRouteUpdatedAt: cacheFetchRouteUpdatedAt,
          resultAt,
        } = cacheObject

        // on cache hit, resolve but proceed with fetch
        if (cacheReadConsistency === `session`) {
          if (sessionUpdatedAt === cacheSessionUpdatedAt) {
            requestBoxLog(
              `HIT      ${requestKey} : ${cacheReadConsistency} : fetchRouteUpdatedAt - ${fetchRouteUpdatedAt}, requestedAt - ${requestedAt}, cacheSessionUpdatedAt - ${cacheSessionUpdatedAt}`,
            )
            resolve(result)

            if (fetchRouteUpdatedAt === cacheFetchRouteUpdatedAt) {
              // if same route, don't update
              if (!forceRevalidate) {
                return
              }
            }
            // session requests need to populate newer
          }
        } else if (cacheReadConsistency === `route`) {
          if (fetchRouteUpdatedAt === cacheFetchRouteUpdatedAt) {
            requestBoxLog(
              `HIT      ${requestKey} : ${cacheReadConsistency} : fetchRouteUpdatedAt - ${fetchRouteUpdatedAt}, requestedAt - ${requestedAt}, cacheFetchRouteUpdatedAt - ${cacheFetchRouteUpdatedAt}`,
            )
            resolve(result)
            // route requests don't repopulate, since routing will flush it.
            if (!forceRevalidate) {
              return
            }
          }
        } else if (cacheReadConsistency === `duration` && cacheReadDuration) {
          if (resultAt + cacheReadDuration >= Date.now()) {
            requestBoxLog(
              `HIT      ${requestKey} : ${cacheReadConsistency} : fetchRouteUpdatedAt - ${fetchRouteUpdatedAt}, requestedAt - ${requestedAt}`,
            )
            resolve(result)
            // duration requests also don't repopulate.
            if (!forceRevalidate) {
              return
            }
          }
        }
      }

      let isLockOwner = false
      if (requestLocks[requestKey]) {
        batchOptions?.resolve() // shared request will resolve from the one visiting the server :)

        if (!done) {
          requestBoxLog(
            `SHARE    ${requestKey} : ${cacheReadConsistency} : fetchRouteUpdatedAt - ${fetchRouteUpdatedAt}, requestedAt - ${requestedAt}`,
          )
        }
        if (requestListeners[requestKey]) {
          requestListeners[requestKey].push([resolve, reject])
        } else {
          requestListeners[requestKey] = [[resolve, reject]]
        }
        return
      }

      if (done) {
        requestBoxLog(
          `POPULATE ${requestKey} : ${cacheReadConsistency} : fetchRouteUpdatedAt - ${fetchRouteUpdatedAt}, requestedAt - ${requestedAt}`,
        )
      } else {
        requestBoxLog(
          `MISS     ${requestKey} : ${cacheReadConsistency} : fetchRouteUpdatedAt - ${fetchRouteUpdatedAt}, requestedAt - ${requestedAt}, sessionUpdatedAt - ${sessionUpdatedAt}`,
        )
      }

      try {
        isLockOwner = true
        requestLocks[requestKey] = true
        // session and route prior request
        let result: RequestBoxFetchResult<T>
        try {
          result = await fetchWrapper(url, fetchOptions, batchOptions)
        } catch (e) {
          // Authorization was provided but failed.
          if (
            (e as RequestBoxFetchError)?.result?.status === 401 &&
            fetchOptions.uid &&
            fetchOptions.headers?.get(`Authorization`)
          ) {
            // provided but failed.
            // auth expired and failed to automatic refresh.
            // clear localStorage and reload.

            // logged out of inactivity. alert and refresh
            console.log(`Uid removed. reloading.`)
            window.localStorage.clear()
            window.location.reload()
            return
          }
          // normal 401
          throw e
        }
        const resultHandleType = fetchOptions.resultHandleType
        const noCache = fetchOptions.noCache

        if (resultHandleType !== `blob` && !noCache) {
          /*
            If prevCache result is same, don't update the reference and reuse the result.
            Lets drop the bodyHash feature :(
            Cache result or shared-parallel uses the same reference anyway.
            Once we're here to store to the cache, we're invalidating it.
            const prevCache = requestCache[requestKey]
            if (prevCache) {
              const prevCacheResult = prevCache.result
              
              if (
                prevCacheResult &&
                prevCacheResult.bodyHash === result.bodyHash
                ) {
                  result = prevCacheResult
                }
              }
          */

          requestBoxLog(
            `STORE    ${requestKey} : ${cacheReadConsistency} : fetchRouteUpdatedAt - ${fetchRouteUpdatedAt}, requestedAt - ${requestedAt}, sessionUpdatedAt - ${sessionUpdatedAt}`,
          )
          // don't cache if blob
          requestCache[requestKey] = {
            result,
            requestedAt,
            resultAt: Date.now(),
            sessionUpdatedAt,
            fetchRouteUpdatedAt,
          }
        }

        if (requestListeners[requestKey]) {
          requestListeners[requestKey].forEach((handle) => {
            const resolve = handle[0]
            try {
              resolve(result)
            } catch (e) {
              console.error(`release lock error!`)
              console.error(e)
            }
          })
          requestListeners[requestKey] = []
        }
        requestLocks[requestKey] = false
        resolve(result)

        // callListener(requestKey, result)
      } catch (e) {
        if (isLockOwner && requestLocks[requestKey]) {
          if (requestListeners[requestKey]) {
            requestListeners[requestKey].forEach((handle) => {
              const reject = handle[1]
              try {
                reject(e)
              } catch (e) {
                console.error(`release lock error!`)
                console.error(e)
              }
            })
            requestListeners[requestKey] = []
          }
          requestLocks[requestKey] = false
        }
      }
    })().catch((e) => reject(e))
  })
}
