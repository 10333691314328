import React from 'react'
import { Link } from 'components/link'
import { useForceUpdate } from 'hooks/use-force-update'
import { NotificationExistsOverlay } from 'components/widgets/notification-exists-overlay'
import { useHasFCP } from 'hooks/use-has-fcp'
import { ProfileImage } from 'components/widgets/profile-image'
import { useUid } from 'hooks/use-uid'
import { usePathname } from 'hooks/use-location'
import { useData } from 'hooks/use-data'
import { select } from 'util/select'
import { useKeyboardPopped } from 'hooks/use-keyboard-popped'
import { useOverlayPopped } from 'hooks/use-overlay'

import { useViewportWidth } from 'hooks/use-viewport-dimension'
import { useNotificationExists } from 'hooks/use-notification-exists'
import { breakpoints } from 'src/theme'

import HomeIcon from 'assets/images/svgs/home-new.svg'
import StoryIcon from 'assets/images/svgs/story-new.svg'
import CreateIcon from 'assets/images/svgs/create-new.svg'
import NotificationIcon from 'assets/images/svgs/notification-new.svg'

import MeOnIcon from 'assets/images/svgs/me-on.svg'
import MeOffIcon from 'assets/images/svgs/me-off.svg'

import * as styles from './style.module.less'

const PaymentOverlay = React.lazy(
  () => import(`containers/payment-overlay/default`),
)
const BottomOverlay = React.lazy(
  () => import(`containers/bottom-overlay/default`),
)

type IconComponent = (props: { className?: string }) => JSX.Element
interface NavigateButtonProps {
  to: string
  label: string
  displayLabel?: boolean
  children?: React.ReactNode
  className?: string
  id?: string
  OnIcon: IconComponent
  OffIcon: IconComponent
  rel?: string
  isAuthRequired?: boolean
}

export const NavigationBarContainerPaddingDummy = () => {
  return <div className={styles.navigationBarContainerPaddingDummy} />
}

export const NavigateButton = ({
  OnIcon,
  OffIcon,
  label,
  to,
  children,
  className,
  displayLabel = true,
  id,
  rel,
  isAuthRequired = false,
}: NavigateButtonProps) => {
  const uid = useUid()
  const pathname = usePathname()

  let iconElement
  if (pathname === to.split(`?`)[0]) {
    iconElement = (
      <>
        <div className={styles.iconContainer}>
          <OnIcon
            className={`${styles.navigationBarIcon} ${styles.match} ${styles.on}`}
          />
        </div>
        {displayLabel ? (
          <div className={`${styles.navigationBarLabel} ${styles.match}`}>
            {label}
          </div>
        ) : null}
      </>
    )
  } else {
    iconElement = (
      <>
        <div className={styles.iconContainer}>
          <OffIcon className={`${styles.navigationBarIcon} off`} />
        </div>
        {displayLabel ? (
          <div className={styles.navigationBarLabel}>{label}</div>
        ) : null}
      </>
    )
  }

  const isInactive = isAuthRequired && !uid

  return (
    <Link
      rel={rel}
      to={to}
      id={id}
      className={`${styles.navigateButton} ${className} ${
        isInactive ? styles.inactive : ``
      }`}
      aria-label={`${label} 메뉴로 이동`}
    >
      {iconElement}
      {children}
    </Link>
  )
}

const navBarHiddenPaths: Record<string, boolean> = {
  '/label-editor': true,
}
let _onNavBarHiddenChange: (() => void) | null = null
export const useHideNavigationBar = (hidden: boolean) => {
  const pathname = usePathname()

  React.useEffect(() => {
    // why receive hidden?
    // same page might show navBar only if keyboard open.
    if (hidden) {
      navBarHiddenPaths[pathname] = true
      _onNavBarHiddenChange?.()
      return () => {
        navBarHiddenPaths[pathname] = false
        _onNavBarHiddenChange?.()
      }
    }
  }, [pathname, hidden])
}

// TODO hmm>??
export const NavigationBar = () => {
  const overlayPopped = useOverlayPopped()
  const keyboardPopped = useKeyboardPopped()
  const pathname = usePathname()

  const hasFCP = useHasFCP()
  const forceUpdate = useForceUpdate()

  const uid = useUid()
  const [userData] = useData(uid ? `/users/${uid}` : null, {
    cacheReadConsistency: `session`,
  })
  const profileImage = select(userData, `public.profile.profileImage`)

  const hideNavBar =
    !!navBarHiddenPaths[pathname] || pathname.indexOf(`/sales`) !== -1

  // set hidden
  const onNavBarHiddenChange = React.useCallback(() => {
    forceUpdate()
  }, [forceUpdate])

  React.useEffect(() => {
    _onNavBarHiddenChange = onNavBarHiddenChange
    return () => {
      _onNavBarHiddenChange = null
    }
  }, [onNavBarHiddenChange])

  const overlays = React.useMemo(() => {
    if (!hasFCP) {
      return null
    }
    return (
      <React.Suspense fallback={null}>
        <BottomOverlay hide={hideNavBar} />
        <PaymentOverlay hide={hideNavBar} />
      </React.Suspense>
    )
  }, [hideNavBar, hasFCP])

  const {
    activity: activityExists,
    commerce: commerceExists,
    reward: rewardExists,
  } = useNotificationExists()

  let activityType = `activity`
  if (activityExists) {
    activityType = `activity`
  } else if (commerceExists) {
    activityType = `commerce`
  } else if (rewardExists) {
    activityType = `reward`
  }

  const viewportWidth = useViewportWidth()

  if (viewportWidth >= breakpoints.smallTablet) {
    return overlays
  }

  return (
    <>
      <NavigationBarContainerPaddingDummy />
      <footer
        className={`${styles.rootContainer} ${
          hideNavBar ? styles.navigationBarHidden : ``
        } ${keyboardPopped || overlayPopped ? styles.keyboardUp : ``}`}
      >
        <nav>
          <NavigateButton
            OnIcon={HomeIcon}
            OffIcon={HomeIcon}
            to="/"
            label="홈"
            className={styles.homeIcon}
          />
          <NavigateButton
            OnIcon={StoryIcon}
            OffIcon={StoryIcon}
            to="/story"
            label="스토리"
            className={styles.storyIcon}
          />
          <NavigateButton
            OnIcon={CreateIcon}
            OffIcon={CreateIcon}
            to="/p/create"
            label="공유하기"
            rel="nofollow"
            isAuthRequired
            className={styles.createIcon}
            displayLabel={false}
          />
          <NavigateButton
            OnIcon={NotificationIcon}
            OffIcon={NotificationIcon}
            to={`/activities?type=${activityType}`}
            label="활동"
            rel="nofollow"
            isAuthRequired
            className={styles.notificationIcon}
          >
            <NotificationExistsOverlay top={8} right={14} />
          </NavigateButton>
          {uid ? (
            <Link
              className={styles.navigateButton}
              rel="nofollow"
              to="/me"
              id="nav-button-me"
              aria-label="내 루트 메뉴로 이동"
            >
              <div className={styles.profileImageContainer}>
                <ProfileImage
                  src={profileImage}
                  size={22}
                  className={`${styles.profileImage} ${
                    pathname === `/me` ? styles.match : ``
                  }`}
                  alt="내 프로필 사진"
                />
              </div>
              <div
                className={`${styles.navigationBarLabel} ${
                  pathname === `/me` ? styles.match : ``
                }`}
              >
                내 루트
              </div>
            </Link>
          ) : (
            <NavigateButton
              OnIcon={MeOnIcon}
              OffIcon={MeOffIcon}
              to="/me"
              label="내 루트"
              rel="nofollow"
              isAuthRequired
              className={styles.profileIcon}
            />
          )}
        </nav>
      </footer>
      {overlays}
    </>
  )
}
