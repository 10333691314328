import { createUuid } from 'util/uuid'

// normalized from get-session-storage-id
export const getLocalStorageId = () => {
  /*
        check for sesionStorageId in sessionStorage.
        if exists, return. 
        else, generate one and return. 
    */

  if (typeof window !== `undefined`) {
    const currentLocalStorageId = window.localStorage.getItem(`localStorageId`)
    if (currentLocalStorageId) {
      return currentLocalStorageId
    }

    const newLocalStorageId = createUuid(20)
    window.localStorage.setItem(`localStorageId`, newLocalStorageId)
    return newLocalStorageId
  }
  return `null`
}
