import React from 'react'
import { useUid } from 'hooks/use-uid'
import { useIsSSR } from 'hooks/use-is-ssr'
import type { AuthType } from 'components/auth'
import { useIsFullSSR } from 'hooks/use-is-full-ssr'
import { SuspenseMessage } from 'components/widgets/suspense-message'
import { AuthConcierge } from 'components/auth/concierge'
import { navigateBack } from 'hooks/use-location'
import { BackButton } from 'components/header'
import { useDelayedSwitch } from 'hooks/use-delayed-switch'
import { useOnPopState } from 'hooks/use-on-pop-state'
import { useTouchGestures } from 'util/gesture'

import * as styles from './style.module.less'

const AuthBarrierAuth = React.lazy(() => import(`./auth-default`))

export type SetAuthBarrierHandle = (
  message: string,
  closable: boolean,
  open: boolean,
  renderBehindHeaderAndNavBar?: boolean,
) => void

interface AuthBarrierProps {
  message: string
  isClosable: boolean // if !closable, provide a backdrop filter. if closable, provide a close button
  isOpen: boolean
  close: () => void
  renderBehindHeaderAndNavBar: boolean
}

export const SetAuthBarrierHandleContext =
  React.createContext<SetAuthBarrierHandle>(() => {
    /* no-op */
    console.error(`Please use auth barrier handle within provider`)
  })

export const AuthBarrierPropsContext = React.createContext<AuthBarrierProps>({
  message: ``,
  isClosable: false, // if !closable, provide a backdrop filter. if closable, provide a close button
  isOpen: false,
  close: () => {
    /* no-op */
  },
  renderBehindHeaderAndNavBar: false,
})

const AuthBarrier = ({
  message,
  isClosable,
  isOpen,
  close,
  renderBehindHeaderAndNavBar,
}: AuthBarrierProps) => {
  const [type, setType] = React.useState<AuthType>(`unset`)
  const [isSeller, setIsSeller] = React.useState(false)
  const [barrierVisible, setBarrierVisible] = React.useState(false)
  const [displayBarrierElement, displayBarrierStyle] =
    useDelayedSwitch(barrierVisible)

  React.useEffect(() => {
    if (!isOpen) {
      setType(`unset`)
      setBarrierVisible(false)
    } else {
      setBarrierVisible(true)
    }
  }, [isOpen])

  const onPopState = React.useCallback(() => {
    if (isOpen && isClosable) {
      close()
      return true
    }
    return false
  }, [isOpen, isClosable, close])

  useOnPopState(isOpen && isClosable, onPopState)

  const fallback = (
    <SuspenseMessage message="로그인 화면을 불러오고 있습니다." />
  )

  const concierge = React.useMemo(() => {
    return <AuthConcierge type={type} isSeller={isSeller} />
  }, [type, isSeller])

  const onBindCloseTouchStart = React.useCallback(
    ({
      target,
      currentTarget,
    }: {
      target: HTMLElement
      currentTarget: HTMLDivElement
    }) => {
      if (target === currentTarget && isClosable) {
        close()
      }
    },
    [close, isClosable],
  )

  const bindClose = useTouchGestures<HTMLDivElement>({
    onStart: onBindCloseTouchStart,
  })

  if (!displayBarrierElement) {
    return null
  }

  /* eslint-disable react/jsx-props-no-spreading */
  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

  return (
    <>
      <div
        className={`${styles.backgroundContainer} ${
          displayBarrierStyle ? styles.open : styles.closed
        } 
        ${renderBehindHeaderAndNavBar ? `` : styles.renderOverHeaderAndNavbar}`}
      />
      <div
        className={`${styles.rootContainer} ${
          displayBarrierStyle ? styles.open : styles.closed
        }  
        ${renderBehindHeaderAndNavBar ? `` : styles.renderOverHeaderAndNavbar} 
        ${barrierVisible ? `` : `dont-intercept-close`}
      `}
        /*     ref={disableScrollRef} */
        {...bindClose(`auth-barrier-root-container`)}
      >
        {!renderBehindHeaderAndNavBar ? (
          isClosable ? null : (
            <div className={styles.backButtonContainer}>
              <BackButton />
            </div>
          )
        ) : null}
        <div
          className={`${styles.authBarrierScrollWrapper} ${
            barrierVisible ? `` : styles.dontInterceptClose
          }`}
          {...bindClose(`auth-barrier-scroll-wrapper`)}
        >
          <div className={`${styles.authBarrierMessageContainer} t`}>
            {message}
          </div>
          <div className={styles.authBarrierPanel}>
            <div>{concierge}</div>
            <div className={styles.authBarrierAuthContainer}>
              <React.Suspense fallback={fallback}>
                <AuthBarrierAuth
                  onSuccess={close}
                  type={type}
                  setType={setType}
                  isSeller={isSeller}
                  setIsSeller={setIsSeller}
                />
              </React.Suspense>
            </div>
            {renderBehindHeaderAndNavBar ? null : (
              <button
                type="button"
                className={`${styles.closeButtonText} t`}
                onClick={() => {
                  if (isClosable) {
                    close()
                  } else {
                    navigateBack({ defaultParentUrl: `/` })
                  }
                }}
                aria-label={isClosable ? `로그인 창 닫기` : `뒤로가기`}
              >
                괜찮습니다, 다음에 할게요
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const AuthBarrierProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const isSSR = useIsSSR()
  const isFullSSR = useIsFullSSR()
  const uid = useUid()
  const [authBarrierMessage, setAuthBarrierMessage] = React.useState(``)
  const [isAuthBarrierClosable, setIsAuthBarrierClosable] = React.useState(true)
  const [_isAuthBarrierOpen, setIsAuthBarrierOpen] = React.useState(false)
  const [renderBehindHeaderAndNavBar, setRenderBehindHeaderAndNavBar] =
    React.useState(false)

  const setAuthBarrier = React.useCallback(
    (
      message: string,
      closable: boolean,
      open: boolean,
      renderBehindHeaderAndNavBar = false,
    ) => {
      setAuthBarrierMessage(message)
      setIsAuthBarrierClosable(closable)
      setIsAuthBarrierOpen(open)
      setRenderBehindHeaderAndNavBar(renderBehindHeaderAndNavBar)
    },
    [],
  )

  const closeAuthBarrier = React.useCallback(() => {
    setIsAuthBarrierOpen(false)
  }, [])

  const isAuthBarrierOpen =
    uid || isSSR || isFullSSR ? false : _isAuthBarrierOpen

  const authBarrierProps = React.useMemo(() => {
    return {
      message: authBarrierMessage,
      isClosable: isAuthBarrierClosable,
      isOpen: isAuthBarrierOpen,
      close: closeAuthBarrier,
      renderBehindHeaderAndNavBar,
    }
  }, [
    authBarrierMessage,
    isAuthBarrierClosable,
    isAuthBarrierOpen,
    closeAuthBarrier,
    renderBehindHeaderAndNavBar,
  ])

  return (
    <AuthBarrierPropsContext.Provider value={authBarrierProps}>
      <SetAuthBarrierHandleContext.Provider value={setAuthBarrier}>
        {children}
      </SetAuthBarrierHandleContext.Provider>
    </AuthBarrierPropsContext.Provider>
  )
}

export const AuthBarrierElement = () => {
  const isSSR = useIsSSR()
  const isFullSSR = useIsFullSSR()
  const uid = useUid()

  const authBarrierProps = React.useContext(AuthBarrierPropsContext)
  return uid || isSSR || isFullSSR ? null : (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <AuthBarrier {...authBarrierProps} />
  )
}

// open and close
export const useAuthBarrier = () => {
  const displayAuthBarrierHandle = React.useContext(SetAuthBarrierHandleContext)
  return displayAuthBarrierHandle
}
