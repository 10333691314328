type NavigateDirection = `forward` | `backward`
// normalized from prefetcher.raw.ts
type PrefetchRouteDataOptions = {
  forceStrongConsistencyForAllRequestsInThisRoute?: boolean
}
const defaultOptions: PrefetchRouteDataOptions = {
  forceStrongConsistencyForAllRequestsInThisRoute: false,
}

export const prefetchRouteData = async (
  to?: string,
  navigateDirection?: NavigateDirection,
  options: PrefetchRouteDataOptions = defaultOptions,
) => {
  // prefetch don't throw!
  try {
    if (window.prefetchRouteData) {
      await window.prefetchRouteData(to, navigateDirection, options)
    }
  } catch (e) {
    console.error(e)
  }
}
