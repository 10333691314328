import React from 'react'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { delay } from 'util/delay'
import { useAsyncEffect } from 'hooks/use-async-effect'

export const useThrottledRealTimeSearchValue = (input: string) => {
  const getIsMounted = useGetIsMounted()
  const querySequenceBox = React.useRef(0)
  const latestRequestAtBox = React.useRef(0)

  const [inputToSubmit, setInputToSubmit] = React.useState(``)
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useAsyncEffect(async () => {
    /*
      
    */
    const querySequence = querySequenceBox.current + 1
    querySequenceBox.current = querySequence
    let sendRequest = false
    if (latestRequestAtBox.current + 500 < Date.now()) {
      // proceed
      latestRequestAtBox.current = Date.now()
      sendRequest = true
    } else {
      // in cooldown.
      const diff = Date.now() - latestRequestAtBox.current
      await delay(diff + 16) // come after delay
      if (getIsMounted() && querySequenceBox.current === querySequence) {
        sendRequest = true
      }
    }

    if (sendRequest) {
      setInputToSubmit(input?.trim() || ``)
    }
  }, [input, getIsMounted])

  return inputToSubmit
}
