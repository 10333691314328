import { getState, getCurrentPath } from 'util/url'
import { navigate as _navigate } from 'gatsby'
import { prefetchRouteData } from 'src/services/prefetch'
import { triggerOnPreNavigate } from 'hooks/use-on-pre-navigate'
import { getLocation, useParsedLocation } from './provider'
import { usePathnameSearch } from './reducers/pathname-search'
import { usePathname } from './reducers/pathname'
import { useState } from './reducers/state'

export const getInnerNavigated = () => {
  if (typeof window !== `undefined`) {
    return !!getState().innerNavigated
  }
  return false
}

/*
  Prefetch required : get TO
*/
export const navigateBack = ({
  defaultParentUrl,
  forceStrongConsistencyForAllRequestsInThisRoute,
}: {
  defaultParentUrl?: string
  forceStrongConsistencyForAllRequestsInThisRoute?: boolean
}) => {
  triggerOnPreNavigate()
  if (!getInnerNavigated() && defaultParentUrl) {
    _navigate(defaultParentUrl)
  } else if (typeof window !== `undefined`) {
    const { prevPath } = getState()
    if (prevPath) {
      prefetchRouteData(prevPath, `backward`, {
        forceStrongConsistencyForAllRequestsInThisRoute,
      })
    }
    window.history.back()
  }
}

// might need state props
export const navigate = (
  to: string,
  {
    replace = false,
    forceStrongConsistencyForAllRequestsInThisRoute,
  }: {
    replace?: boolean
    forceStrongConsistencyForAllRequestsInThisRoute?: boolean
  } = {},
) => {
  console.log(`navigate to  : ${to}`)
  prefetchRouteData(to, `forward`, {
    forceStrongConsistencyForAllRequestsInThisRoute,
  })

  triggerOnPreNavigate()

  _navigate(to, {
    replace,
    state: { innerNavigated: true, prevPath: getCurrentPath() },
  })
}
export {
  getLocation,
  useParsedLocation,
  usePathnameSearch,
  usePathname,
  useState,
}
