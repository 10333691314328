import React from 'react'
import { useIsSSR } from 'hooks/use-is-ssr'

const FCPDetectClientComponent = React.lazy(
  () => import(`./lazy-component.default`),
)

const webpackLoadedAtMilliseconds = Date.now()
let webpackLoadMilliseconds = 0
if (typeof window !== `undefined`) {
  if (window.sessionUpdatedAt) {
    webpackLoadMilliseconds =
      webpackLoadedAtMilliseconds - window.sessionUpdatedAt
  }
}

const HasFCPContext = React.createContext(false)

export const HasFCPProvider = ({ children }: { children: React.ReactNode }) => {
  const isSSR = useIsSSR()
  const [hasFCP, setHasFCP] = React.useState(false)

  const onFCP = React.useCallback(() => {
    // fcp listeners ~~
    setHasFCP(true)

    const currentTimeMilliseconds = Date.now()
    const fcpDelayMilliseconds =
      currentTimeMilliseconds - webpackLoadedAtMilliseconds
    if (webpackLoadMilliseconds) {
      console.log(`html-webpack : ${webpackLoadMilliseconds}ms`)
    }

    console.log(`webpack-fcp  : ${fcpDelayMilliseconds}ms`)
  }, [])

  return (
    <HasFCPContext.Provider value={hasFCP}>
      {isSSR ? null : (
        <React.Suspense fallback={null}>
          <FCPDetectClientComponent onFCP={onFCP} />
          {` `}
        </React.Suspense>
      )}
      {children}
    </HasFCPContext.Provider>
  )
}

export const useHasFCP = () => {
  return React.useContext(HasFCPContext)
}
