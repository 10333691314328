import React from 'react'
import { useData } from 'hooks/use-data'
import {
  getAnonymousCartUserData,
  setAnonymousCart,
} from 'model/app/shopping/cart'
import { useAsyncCallback } from 'hooks/use-async-callback'
import { useUid } from 'hooks/use-uid'
import { useGetter } from 'hooks/use-getter'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { select } from 'util/select'

// CartDataProvider
// CartHandleProvider

// useCartUpdateHandle
//

interface CartHandle {
  updateCartData: (data: UserCartFragment | null) => void
  refreshCartData: () => Promise<void>
}

const CartDataContext = React.createContext<UserCartFragment | null>(null)
const CartUpdateHandleContext = React.createContext<CartHandle>({
  updateCartData: () => {
    /* no-op */
    console.error(`@updateCartData : cart handle not initialized!`)
  },
  refreshCartData: async () => {
    /* no-op */
    console.error(`@refreshCartData : cart handle not initialized!`)
  },
})

const IsSelectedDeliveryInfoJejuContext = React.createContext<boolean>(false)
const IsSelectedDeliveryInfoIslandAndMountainContext =
  React.createContext<boolean>(false)

const emptyCart: PopulatedCart = []
const emptyDeliveryInfo: PopulatedDeliveryInfo[] = []

export const CartProvider = ({ children }: { children: React.ReactNode }) => {
  const uid = useUid()
  const getIsMounted = useGetIsMounted()

  const [currentCartData, setCartData] =
    React.useState<UserCartFragment | null>(null)

  // keeping network cart
  const [networkCartData, , reloadNetworkCartData] = useData<UserCartFragment>(
    uid ? `/cart` : null,
    {
      forceRevalidate: true,
      reloadOnNavigate: true,
      cacheReadConsistency: `route`,
    },
  )

  // on networkCartData update, update!
  React.useEffect(() => {
    if (uid) {
      setCartData(networkCartData)
    }
  }, [networkCartData, uid])

  React.useEffect(() => {
    if (uid) {
      // if logged in, remove the anonymous cart.
      setAnonymousCart([])
    }
  }, [uid])

  // refresh anonymous cart
  const refreshAnonymousCartUserData = useAsyncCallback(async () => {
    const anonymousCartData = await getAnonymousCartUserData()
    if (!uid && getIsMounted()) {
      setCartData(anonymousCartData)
    }
  }, [uid, getIsMounted])

  // if not logged in, use anonymous cart data
  React.useEffect(() => {
    if (!uid) {
      refreshAnonymousCartUserData()
    }
  }, [refreshAnonymousCartUserData, uid])

  // update current cartdata regardless of network / anonymous state
  const updateCartData = React.useCallback((data: UserCartFragment | null) => {
    setCartData(data)
  }, [])

  // update current cartdata. update to anonymous if not logged in, update to network if logged in.
  const refreshCartData = useAsyncCallback(async () => {
    if (!uid) {
      await refreshAnonymousCartUserData()
      return
    }

    await reloadNetworkCartData()
  }, [uid, refreshAnonymousCartUserData, reloadNetworkCartData])

  const getRefreshCartData = useGetter(refreshCartData)
  const getUpdateCartData = useGetter(updateCartData)

  const cartUpdateHandle = React.useMemo(() => {
    return {
      refreshCartData: () => getRefreshCartData()(),
      updateCartData: (data: UserCartFragment | null) =>
        getUpdateCartData()(data),
    }
  }, [getRefreshCartData, getUpdateCartData])

  const [isJeju, isIslandAndMountain] = React.useMemo(() => {
    const [
      cart = emptyCart,
      deliveryInfo = emptyDeliveryInfo,
      selectedDeliveryInfo,
    ]: [PopulatedCart, PopulatedDeliveryInfo[], string] = select(
      currentCartData,
      [
        `private.shopping.cart`,
        `private.shopping.deliveryInfo`,
        `private.shopping.selectedDeliveryInfo`,
      ],
    )

    const currentDeliveryInfo = deliveryInfo.find(
      (e) => e.id === selectedDeliveryInfo,
    )

    if (!currentDeliveryInfo) return [false, false]
    if (!cart || cart.length === 0) return [false, false]

    const isJeju = !!currentDeliveryInfo?.isJeju
    const isIslandAndMountain = !!currentDeliveryInfo?.isIslandAndMountain

    return [isJeju, isIslandAndMountain]
  }, [currentCartData])

  return (
    <CartUpdateHandleContext.Provider value={cartUpdateHandle}>
      <CartDataContext.Provider value={currentCartData}>
        <IsSelectedDeliveryInfoJejuContext.Provider value={isJeju}>
          <IsSelectedDeliveryInfoIslandAndMountainContext.Provider
            value={isIslandAndMountain}
          >
            {children}
          </IsSelectedDeliveryInfoIslandAndMountainContext.Provider>
        </IsSelectedDeliveryInfoJejuContext.Provider>
      </CartDataContext.Provider>
    </CartUpdateHandleContext.Provider>
  )
}

export const useCartUpdateHandle = () => {
  return React.useContext(CartUpdateHandleContext)
}
// state is error or loaded,
// consumer
export const useCartData = () => {
  return React.useContext(CartDataContext)
}

export const useIsJejuOrIslandAndMountain = () => {
  const isJeju = React.useContext(IsSelectedDeliveryInfoJejuContext)
  const isIslandAndMountain = React.useContext(
    IsSelectedDeliveryInfoIslandAndMountainContext,
  )
  return React.useMemo(() => {
    return [isJeju, isIslandAndMountain]
  }, [isJeju, isIslandAndMountain])
}
