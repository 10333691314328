import { isImageExtension } from '../index.js'

/*
  thumbnail : 14, 18, 30, 32, 40, 42, 96
  post-card : 200, 300
  product-card : 120, 200
  new product-card : 130
  post-grid : 150, 240 -> scale down 320 / 3 = 100
  media-list : 608, 640


  50 100 200 300 400 500 600 700 800 900 1000 1080
     120 240 360  
     130 260     480         720
                         640
                                   850 950
*/

export const imageWidthBreakpoints = [
  50, 100, 120, 130, 200, 240, 260, 300, 360, 400, 480, 500, 600, 640, 700, 720,
  800, 850, 900, 950, 1000, 1080,
]

/*
export const compressedWords = [
  [`media`, `m`],
  [`public`, `p`],
  [`users`, `u`],
  [`alias`, `a`],
  [`contents`, `c`],
  [`profile`, `f`],
  [`images`, `i`],
]
*/

export const compressReservedWords = (rawPath) => {
  if (!rawPath) {
    return rawPath
  }

  let compressedPath = rawPath
  compressedPath = compressedPath.replace(/\/media\//g, `/m/`)
  compressedPath = compressedPath.replace(/\/public\//g, `/p/`)
  compressedPath = compressedPath.replace(/\/users\//g, `/u/`)
  compressedPath = compressedPath.replace(/\/alias\//g, `/a/`)
  compressedPath = compressedPath.replace(/\/contents\//g, `/c/`)
  compressedPath = compressedPath.replace(/\/profile\//g, `/f/`)
  compressedPath = compressedPath.replace(/\/images\//g, `/i/`)
  return compressedPath
}

export const decompressReservedWords = (compressedPath) => {
  if (!compressedPath) {
    return compressedPath
  }

  let decompressedPath = compressedPath
  decompressedPath = decompressedPath.replace(/\/m\//g, `/media/`)
  decompressedPath = decompressedPath.replace(/\/p\//g, `/public/`)
  decompressedPath = decompressedPath.replace(/\/u\//g, `/users/`)
  decompressedPath = decompressedPath.replace(/\/a\//g, `/alias/`)
  decompressedPath = decompressedPath.replace(/\/c\//g, `/contents/`)
  decompressedPath = decompressedPath.replace(/\/f\//g, `/profile/`)
  decompressedPath = decompressedPath.replace(/\/i\//g, `/images/`)
  return decompressedPath
}

export const getUrlFromSrc = (src, { width, mediaServerHost } = {}) => {
  if (!src) return ``

  if (src.indexOf(`http://`) !== -1 && src.indexOf(`https://`) !== -1) {
    return src
  }

  if (width) {
    let selectedWidth
    for (let i = 0; i < imageWidthBreakpoints.length; i += 1) {
      const breakpoint = imageWidthBreakpoints[i]
      if (breakpoint >= width) {
        selectedWidth = breakpoint
        break
      }
      if (i === imageWidthBreakpoints.lenth - 1) {
        selectedWidth = breakpoint
      }
    }
    return `https://${mediaServerHost.replace(/\/$/, ``)}/media/${src.replace(
      /^\//,
      ``,
    )}?width=${selectedWidth}`
  }

  return `https://${mediaServerHost.replace(/\/$/, ``)}/media/${src.replace(
    /^\//,
    ``,
  )}`
}

/*
  need to provide mediaServerHost in options
*/
export const getImageURLs = (paths, options) => {
  if (!paths) {
    return null
  }

  if (Array.isArray(paths)) {
    const res = []
    paths.forEach((path) => {
      if (isImageExtension(path)) {
        res.push(getUrlFromSrc(path, options))
      }
    })
    return res
  }

  const path = paths

  if (isImageExtension(path)) {
    return getUrlFromSrc(path, options)
  }
  return null
}
