import { createUuid } from 'util/uuid'

export const getSessionStorageId = () => {
  /*
        check for sesionStorageId in sessionStorage.
        if exists, return. 
        else, generate one and return. 
    */

  if (typeof window !== `undefined`) {
    const currentSessionStorageId =
      window.sessionStorage.getItem(`sessionStorageId`)
    if (currentSessionStorageId) {
      return currentSessionStorageId
    }

    const newSessionStorageId = createUuid(20)
    window.sessionStorage.setItem(`sessionStorageId`, newSessionStorageId)
    return newSessionStorageId
  }
  return `null`
}
