import React from 'react'
import { SuspenseMessage } from 'components/widgets/suspense-message'
import { useDisableOverscrollRefresh } from 'hooks/use-disable-overscroll-refresh'
import { useDelayedSwitch } from 'hooks/use-delayed-switch'
import { useBoxedCallback } from 'hooks/use-boxed-callback'
import { useOnPopState } from 'hooks/use-on-pop-state'
import { useStateRef } from 'hooks/use-state-ref'
import { useDragToClose } from 'hooks/use-drag-to-close'
import { parseQuery, replaceQuery } from 'util/url'
import CloseOverlayButtonIcon from 'assets/images/svgs/close-overlay-button.svg'
import { useTouchGestures } from 'util/gesture'
import { useQuery } from 'hooks/use-query'
import { DarkBackground } from 'components/dark-background'
import { hookRefreshesWith } from 'util/hook-refreshes-with'
import { useOnPreNavigate } from 'hooks/use-on-pre-navigate'

import Back from 'assets/images/svgs/back.svg'
import CloseIcon from 'assets/images/svgs/close.svg'

import * as darkBackgroundStyles from 'src/components/dark-background/style.module.less'
import * as styles from './style.module.less'

const Followers = React.lazy(
  () => import(`components/overlays/followers/default`),
)
const Followings = React.lazy(
  () => import(`components/overlays/followings/default`),
)

const Comments = React.lazy(
  () => import(`components/overlays/comments/default`),
)

const SetIsSeller = React.lazy(
  () => import(`components/overlays/set-is-seller/default`),
)

const Likes = React.lazy(() => import(`components/overlays/likes/default`))

const ProductInformation = React.lazy(
  () => import(`components/overlays/product-information/default`),
)

const ProductUnit = React.lazy(
  () => import(`components/overlays/product-unit/default`),
)

const Option = React.lazy(() => import(`components/overlays/option/default`))

const Category = React.lazy(
  () => import(`components/overlays/category/default`),
)

const FoodContent = React.lazy(
  () => import(`components/overlays/food-content/default`),
)

const Rate = React.lazy(() => import(`components/overlays/rate/default`))

const RewardCode = React.lazy(
  () => import(`components/overlays/reward-code/default`),
)

const DeleteAccount = React.lazy(
  () => import(`components/overlays/delete-account/default`),
)

const Report = React.lazy(() => import(`components/overlays/report/default`))

const ContentReward = React.lazy(
  () => import(`components/overlays/content-reward/default`),
)

const MarketingConsent = React.lazy(
  () => import(`components/overlays/marketing-consent/default`),
)

const OpenOverlayHandleContext = React.createContext<(slug: string) => void>(
  () => {
    /* no-op */
  },
)

const OverlayElementContext = React.createContext<React.ReactNode>(null)

const OverlayPoppedContext = React.createContext<boolean>(false)

const slugComponentMap = {
  '/u/followings': Followings,
  '/u/followers': Followers,
  '/comments': Comments,
  '/set-is-seller': SetIsSeller,
  '/likes': Likes,
  '/product-information': ProductInformation,
  '/product-unit': ProductUnit,
  '/option': Option,
  '/category': Category,
  '/food-content': FoodContent,
  '/rate': Rate,
  '/reward-code': RewardCode,
  '/delete-account': DeleteAccount,
  '/report': Report,
  '/content-reward': ContentReward,
  '/marketing-consent': MarketingConsent,
}

const slugComponentMapKeys = Object.keys(
  slugComponentMap,
) as (keyof typeof slugComponentMap)[]

export const getSlugMatchingComponent = (slug: string) => {
  /*
        /u/followings
        /u/followers
    */
  if (!slug) {
    return null
  }

  for (let i = 0; i < slugComponentMapKeys.length; i += 1) {
    const slugComponentMapKey = slugComponentMapKeys[i]
    if (slug.indexOf(slugComponentMapKey) === 0) {
      return slugComponentMap[slugComponentMapKey]
    }
  }

  return null
}

/*
  if small, render as overlay
  else, render center
*/

/*

  bindDragToClose: (key: string) => TouchGestureBindMethodContainer<HTMLElement>

  Overlay sticks to bottom, has open and close (just like paymentOverlay)
  if open, renders corresponding inner element inside
*/
const Overlay = ({
  isOverlayOpen,
  closeOverlay,
  openOverlay,
  slug,
  renderBackButton,
  pop,
  currentOverlayState,
  setOverlayState,
  overlayMaxHeightOverride,
}: {
  isOverlayOpen: boolean
  closeOverlay: () => void
  openOverlay: (slug: string) => void
  slug: string
  renderBackButton: boolean
  pop: () => void
  currentOverlayState: any
  setOverlayState: (value: any) => void
  overlayMaxHeightOverride?: number
}) => {
  const OverlayContentComponent = getSlugMatchingComponent(slug)

  /*
    TODO : not closable overlay (마케팅 수신동의)
    TODO : not closable by background click 
  */

  const [renderOverlayElement, renderOverlayStyle] = useDelayedSwitch(
    isOverlayOpen,
    { enableDelay: 32, disableDelay: 300 },
  )

  const onBindCloseTouchStart = React.useCallback(
    ({
      target,
      currentTarget,
    }: {
      target: HTMLElement
      currentTarget: HTMLDivElement
    }) => {
      if (target === currentTarget) {
        closeOverlay()
      }
    },
    [closeOverlay],
  )

  const overlayCardContainerRef = useStateRef<HTMLDivElement>(null)

  const bindClose = useTouchGestures<HTMLDivElement>({
    onStart: onBindCloseTouchStart,
  })

  const bindDragToClose = useDragToClose({
    translateTarget: overlayCardContainerRef.current,
    isOpen: isOverlayOpen,
    close: closeOverlay,
  })
  //

  const overlayVisible = !!OverlayContentComponent && !!renderOverlayStyle
  useDisableOverscrollRefresh(overlayVisible)

  if (!OverlayContentComponent) {
    return null
  }

  if (!renderOverlayElement) {
    return null
  }

  const disableBackgroundClose = slug?.indexOf(`disableBackgroundClose`) !== -1
  /* eslint-disable react/jsx-props-no-spreading, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
  return (
    <>
      <DarkBackground
        className={`${
          renderOverlayStyle
            ? darkBackgroundStyles.visible
            : darkBackgroundStyles.hidden
        } ${isOverlayOpen ? `` : darkBackgroundStyles.dontInterceptClose}`}
        {...(disableBackgroundClose ? {} : bindClose(`dark-background`))}
      />
      <div
        className={`${styles.rootContainer} ${
          renderOverlayStyle ? `` : styles.closed
        } ${isOverlayOpen ? `` : styles.dontInterceptClose} ${
          disableBackgroundClose ? styles.disableBackgroundClose : ``
        }`}
        {...(disableBackgroundClose ? {} : bindClose(`overlay-root`))}
      >
        <div
          className={styles.overlayCardContainer}
          ref={overlayCardContainerRef}
        >
          <button
            type="button"
            className={styles.closeOverlayButton}
            onClick={() => {
              closeOverlay()
            }}
            {...bindDragToClose(`close-overlay-button`)}
          >
            <CloseOverlayButtonIcon />
          </button>
          <React.Suspense
            fallback={
              <SuspenseMessage
                message="화면을 불러오고 있습니다"
                className={styles.overlaySuspenseMessage}
                style={{
                  height: `calc(${
                    overlayMaxHeightOverride || 70
                  } * var(--max-vh))`,
                }}
              />
            }
          >
            <OverlayContentComponent
              slug={slug}
              bindDragToClose={bindDragToClose}
              backButton={
                renderBackButton ? (
                  <button type="button" onClick={pop}>
                    <Back className={styles.backButtonIcon} />
                  </button>
                ) : null
              }
              closeButton={
                <button type="button" onClick={closeOverlay}>
                  <CloseIcon className={styles.closeButtonIcon} />
                </button>
              }
              currentOverlayState={currentOverlayState}
              setOverlayState={setOverlayState}
              overlayMaxHeightOverride={overlayMaxHeightOverride}
              closeOverlay={closeOverlay}
              openOverlay={openOverlay}
            />
          </React.Suspense>
        </div>
      </div>
    </>
  )
}

export const OverlayProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [currentOverlaySlug, setCurrentOverlaySlug] = React.useState(``)
  const [currentOverlayState, setCurrentOverlayState] = React.useState<any>(
    () => ({}),
  )
  const [isOverlayOpen, setIsOverlayOpen] = React.useState(false)

  // stack of overlay slugs :)
  const slugStackBox = React.useRef<string[]>([])
  const stateStackBox = React.useRef<any[]>([])

  const renderBackButton = slugStackBox.current.length > 1

  /*
    if max height set from any of the overlays, following overlay stack inherits the max height.
  */
  const overlayMaxHeightOverride = React.useMemo(() => {
    hookRefreshesWith(currentOverlaySlug)
    for (let i = 0; i < slugStackBox.current.length; i += 1) {
      const slug = slugStackBox.current[i]
      const { maxHeightOverride } = parseQuery(slug)
      try {
        if (maxHeightOverride) {
          const maxHeightOverrideNumber = Number(maxHeightOverride)
          if (maxHeightOverrideNumber) {
            return maxHeightOverrideNumber
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
    return -1
  }, [currentOverlaySlug])

  const invalidateCurrentSlug = React.useCallback(() => {
    if (slugStackBox.current.length === 0) {
      setIsOverlayOpen(false)
      // overlay slug stays as last
      // setCurrentOverlaySlug(``)
    } else {
      setIsOverlayOpen(true)
      setCurrentOverlaySlug(
        slugStackBox.current[slugStackBox.current.length - 1],
      )
      setCurrentOverlayState(
        stateStackBox.current[stateStackBox.current.length - 1],
      )
    }
  }, [])

  const queryOverlaySlugEncoded = useQuery(`overlaySlug`)
  const queryOverlaySlug = queryOverlaySlugEncoded
    ? decodeURIComponent(queryOverlaySlugEncoded)
    : ``

  React.useEffect(() => {
    if (queryOverlaySlug) {
      slugStackBox.current = [queryOverlaySlug]
      stateStackBox.current = [null]
      invalidateCurrentSlug()
      replaceQuery(`overlaySlug`) // remove query once consumed!
    }
  }, [queryOverlaySlug, invalidateCurrentSlug])

  /*
    can push, pop, 
  */
  const push = React.useCallback(
    (slug: string) => {
      slugStackBox.current.push(slug)
      stateStackBox.current.push(null)
      invalidateCurrentSlug()
    },
    [invalidateCurrentSlug],
  )

  const pop = React.useCallback(() => {
    if (slugStackBox.current.length !== 0) {
      slugStackBox.current.pop()
      stateStackBox.current.pop()
      invalidateCurrentSlug()
      return true
    }
    return false
  }, [invalidateCurrentSlug])

  const onPopState = React.useCallback(() => {
    // if stack exists, intercept
    if (pop()) {
      return true
    }
    return false
  }, [pop])

  const closeOverlay = React.useCallback(() => {
    // clear stack!
    slugStackBox.current = []
    stateStackBox.current = []
    invalidateCurrentSlug()
  }, [invalidateCurrentSlug])

  const setOverlayState = React.useCallback((value: any) => {
    stateStackBox.current[stateStackBox.current.length - 1] = value
  }, [])

  useOnPopState(isOverlayOpen, onPopState)

  const openOverlayHandle = useBoxedCallback(
    (slug: string) => {
      push(slug)
    },
    [push],
  )

  useOnPreNavigate(closeOverlay)

  const overlayElement = React.useMemo(() => {
    return (
      <Overlay
        isOverlayOpen={isOverlayOpen}
        closeOverlay={closeOverlay}
        openOverlay={openOverlayHandle}
        slug={currentOverlaySlug}
        renderBackButton={renderBackButton}
        pop={pop}
        currentOverlayState={currentOverlayState}
        setOverlayState={setOverlayState}
        overlayMaxHeightOverride={
          overlayMaxHeightOverride === -1 ? undefined : overlayMaxHeightOverride
        }
      />
    )
  }, [
    isOverlayOpen,
    closeOverlay,
    openOverlayHandle,
    currentOverlaySlug,
    renderBackButton,
    pop,
    currentOverlayState,
    setOverlayState,
    overlayMaxHeightOverride,
  ])

  return (
    <OpenOverlayHandleContext.Provider value={openOverlayHandle}>
      <OverlayElementContext.Provider value={overlayElement}>
        <OverlayPoppedContext.Provider value={isOverlayOpen}>
          {children}
        </OverlayPoppedContext.Provider>
      </OverlayElementContext.Provider>
    </OpenOverlayHandleContext.Provider>
  )
}

export const OverlayElement = () => {
  const element = React.useContext(OverlayElementContext)
  // eslint-disable-next-line
  return <>{element}</>
}

export const useOverlayPopped = () => {
  return React.useContext(OverlayPoppedContext)
}

export const useOpenOverlayHandle = () => {
  return React.useContext(OpenOverlayHandleContext)
}
