import _dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/ko'

const emptyObject = {}

_dayjs.extend(utc)
_dayjs.extend(timezone)
_dayjs.tz.setDefault(`Asia/Seoul`)
_dayjs.extend(weekOfYear)
_dayjs.locale(`ko`)

const dayjs = _dayjs.tz
export { dayjs }

export function parseMillis(timeMillis: number) {
  const seconds = Math.floor(timeMillis / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  return {
    seconds: seconds % 60,
    minutes: minutes % 60,
    hours: hours % 24,
    days,
  }
}

export const getDateObject = (
  secondsOrDateString: number | string,
): _dayjs.Dayjs => {
  let dateObject
  if (typeof secondsOrDateString === `number`) {
    dateObject = dayjs(secondsOrDateString * 1000)
  } else {
    dateObject = dayjs(secondsOrDateString)
  }
  return dateObject
}

export const getDateString = (secondsOrDateString: number | string): string => {
  const dateString = getDateObject(secondsOrDateString).format(`M월 D일`)

  return dateString
}

export const getTimeString = (secondsOrDateString: number | string): string => {
  const dateString = getDateObject(secondsOrDateString)
    .format(`M월 D일 A h:mm`)
    .replace(`AM`, `오전`)
    .replace(`PM`, `오후`)

  return dateString
}

export const getTimeString24h = (
  secondsOrDateString: number | string,
): string => {
  const dateString = getDateObject(secondsOrDateString).format(`M월 D일 H:mm`)

  return dateString
}

export const getTimeStringYears = (
  secondsOrDateString: number | string,
): string => {
  const dateString = getDateObject(secondsOrDateString)
    .format(`YYYY년 M월 D일 A h:mm`)
    .replace(`AM`, `오전`)
    .replace(`PM`, `오후`)

  return dateString
}

/**
 * seconds must not be future
 */
export const getTimeDiffString = (
  seconds: number,
  {
    includePrefix = true,
  }: {
    includePrefix?: boolean
  } = emptyObject,
) => {
  const diffSeconds = Math.floor(Date.now() / 1000) - seconds
  if (diffSeconds <= 0) {
    return `방금`
  }

  const diffMinutes = Math.floor(diffSeconds / 60)
  const diffHours = Math.floor(diffMinutes / 60)
  const diffDays = Math.floor(diffHours / 24)
  const diffWeeks = Math.floor(diffDays / 7)

  if (diffWeeks) {
    const currentYear = dayjs(new Date()).year()
    const targetDate = getDateObject(seconds)
    if (targetDate.year() === currentYear) {
      return targetDate.format(`M월 D일`)
    }
    return targetDate.format(`YY년 M월`)
  }

  if (diffDays) {
    if (diffDays === 1) {
      return `어제`
    }

    return `${diffDays}일${includePrefix ? ` 전` : ``}`
  }

  if (diffHours) {
    return `${diffHours}시간${includePrefix ? ` 전` : ``}`
  }

  if (diffMinutes) {
    return `${diffMinutes}분${includePrefix ? ` 전` : ``}`
  }

  return `방금`
  // return `${diffSeconds}초${includePrefix ? ` 전` : ``}`
}

export function getTimeLeftString(timeMillis: number) {
  const { seconds, minutes, hours, days } = parseMillis(timeMillis)
  if (days > 0) {
    switch (days) {
      case 1:
        return `하루`
      case 2:
        return `이틀`
      default:
        return `${days}일`
    }
  }
  if (hours > 0) {
    return `${hours}시간 ${minutes}분`
  }
  if (minutes > 0) {
    return `${minutes}분 ${seconds}초`
  }
  return `${seconds}초`
}

/*
export function formatDateTime(dateTime, isToday = false, timeOnly = false) {
  let formatted = timeOnly
    ? dayjs(dateTime).format(`A h시`)
    : dayjs(dateTime).format(`M월 D일 A h시`)
  const dayNames = [`일`, `월`, `화`, `수`, `목`, `금`, `토`]

  if (!isToday) {
    const curDate = dayjs(dayjs().format(`YYYYMMDD`))
    const targetDate = dayjs(dateTime.format(`YYYYMMDD`))
    let dayString
    const daysDiff = targetDate.diff(curDate, `days`)
    const weekDiff = (targetDate.week() - curDate.week() + 52) % 52

    switch (daysDiff) {
      case 0:
        dayString = `오늘`
        break
      case 1:
        dayString = `내일`
        break
      case 2:
        dayString = `내일 모레`
        break
      case -1:
        dayString = `어제`
        break
      case -2:
        dayString = `그저께`
        break
      default:
        switch (weekDiff) {
          case 0:
            dayString = `이번 주`
            break
          case 1:
            dayString = `다음 주`
            break
          case 2:
            dayString = `다다음 주`
            break
          case -1:
            dayString = `지난주`
            break
          case -2:
            dayString = `지지난주`
            break
          default:
            return dayjs(dateTime).format(`M월 D일`)
          // dayString = `${weekDiff} 주 후`
        }
        dayString += ` ${dayNames[targetDate.day()]}요일`
        return dayString
    }

    formatted = `${dayString} ${dayjs(dateTime).format(`A h시 m분`)}`
  }

  formatted = formatted.replace(`AM`, `오전`).replace(`PM`, `오후`)

  return formatted
}
*/

/*
export function weekOfMonth(input = dayjs()) {
  const firstDayOfMonth = input.clone().startOf(`month`)
  const firstDayOfWeek = firstDayOfMonth.clone().startOf(`week`)

  const offset = firstDayOfMonth.diff(firstDayOfWeek, `days`)

  return Math.ceil((input.date() + offset) / 7)
}

export function formatDateWeekOfMonth(date) {
  const weekNum = weekOfMonth(date)
  const monthNum = date.month() + 1

  let weekNumString
  switch (weekNum) {
    case 1:
      weekNumString = `첫째 주`
      break
    case 2:
      weekNumString = `둘째 주`
      break
    case 3:
      weekNumString = `셋째 주`
      break
    case 4:
      weekNumString = `넷째 주`
      break
    case 5:
      weekNumString = `다섯째 주`
      break
    default:
  }
  const formatted = `${monthNum}월 ${weekNumString}`
  return formatted
}

*/
