import React from 'react'
import { request } from 'util/request'
import { CacheReadConsistency } from 'src/services/prefetch/types'
import { asyncCatch } from 'util/async-catch'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { useIsSSR } from 'hooks/use-is-ssr'
import { useAsyncCallback } from 'hooks/use-async-callback'
import { usePathnameSearch } from 'hooks/use-location'
import { usePriorityDeferredValue } from 'hooks/use-priority-deferred-value'
import { useIsFullSSR } from 'hooks/use-is-full-ssr'

import deploymentConfig from 'values/deployment.json'
import { useSynchronousState } from 'hooks/use-synchronous-state'
import { hookRefreshesWith } from 'util/hook-refreshes-with/index'

const { apiServerHost } = deploymentConfig

interface UseDataArgs<T> {
  initialRequestCacheReadConsistency?: CacheReadConsistency
  cacheReadConsistency?: CacheReadConsistency
  cacheReadDuration?: number
  forceRevalidate?: boolean
  id?: string | null
  initialData?: RooutSSR.Data<T>
  defer?: boolean // defaults false. if true, data loading is deferred.
  priority?: number // defaults undefined, highest priority, concurrent with lower priority. behaves like normal defer.
  reloadOnNavigate?: boolean
}

export type LoadingState = `empty` | `loaded` | `loading` | `error`

const defaultHeaders: Record<string, string> = {}
const defaultUseDataArgs: UseDataArgs<any> = {}

/*
 * uses defaultData if data is populated in props.
 *
 */
export const useData = <T>(
  path: string | null,
  {
    initialRequestCacheReadConsistency,
    forceRevalidate = false,
    // lazy by default.
    // session is lazy.
    cacheReadConsistency: _cacheReadConsistency = `route`, // hmm is this right?? session consistency for default?? hmmmmm
    cacheReadDuration = 0,
    id: _id,
    /* deferred pass disabled for now! */
    initialData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    defer,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    priority,
    reloadOnNavigate = false,
  }: UseDataArgs<T> = defaultUseDataArgs,
): [T | null, LoadingState, () => Promise<void>, Record<string, string>] => {
  const isFullSSR = useIsFullSSR()
  const isSSR = useIsSSR()
  const pathnameSearch = usePathnameSearch()
  const id = _id || path
  // const initialIdBox = React.useRef(id)

  const [dataBox, setData] = useSynchronousState<T | null>(
    initialData?.body || null,
  )
  const [headersBox, setHeaders] = useSynchronousState<Record<string, string>>(
    initialData?.headers || defaultHeaders,
  )
  const [loadingStateBox, setLoadingState] = useSynchronousState<LoadingState>(
    initialData ? `loaded` : `empty`,
  )

  const handleBox = React.useRef<number>(0)
  const getIsMounted = useGetIsMounted()
  const isLoadingBox = React.useRef<boolean>(false)

  // on id change trigger, clear states
  React.useMemo(() => {
    // not setResult, since it's immediate
    // if (initialIdBox.current !== id) {
    hookRefreshesWith(id)

    setLoadingState(`empty`, true)
    setData(null, true)
    setHeaders({}, true)
    isLoadingBox.current = false
    handleBox.current += 1
    // }
  }, [setLoadingState, setData, setHeaders, id])

  const setResult = React.useCallback(
    (
      loadingState: LoadingState,
      data: T | null,
      header: Record<string, string>,
    ) => {
      // appendToWaitList(() => {
      // if (getIsMounted()) {
      setLoadingState(loadingState)
      setData(data)
      setHeaders(header)
      //  }
      // })
    },
    [
      /* getIsMounted */
      setLoadingState,
      setData,
      setHeaders,
    ],
  )

  const isInitialRequestBox = React.useRef(true)
  const load = useAsyncCallback(
    async (consistencyOverride?: CacheReadConsistency) => {
      const targetPath = path
      const handle = handleBox.current + 1
      handleBox.current = handle

      if (!targetPath) {
        return
      }

      isLoadingBox.current = true
      setLoadingState(`loading`)

      let cacheReadConsistency = _cacheReadConsistency
      if (isInitialRequestBox.current && initialRequestCacheReadConsistency) {
        cacheReadConsistency = initialRequestCacheReadConsistency
      }
      isInitialRequestBox.current = false
      const [err, res] = await asyncCatch(
        request<T>(`GET`, apiServerHost, path, {
          cacheReadConsistency: consistencyOverride || cacheReadConsistency,
          cacheReadDuration,
          forceRevalidate,
          auth: true,
        }),
      )

      if (!getIsMounted()) {
        return
      }

      if (handleBox.current !== handle) {
        return
      }

      isLoadingBox.current = false

      if (err) {
        setResult(`error`, null, {})
        console.error(err)
      } else {
        const { body, headers } = res
        setResult(`loaded`, body, headers)
      }
    },
    [
      path,
      getIsMounted,
      _cacheReadConsistency,
      initialRequestCacheReadConsistency,
      cacheReadDuration,
      forceRevalidate,
      setResult,
      setLoadingState,
    ],
  )

  React.useEffect(() => {
    if (!isSSR) {
      load()
    }
  }, [isSSR, load])

  const currentHref = pathnameSearch
  const latestLoadedLocationHrefBox = React.useRef(currentHref)
  React.useEffect(() => {
    if (
      reloadOnNavigate &&
      currentHref !== latestLoadedLocationHrefBox.current
    ) {
      latestLoadedLocationHrefBox.current = currentHref
      load()
    }
  }, [currentHref, load, reloadOnNavigate])

  const forceReload = React.useCallback(() => {
    return load(`strong`)
  }, [load])

  /*
    isSSR : all direct
    data :
      null : direct
      not-null : deferred
    loadingState : 
      `empty` | `loaded` | `loading` | `error`
      empty : direct
      loaded, error : deferred
      loading : direct, but don't trigger defer direct, since the value can be overridden
  */

  const data = dataBox.current
  const headers = headersBox.current
  const loadingState = loadingStateBox.current

  const dataAndLoadingState = React.useMemo<[T | null, LoadingState]>(() => {
    return [data, loadingState]
  }, [data, loadingState])

  const [deferredData, deferredLoadingState] = usePriorityDeferredValue<
    [T | null, LoadingState]
  >(dataAndLoadingState, {
    priority,
    direct: !defer || data === null, // if not using defer, values are visible immediately.
  })

  // visibility of loading is direct, but other values, follow deferred.
  const deferredLoadingStateWithDirectLoading =
    loadingState === `loading` || loadingState === `empty`
      ? loadingState
      : deferredLoadingState

  if (isFullSSR) {
    return [
      initialData?.body || null,
      `loaded`,
      forceReload,
      initialData?.headers || defaultHeaders,
    ]
  }

  return [
    deferredData,
    deferredLoadingStateWithDirectLoading,
    forceReload,
    headers,
  ]

  // return [data, loadingState, load]
}
