import React from 'react'
import type { ParsedLocation } from './types'

export const createReducer = <T,>(
  transformer: (parsedLocation: ParsedLocation) => T,
  defaultValue: T,
) => {
  const Context = React.createContext<T>(defaultValue)

  const Provider = ({
    location,
    children,
  }: {
    location: ParsedLocation
    children: React.ReactNode
  }) => {
    const value = React.useMemo(() => {
      return transformer(location)
    }, [location])

    return <Context.Provider value={value}>{children}</Context.Provider>
  }

  const useContext = () => {
    return React.useContext(Context)
  }

  return {
    Context,
    Provider,
    useContext,
  }
}
