export const isMobile = (): boolean => {
  if (typeof window === `undefined`) {
    return false
  }

  const match =
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  if (match) {
    return true
  }

  return false
}

export const getMobileWebOS = () => {
  if (typeof window === `undefined`) {
    return `unknown`
  }
  const userAgent = navigator.userAgent

  if (/android/i.test(userAgent)) {
    return `android`
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return `ios`
  }

  return `unknown`
}
