import React from 'react'
import { useBoxedCallback } from 'hooks/use-boxed-callback'
import { hookRefreshesWith } from 'util/hook-refreshes-with'
import { useTouchGestures, Tuple } from 'util/gesture'
/*

*/
export const useDragToClose = ({
  translateTarget,
  isOpen,
  close,
}: {
  translateTarget: HTMLElement | (HTMLElement | null)[] | null
  isOpen: boolean
  close: () => void
}) => {
  /* eslint-disable no-param-reassign */
  const onDragToCloseStart = React.useCallback(() => {
    // if this doesn't exist, initial position doesnt get recorded!
  }, [])

  const setStyle = useBoxedCallback(
    (amount: number, seconds: number) => {
      if (Array.isArray(translateTarget)) {
        translateTarget.forEach((element) => {
          if (element) {
            element.style.transform = `translateY(${amount}px)`
            element.style.transition = `transform ${seconds}s`
            element.style.willChange = `transform`
          }
        })
      } else if (translateTarget) {
        translateTarget.style.transform = `translateY(${amount}px)`
        translateTarget.style.transition = `transform ${seconds}s`
        translateTarget.style.willChange = `transform`
      }
    },
    [translateTarget],
  )

  const onDragToCloseMove = React.useCallback(
    ({ movement }: { movement: Tuple }) => {
      const amount = movement[1]
      if (amount >= 0) {
        setStyle(amount, 0)
      }
    },
    [setStyle],
  )

  const restoreDragOffset = React.useCallback(() => {
    setStyle(0, 0.2)
  }, [setStyle])

  const onDragToCloseEnd = React.useCallback(
    ({ movement }: { movement: Tuple }) => {
      if (movement[1] > 120) {
        close()
      } else {
        restoreDragOffset()
      }
    },
    [close, restoreDragOffset],
  )

  React.useEffect(() => {
    hookRefreshesWith(translateTarget)
    if (isOpen) {
      restoreDragOffset()
    }
  }, [isOpen, restoreDragOffset, translateTarget])

  const bindDragToClose = useTouchGestures<HTMLElement>({
    onStart: onDragToCloseStart,
    onMove: onDragToCloseMove,
    onEnd: onDragToCloseEnd,
    skipMouseEvents: true,
  })

  return bindDragToClose
}
