import React from 'react'
import { useQuery } from 'src/hooks/use-query'
import { useIsSSR } from 'hooks/use-is-ssr'
import { replaceQuery, replaceAnchor } from 'util/url'

/*

Temporarily disabled for usePopState issue triggering route update.

export const useAnchor = (anchor: string | null, enabled: boolean) => {
  React.useEffect(() => {
    if (!anchor || !enabled) return

    replaceAnchor(anchor)
  }, [anchor, enabled])
}
*/

export const useConsumeQueryAnchor = () => {
  const anchorQuery = useQuery(`anchor`)
  React.useEffect(() => {
    if (anchorQuery) {
      // remove anchor
      replaceQuery(`anchor`, undefined)
      replaceAnchor(anchorQuery)

      // consume anchor that was saved in the query into hash
    }
  }, [anchorQuery])
}

export const useInitialAnchor = (enabled: boolean) => {
  const isSSR = useIsSSR()
  return React.useMemo<string | null>(() => {
    if (!enabled) {
      return null
    }

    if (!isSSR) {
      const { hash } = window.location
      if (hash.indexOf(`#`) === 0) {
        return hash.substring(1, hash.length)
      }
      return hash
    }

    return null
  }, [enabled, isSSR])
}
