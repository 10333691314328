import React from 'react'
import { request } from 'util/request'
import deploymentConfig from 'values/deployment.json'
import { select } from 'util/select'
import { getUid } from 'model/app/auth'
import { replaceQuery } from 'util/url'
import { Capacitor } from '@capacitor/core'
import { checkPermissions } from 'model/app/push'
import { hookRefreshesWith } from 'util/hook-refreshes-with'
import { useUid } from 'hooks/use-uid'
import { checkIfVersionSatisfied } from 'hooks/use-app-info'
import { useOverlayPopped } from 'hooks/use-overlay'
import { useGetter } from 'hooks/use-getter'

const { apiServerHost } = deploymentConfig

// in face tryDisplayNotificationsMenu
export const tryDisplayMarketingConsent = async () => {
  const uid = getUid()
  if (!uid) {
    return
  }

  let shouldDisplay = false

  if (Capacitor.isNativePlatform()) {
    const result = await checkPermissions()
    if (result !== `granted`) {
      // permission was not granted!!
      // we need to display the consent!
      // open settings is added in 1.1.0
      if (result === `denied` && (await checkIfVersionSatisfied(`1.1.0`))) {
        shouldDisplay = true
      } else {
        shouldDisplay = true
      }
    }
  }

  if (!shouldDisplay) {
    const result = await request<User>(`GET`, apiServerHost, `/users/${uid}`, {
      auth: true,
    })

    const [value, validUntilSeconds, isSeller]: [boolean, number, boolean] =
      select(result.body, [
        `private.settings.marketingConsent.value`,
        `private.settings.marketingConsent.validUntil._seconds`,
        `public.data.commerce.isSeller`,
      ])

    const isAgreed = value && validUntilSeconds >= Math.floor(Date.now() / 1000)
    shouldDisplay = !isSeller && !isAgreed
  }

  if (shouldDisplay) {
    const currentTimeSeconds = Math.floor(Date.now() / 1000)
    localStorage.setItem(
      `latestMarktingConsentDisplayedAtSeconds`,
      `${currentTimeSeconds}`,
    )

    // open the overlay!
    replaceQuery({
      overlaySlug: `/marketing-consent?disableBackgroundClose=true`,
    })
  }
}

/*
  if (typeof window !== `undefined`) {
    ;(window as any).tryDisplayMarketingConsent = tryDisplayMarketingConsent
  }
*/

let marketingConsentDisplayed = false
export const useTryDisplayMarketingConsent = () => {
  const overlayPopped = useOverlayPopped()
  const uid = useUid()

  const getOverlayPopped = useGetter(overlayPopped)
  React.useEffect(() => {
    let latestMarktingConsentDisplayedAtSeconds = 0
    const latestMarktingConsentDisplayedAtSecondsString = localStorage.getItem(
      `latestMarktingConsentDisplayedAtSeconds`,
    ) as string
    if (latestMarktingConsentDisplayedAtSecondsString) {
      try {
        latestMarktingConsentDisplayedAtSeconds = Number(
          latestMarktingConsentDisplayedAtSecondsString,
        )
      } catch (e) {
        console.error(e)
      }
    }

    const currentTimeSeconds = Math.floor(Date.now() / 1000)
    const isMarketingConsentCooldown =
      currentTimeSeconds - latestMarktingConsentDisplayedAtSeconds <
      90 * 24 * 60 * 60 // 3 months cooldown

    // when uid changes and exists,
    hookRefreshesWith(uid)
    if (
      uid &&
      !marketingConsentDisplayed &&
      !overlayPopped &&
      !isMarketingConsentCooldown
    ) {
      // wait for 500ms for other overlays to pop up
      /*
        Why? 
        => overlay-popping-hooks might call after me!
      */
      window.setTimeout(() => {
        if (!getOverlayPopped()) {
          marketingConsentDisplayed = true
          tryDisplayMarketingConsent()
        }
      }, 500)
    }
  }, [uid, overlayPopped, getOverlayPopped])
}

export const TryDisplayMarketingConsentComponent = () => {
  useTryDisplayMarketingConsent()
  return null
}
