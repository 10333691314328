import React from 'react'

export const Form = ({
  id,
  className,
  onSubmit,
  autoComplete = `off`,
  children,
  //
  name,
  method,
  action,
}: {
  id: string
  className?: string
  onSubmit: React.FormEventHandler<HTMLFormElement>
  autoComplete?: string
  children: React.ReactNode
  //
  name?: string // ex) search
  method?: string // ex) get
  action?: string // ex) /search
}) => {
  const formRef = React.useRef<HTMLFormElement>(null)

  return (
    <form
      ref={formRef}
      id={id}
      className={className || undefined}
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
        ;(document.activeElement as HTMLElement)?.blur()
        const res = onSubmit(e)
        return res
      }}
      autoComplete={autoComplete}
      name={name}
      method={method}
      action={action}
    >
      {children}
    </form>
  )
}
