import { request } from 'util/request'
import {
  onAddToCart,
  onChangeCount,
  onChangeMessage,
  onRemoveFromCart,
} from 'src/services/analytics/events/shopping/cart'
import { GetProductDataArgs } from 'src/services/analytics/events/util'
import { getUid } from 'model/app/auth'

import deploymentConfig from 'values/deployment.json'

const { apiServerHost } = deploymentConfig

export const getAnonymousCart = (): Cart => {
  if (typeof window !== `undefined` && window.localStorage) {
    const serializedCart = window.localStorage.getItem(`anonymousUserCart`)
    const cart: Cart = []
    if (serializedCart) {
      try {
        const parsedRes = JSON.parse(serializedCart)
        if (Array.isArray(parsedRes)) {
          for (let i = 0; i < parsedRes.length; i += 1) {
            const item = parsedRes[i]
            if (typeof item === `object` && item) {
              const { productId, message, count } = item
              if (
                typeof productId === `string` &&
                typeof message === `string` &&
                typeof count === `number` &&
                count > 0
              ) {
                cart.push({
                  productId,
                  message,
                  count,
                })
              }
            }
          }
        }
      } catch (e) {
        console.error(`cart deserialize fail! fallback to empty cart!`)
        console.error(e)
      }
    }

    return cart
  }

  return []
}

export const setAnonymousCart = (cart: Cart) => {
  if (typeof window !== `undefined` && window.localStorage) {
    window.localStorage.setItem(`anonymousUserCart`, JSON.stringify(cart))
  }
}

const populateProduct = async (productId: string) => {
  const { body } = await request<Product>(
    `GET`,
    apiServerHost,
    `/contents/product/${productId}`,
    {
      auth: true,
    },
  )

  return body
}

// populate!!!
const populateAnonymousCartUserData = async (
  cart: Cart,
): Promise<UserCartFragment> => {
  const populatedCart: PopulatedCart = await Promise.all(
    cart.map(async (item) => {
      const { productId } = item

      return {
        ...item,
        _populated: {
          product: await populateProduct(productId),
        },
      }
    }),
  )

  return {
    private: {
      shopping: {
        cart: populatedCart,
      },
    },
  }
}

export const getAnonymousCartUserData = async (): Promise<UserCartFragment> => {
  const cart = getAnonymousCart()
  return populateAnonymousCartUserData(cart)
}

export const addToCart = async (
  productId: string,
  analyticsArgs: GetProductDataArgs,
) => {
  let body
  const uid = getUid()
  if (!uid) {
    const cart = getAnonymousCart()
    const item = cart.find((e) => e.productId === productId)
    if (item) {
      item.count += 1
    } else {
      cart.push({
        count: 1,
        productId,
        message: ``,
      })
    }
    setAnonymousCart(cart)

    body = await populateAnonymousCartUserData(cart)
  } else {
    ;({ body } = await request<UserCartFragment>(
      `POST`,
      apiServerHost,
      `/cart/${productId}`,
      {
        auth: true,
      },
    ))
  }

  onAddToCart(analyticsArgs)

  return body
}

export const changeCount = async (
  productId: string,
  targetCount: number,
  analyticsArgs: GetProductDataArgs,
) => {
  let body
  const uid = getUid()
  if (!uid) {
    const cart = getAnonymousCart()
    const item = cart.find((e) => e.productId === productId)
    if (item) {
      item.count = targetCount
    }
    setAnonymousCart(cart)

    body = await populateAnonymousCartUserData(cart)
  } else {
    ;({ body } = await request<UserCartFragment>(
      `PUT`,
      apiServerHost,
      `/cart/${productId}`,
      {
        data: {
          count: targetCount,
        },
        auth: true,
      },
    ))
  }

  onChangeCount(analyticsArgs, targetCount)
  return body
}

export const changeMessage = async (
  productId: string,
  targetMessage: string,
  analyticsArgs: GetProductDataArgs,
) => {
  let body
  const uid = getUid()
  if (!uid) {
    const cart = getAnonymousCart()
    const item = cart.find((e) => e.productId === productId)
    if (item) {
      item.message = targetMessage
    }
    setAnonymousCart(cart)

    body = await populateAnonymousCartUserData(cart)
  } else {
    ;({ body } = await request<UserCartFragment>(
      `PUT`,
      apiServerHost,
      `/cart/${productId}`,
      {
        data: {
          message: targetMessage,
        },
        auth: true,
      },
    ))
  }

  onChangeMessage(analyticsArgs, targetMessage)
  return body
}

export const removeFromCart = async (
  productId: string,
  analyticsArgs: GetProductDataArgs,
) => {
  let body
  const uid = getUid()
  if (!uid) {
    let cart = getAnonymousCart()
    cart = cart.filter((e) => e.productId !== productId)
    setAnonymousCart(cart)

    body = await populateAnonymousCartUserData(cart)
  } else {
    ;({ body } = await request<UserCartFragment>(
      `DELETE`,
      apiServerHost,
      `/cart/${productId}`,
      {
        auth: true,
      },
    ))
  }

  onRemoveFromCart(analyticsArgs)
  return body
}
