import React from 'react'
import { useGetter } from 'hooks/use-getter'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { useForceUpdate } from 'hooks/use-force-update'
import { useIsSSR } from 'hooks/use-is-ssr'

/*
  returns [elementValue, styleValue]

  1. on turn on
  elementValue turns on with styleValue off,
  styleValue follows up

  [true false]
  [true true]

  2. on turn off
  styleValue turns off with elementValue on ,
  elementValue follows up
  [true false]
  [false false]

*/

interface UseDelayedSwitchOptions {
  enableDelay?: number
  disableDelay?: number
}

const defaultOptions: UseDelayedSwitchOptions = {}

export const useDelayedSwitch = (
  value: boolean,
  {
    enableDelay = 17,
    disableDelay = 500,
  }: UseDelayedSwitchOptions = defaultOptions,
) => {
  const isSSR = useIsSSR()
  // if !value, check for persist. if persist, return persisted value.
  // if value, return true, but turn persistence on.
  const forceUpdate = useForceUpdate()
  const timeoutHandleBox: React.MutableRefObject<number | null> =
    React.useRef<number>(null)

  const boolValue = !!value

  const lastStateChangeTimeBox = React.useRef(0)
  const prevBoolValueBox = React.useRef(boolValue)
  const getEnableDelay = useGetter(enableDelay)
  const getDisableDelay = useGetter(disableDelay)
  const getIsMounted = useGetIsMounted()

  /* delayed switch doesn't set any states inside memo. */
  React.useMemo(() => {
    // delayed switch dont fire on ssr
    if (isSSR) {
      return
    }

    const enableDelay = getEnableDelay()
    const disableDelay = getDisableDelay()

    if (boolValue !== prevBoolValueBox.current) {
      lastStateChangeTimeBox.current = Date.now()
      const targetDelay = boolValue ? enableDelay : disableDelay
      if (timeoutHandleBox.current) {
        clearTimeout(timeoutHandleBox.current)
      }

      timeoutHandleBox.current = window.setTimeout(() => {
        if (getIsMounted()) {
          forceUpdate()
        }
      }, targetDelay + 1)
    }

    prevBoolValueBox.current = boolValue
  }, [
    boolValue,
    getEnableDelay,
    getDisableDelay,
    getIsMounted,
    forceUpdate,
    isSSR,
  ])

  if (boolValue) {
    if (lastStateChangeTimeBox.current + enableDelay >= Date.now()) {
      return [true, false]
    }

    return [true, true]
  }

  if (lastStateChangeTimeBox.current + disableDelay >= Date.now()) {
    // delay not passed
    return [true, false]
  }

  return [false, false]
}
