import { handleDefaultEvent } from 'src/services/analytics/events/util'

export interface AuthContextEventArgs {
  context: AuthInterfaceContext
}

export interface AuthContextUserIdEventArgs {
  context: AuthInterfaceContext
  userId: string
}

export interface AuthUserIdEventArgs {
  userId: string
}

export const onSignin = ({ userId, context }: AuthContextUserIdEventArgs) => {
  handleDefaultEvent(
    `login`,
    { user_id: userId, auth: { context } },
    { clearObject: true },
  )
}

export const onSessionAuth = ({ userId }: AuthUserIdEventArgs) => {
  handleDefaultEvent(
    `session_auth`,
    { user_id: userId },
    { clearObject: false },
  )
}

export const onPaymentOverlayAuthWindowOpen = () => {
  handleDefaultEvent(`payment_overlay_auth_window_open`)
}

export const onPasswordResetButtonClick = ({
  context,
}: AuthContextEventArgs) => {
  handleDefaultEvent(`password_reset_button_click`, { auth: { context } })
}

export const onSignupVerificationCodeRequest = ({
  context,
}: AuthContextEventArgs) => {
  handleDefaultEvent(`signup_verification_code_request`, { auth: { context } })
}

export const onPasswordResetVerificationCodeRequest = ({
  context,
}: AuthContextEventArgs) => {
  handleDefaultEvent(`password_reset_verification_code_request`, {
    auth: { context },
  })
}

export const onSignupVerificationCodeReRequest = ({
  context,
}: AuthContextEventArgs) => {
  handleDefaultEvent(`signup_verification_code_re_request`, {
    auth: { context },
  })
}

export const onPasswordResetVerificationCodeReRequest = ({
  context,
}: AuthContextEventArgs) => {
  handleDefaultEvent(`password_reset_verification_code_re_request`, {
    auth: { context },
  })
}

export const onPasswordResetVerificationCodeSubmit = ({
  context,
}: AuthContextEventArgs) => {
  handleDefaultEvent(`password_reset_verification_code_submit`, {
    auth: { context },
  })
}

export const onSignupChangeId = ({ context }: AuthContextEventArgs) => {
  handleDefaultEvent(`signup_change_id`, { auth: { context } })
}

export const onPasswordResetChangeId = ({ context }: AuthContextEventArgs) => {
  handleDefaultEvent(`password_reset_change_id`, { auth: { context } })
}

export const onPasswordResetSubmit = ({ context }: AuthContextEventArgs) => {
  handleDefaultEvent(`password_reset_submit`, { auth: { context } })
}

export const onSignupSubmit = ({ context }: AuthContextEventArgs) => {
  handleDefaultEvent(`signup_submit`, { auth: { context } })
}

export const onSigninSubmit = ({ context }: AuthContextEventArgs) => {
  handleDefaultEvent(`signin_submit`, { auth: { context } })
}

export const onPasswordReset = ({
  userId,
  context,
}: AuthContextUserIdEventArgs) => {
  handleDefaultEvent(`password_reset`, { user_id: userId, auth: { context } })
}

export const onSignup = ({ userId, context }: AuthContextUserIdEventArgs) => {
  handleDefaultEvent(`sign_up`, { user_id: userId, auth: { context } })
}

export const onLogOut = () => {
  handleDefaultEvent(`logout`, { user_id: undefined }, { clearObject: false })
}
