import { getUid, getIdToken } from 'model/app/auth'
import { requestBoxFetch } from 'src/services/prefetch/request-box-fetch'
import {
  RequestBoxCacheOptions,
  RequestBoxFetchArgs,
  ResultHandleType,
} from 'src/services/prefetch/types'
import { routeIdListener } from 'util/url'
import { getLocalStorageId } from 'util/get-local-storage-id'
import { getSessionStorageId } from 'util/get-session-storage-id'

type Method = `POST` | `PUT` | `GET` | `DELETE`

interface RequestOptions extends RequestBoxCacheOptions {
  body?: BodyInit
  data?: any
  headers?: Record<string, string>
  auth?: boolean | string // translates to uid
  appCheck?: boolean
  allowCORS?: boolean // translates to mode
}

export interface RequestResult<T> {
  status: number
  headers: Record<string, string>
  processedContentType: ResultHandleType
  body: T
}

export const request = async <T>(
  method: Method,
  _host: string,
  _path: string,
  {
    body,
    data,
    auth: useAuthOrToken = false,
    resultHandleType, // undefined
    headers: headersInit = {},
    allowCORS = false,
    cacheReadConsistency = `shared-parallel`,
    cacheReadDuration = 0,
    forceRevalidate = false, // don't force revalidate by default
    noCache = false, // cache by default
    /* eslint-disable-next-line */
    appCheck = false,
  }: RequestOptions,
) => {
  let idToken
  let uid = null
  if (useAuthOrToken) {
    if (typeof useAuthOrToken === `boolean`) {
      uid = getUid()
      if (uid) {
        idToken = await getIdToken()
      }
    } else {
      idToken = useAuthOrToken
    }
  }

  /*
    updated!
    add slash to host if not,
    remove slash from path if exists.
  */
  const host = _host.replace(/\/$/g, ``)
  const path = _path.replace(/^\//g, ``)

  const url = new URL(path, `https://${host}/`)

  const fetchOptions: RequestBoxFetchArgs = {
    //   cache: 'no-cache',
    //   credentials: 'include', // send cookie credentials
    method,
    uid, // auth
    // cache
    resultHandleType, // custom
    cacheReadConsistency,
    cacheReadDuration,
    forceRevalidate,
    noCache,
  }

  /*
    if url same as location origin && idToken, set cors mode to same-origin, set header. 
  */

  const isSameOrigin = url.origin === window.location.origin

  if (isSameOrigin) {
    fetchOptions.mode = `same-origin`
  } else if (allowCORS) {
    fetchOptions.mode = `cors`
  }

  fetchOptions.headers = new Headers(headersInit)
  if ((isSameOrigin || allowCORS) && idToken) {
    fetchOptions.headers.append(`Authorization`, `Bearer ${idToken}`)
    fetchOptions.headers.append(`Authorization-Version`, `1`)
  }

  /*
    always add sessionStorageId and localStorageId

    later, add deviceId if application.
  */
  if (typeof window !== `undefined`) {
    const slug = window.location.pathname + window.location.search
    fetchOptions.headers.append(`X-Slug`, encodeURIComponent(slug))
  }
  fetchOptions.headers.append(`X-RouteId`, `${routeIdListener.getValue()}`)
  fetchOptions.headers.append(`X-SessionStorageId`, getSessionStorageId())
  fetchOptions.headers.append(`X-LocalStorageId`, getLocalStorageId())

  if (body && data) {
    throw new Error(`Cannot set body and data at same time!`)
  }
  if (body) {
    fetchOptions.body = body
  } else if (data) {
    fetchOptions.body = typeof data === `string` ? data : JSON.stringify(data)
    fetchOptions.headers.append(
      `Content-Type`,
      `application/json; charset=utf-8`,
    )
  }

  return requestBoxFetch<T>(url, fetchOptions)
}
