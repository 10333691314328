// extracted by mini-css-extract-plugin
export var backButtonIcon = "bF_pc";
export var blurOverlay = "bF_n5";
export var closeButtonIcon = "bF_pd";
export var closeOverlayButton = "bF_n2";
export var closed = "bF_jB";
export var disableBackgroundClose = "bF_n0";
export var dontInterceptClose = "bF_hR";
export var overlayCardContainer = "bF_n1";
export var overlayContentBlurWrapper = "bF_n4";
export var overlayContentContainer = "bF_n3";
export var overlayHeader = "bF_n6";
export var overlayHeaderCenterPadding = "bF_n9";
export var overlayListCount = "bF_n8";
export var overlaySuspenseMessage = "bF_pb";
export var overlayTitle = "bF_n7";
export var rootContainer = "bF_b";