import React from 'react'
import { SubmittingSpinner } from 'components/widgets/submitting-spinner'
import * as styles from './style.module.less'

export const SuspenseMessage = ({
  className,
  message,
  style: styleProps,
}: {
  className?: string
  message: string
  style?: React.CSSProperties
}) => {
  return (
    <div
      className={`${styles.rootContainer} ${className || ``}`}
      style={styleProps}
    >
      <div className={`${styles.suspenseTextContainer} t`}>
        <SubmittingSpinner
          size={20}
          className={styles.suspenseTextSubmittingSpinner}
        />
        {message}
      </div>
    </div>
  )
}
