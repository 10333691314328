import React from 'react'
import { select } from 'util/select'
import BadgeIcon from 'assets/images/svgs/seller-badge.svg'
import adminAccountsConfig from 'values/admin-accounts.json'

import * as styles from './style.module.less'

const { ceo, cto, admin, customerService, producerService } =
  adminAccountsConfig

const adminAccountList = [ceo, cto, admin, customerService, producerService]

interface BadgeProps {
  data: User | null
  className?: string
  id?: string
}

export const Badge = ({ data, className, id }: BadgeProps) => {
  // TODO select typescriptify
  const [userId, isSeller]: [string, boolean] = select(data, [
    `id`,
    `public.data.commerce.isSeller`,
  ])

  if (adminAccountList.indexOf(userId) !== -1) {
    return (
      <BadgeIcon
        className={`${styles.adminBadgeIcon} ${className || ``}`}
        id={id}
      />
    )
  }

  if (isSeller) {
    return (
      <BadgeIcon
        className={`${styles.sellerBadgeIcon} ${className || ``}`}
        id={id}
      />
    )
  }

  return null
}
