import React from 'react'
import { Image } from 'components/media/image'
import { Link } from 'components/link'
import * as styles from './style.module.less'

interface ProfileImageProps {
  src: string
  size?: number
  className?: string
  id?: string
  alt: string
  lazy?: boolean
  //
  itemProp?: string
  property?: string
}

interface ProfileImageLinkProps {
  src: string
  to: string
  size?: number
  className?: string
  id?: string
  alt: string
  'aria-label': string
  rel?: string
  lazy?: boolean
  //
  itemProp?: string
  property?: string
}

export const ProfileImage = (
  props: ProfileImageProps | ProfileImageLinkProps,
) => {
  const {
    src,
    size = 40,
    className,
    id,
    alt,
    itemProp,
    property,
    lazy = true,
  } = props
  const widths = React.useMemo(() => {
    return [size, size * 2, size * 3]
  }, [size])

  let element
  if (src) {
    element = (
      <Image
        src={src}
        alt={alt}
        width={size}
        height={size}
        sizes={`${size}px`}
        widths={widths}
        lazy={lazy}
        //
        itemProp={itemProp}
        property={property}
      />
    )
  } else {
    element = (
      <img
        src="/images/default-profile-image.jpg"
        width={size}
        height={size}
        alt={alt}
        loading={lazy ? `lazy` : undefined}
        //
        itemProp={itemProp}
        property={property}
      />
    )
  }
  if (`to` in props) {
    const { to, rel, 'aria-label': ariaLabel } = props as ProfileImageLinkProps
    return (
      <Link
        style={size ? { width: `${size}px`, height: `${size}px` } : undefined}
        to={to}
        className={`${styles.rootContainer} ${className || ``}`}
        id={id}
        aria-label={ariaLabel}
        rel={rel}
        //

        itemProp={itemProp}
        property={property}
      >
        {element}
      </Link>
    )
  }

  return (
    <div
      style={size ? { width: `${size}px`, height: `${size}px` } : undefined}
      className={`${styles.rootContainer} ${className || ``}`}
      id={id}
    >
      {element}
    </div>
  )
}
