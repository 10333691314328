import React from 'react'
import { useBoxedCallback } from 'hooks/use-boxed-callback'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { executeLast } from 'util/execute-last'
import { useIsSSR } from 'hooks/use-is-ssr'

// need debounce
// type TimedFunctionHandle = number | null

const defaultOption = { passive: true }

/*
  current clients : 
  - horizontal-scroll
    - detect whether to render horizontal scroll buttons. If list end reached, disable horizontal scroll buttons
  - media-list
    - change index dots and mediaList length badge
  - post-list-banner
    - change index dots
  - home
    - change header background display
    - read scrollY
  - use-infinite-list
    - track anchor, load more data based on scroll
  - use-scroll-anchor
    - save scroll position


  Optimizations : 
    1. skip first call and debounce right away.
    2. yield to render. use startTransition.
    3. slow down.

  Optimizations within scroll handlers : 
    1. remove all other DOM position accessing positions. Change to fixed width access. Use only single scroll position for the element.
      => for elements, don't evaluate every time, but only when the element renders, get the scroll value? height value? hmm
    2. Cache scroll position access for the element. 
*/

/*
  execute & debounce executions to fit only one execution per cooldown.

  What can we do : 
    1. debounce!
*/

/*
  useThrottledEvent should align traffics that cause layout thrashing to single frame so that
  thrashing happens only once per time :)
*/

export const useThrottledEvent = <
  T extends HTMLElement | (Window & typeof globalThis) | Window,
>(
  eventName: string,
  callback: (element: T) => void,
  element: T | null,
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  cooldown = 150, // cooldown is quantized, which means might delay more than 150 + a seconds.
  option = defaultOption,
) => {
  const isSSR = useIsSSR()
  const getIsMounted = useGetIsMounted()
  // const debounceHandleBox = React.useRef<TimedFunctionHandle>(null)
  const isWaitingFlushBox = React.useRef(false)

  const flush = useBoxedCallback(() => {
    isWaitingFlushBox.current = false
    if (getIsMounted()) {
      if (
        element &&
        (element === window || (element as HTMLElement).isConnected)
      ) {
        callback(element)
      }
    }
  }, [getIsMounted, callback, element])

  // ensured only single instance runs at once.
  const wrappedHandler = useBoxedCallback(() => {
    // getIsMounted
    if (!isWaitingFlushBox.current && !isSSR) {
      isWaitingFlushBox.current = true
      // debounceHandleBox.current = window.setTimeout(() => {
      //   debounceHandleBox.current = null
      executeLast(() => {
        flush()
      }, `quantized`)
      // }, cooldown)
    }
  }, [flush, /* cooldown, */ isSSR])

  React.useEffect(() => {
    if (!element) {
      return
    }
    element.addEventListener(eventName, wrappedHandler, option)
    return () => {
      element.removeEventListener(eventName, wrappedHandler)
    }
  }, [element, wrappedHandler, eventName, option])

  return wrappedHandler
}
