import React from 'react'
import { Link } from 'components/link'
import { select } from 'util/select'
import { useData } from 'hooks/use-data'
import { ProfileImage } from 'components/widgets/profile-image'
import { useThrottledRealTimeSearchValue } from 'hooks/use-throttled-real-time-search-value'
import { HorizontalScrollButtonBlock } from 'components/horizontal-scroll-button-block'
import { useStateRef } from 'hooks/use-state-ref'
import { hookRefreshesWith } from 'util/hook-refreshes-with'
import { Badge } from 'components/widgets/badge'

import * as styles from './style.module.less'

const UserCard = ({ data }: { data: User }) => {
  const [id, name, profileImage]: [string, string, string] = select(data, [
    `id`,
    `public.profile.name`,
    `public.profile.profileImage`,
  ])

  return (
    <Link
      to={`/u?user=${id}`}
      aria-label={`${name} 페이지 보기`}
      className={styles.userCard}
    >
      <div className={styles.profileImageContainer}>
        <ProfileImage
          size={34}
          src={profileImage}
          className={`${styles.profileImage} ${
            data ? `` : `data-empty-hidden`
          }`}
          alt={`${name} 프로필 사진`}
        />
      </div>
      <div
        className={`${styles.profileName} t6 ${
          name ? `` : `data-empty-hidden`
        }`}
      >
        {name || `.`}
        <Badge data={data} className={styles.badge} />
      </div>
    </Link>
  )
}

export const SearchHelperUserList = ({
  searchValue,
  onLoadComplete,
}: {
  searchValue: string
  onLoadComplete: (valueExists: boolean) => void
}) => {
  const throttledSearchValue = useThrottledRealTimeSearchValue(searchValue)

  const [type, setType] = React.useState<'seller' | 'all'>(`seller`)

  const [sellerList, sellerListLoadingState] = useData<User[]>(
    throttledSearchValue
      ? `/search/v2/users-by-name?type=seller&text=${encodeURIComponent(
          throttledSearchValue,
        )}`
      : null,
    {
      cacheReadConsistency: `session`,
    },
  )

  const [allUserList, allUserListLoadingState] = useData<User[]>(
    throttledSearchValue
      ? `/search/v2/users-by-name?type=all&text=${encodeURIComponent(
          throttledSearchValue,
        )}`
      : null,
    {
      cacheReadConsistency: `session`,
    },
  )

  const sellerListLoaded =
    sellerListLoadingState !== `empty` && sellerListLoadingState !== `loading`
  const allUserListLoaded =
    allUserListLoadingState !== `empty` && allUserListLoadingState !== `loading`

  const sellerListEmpty = !sellerList || sellerList.length === 0
  const allUserListEmpty = !allUserList || allUserList.length === 0

  const usersElement = React.useMemo(() => {
    const userList = type === `seller` ? sellerList : allUserList

    if (!userList || userList.length === 0) {
      return null
    }

    return userList.map((user) => {
      return <UserCard data={user} key={user?.id} />
    })
  }, [type, sellerList, allUserList])

  const horizontalRef = useStateRef<HTMLUListElement>(null)

  const loadCompleteConsumedBox = React.useRef(false)
  React.useMemo(() => {
    hookRefreshesWith(searchValue)
    loadCompleteConsumedBox.current = false
  }, [searchValue])
  React.useEffect(() => {
    if (
      searchValue === throttledSearchValue &&
      sellerListLoaded &&
      allUserListLoaded
    ) {
      onLoadComplete(!sellerListEmpty || !allUserListEmpty)
    }
  }, [
    sellerListEmpty,
    allUserListEmpty,
    sellerListLoaded,
    allUserListLoaded,
    throttledSearchValue,
    searchValue,
    onLoadComplete,
  ])

  if (!searchValue) {
    return null
  }

  // all empty, return null.
  if (sellerListEmpty && allUserListEmpty) {
    return null
  }

  return (
    <div>
      <div className={`${styles.categoryTitle} t6`}>
        유저 별명으로 검색 &nbsp;[
        <button
          type="button"
          className={`${styles.nameSearchTypeButton} ${
            type === `seller` ? styles.selected : ``
          }`}
          onClick={() => {
            setType(`seller`)
          }}
        >
          농어부
        </button>
        ·
        <button
          type="button"
          className={`${styles.nameSearchTypeButton} ${
            type === `all` ? styles.selected : ``
          }`}
          onClick={() => {
            setType(`all`)
          }}
        >
          전체
        </button>
        ]
      </div>
      <HorizontalScrollButtonBlock
        listRef={horizontalRef}
        elementWidth={220}
        refreshAlways
        overlay
        refreshId={type}
      />
      <ul className={styles.userList} ref={horizontalRef}>
        {usersElement}
      </ul>
    </div>
  )
}
