export const objectEqual = (a: any, b: any) => {
  if (typeof a !== typeof b) {
    return false
  }

  if (a === b) return true

  if (typeof a === `string` && typeof b === `string`) {
    if (a.normalize(`NFKC`) === b.normalize(`NFKC`)) {
      return true
    }
  }

  if (typeof a === `object` && typeof b === `object`) {
    if (!a || !b) {
      return false
    }
    // too much allocation,,, hmm
    const aKeys = Object.keys(a)
    const bKeys = Object.keys(b)
    if (aKeys.length === bKeys.length) {
      for (let i = 0; i < aKeys.length; i += 1) {
        if (!objectEqual(a[aKeys[i]], b[aKeys[i]])) {
          return false
        }
      }
      return true
    }
    return true
  }
  return false
}
