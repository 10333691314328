// extracted by mini-css-extract-plugin
export var authBarrierAuthContainer = "bw_mT";
export var authBarrierMessageContainer = "bw_mR";
export var authBarrierPanel = "bw_mS";
export var authBarrierScrollWrapper = "bw_mQ";
export var backButtonContainer = "bw_mN";
export var backgroundContainer = "bw_mK";
export var closeButtonText = "bw_mP";
export var closed = "bw_jB";
export var dontInterceptClose = "bw_hR";
export var open = "bw_mL";
export var renderOverHeaderAndNavbar = "bw_mM";
export var rootContainer = "bw_b";