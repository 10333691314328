import React from 'react'
import { defaultDimension } from 'util/get-screen-dimension'
import { Listener } from 'util/listener'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { useIsSSR } from 'hooks/use-is-ssr'
import { executeLast } from 'util/execute-last'

// legacy.... hmmm...
export const viewportWidthListener = new Listener(defaultDimension[0])
export const viewportHeightListener = new Listener(defaultDimension[1])

export const screenWidthListener = new Listener(defaultDimension[0])
export const screenHeightListener = new Listener(defaultDimension[1])

// vw and maxVw
export const vwListener = new Listener(defaultDimension[0])
export const vhListener = new Listener(defaultDimension[1])

export const maxVwListener = new Listener(defaultDimension[0])
export const maxVhListener = new Listener(defaultDimension[1])

export const innerWidthListener = new Listener(defaultDimension[0])
export const innerHeightListener = new Listener(defaultDimension[1])

export const searchBarWidthListener = new Listener(0)
export const searchBarHeightListener = new Listener(0)

export const maxSearchBarWidthListener = new Listener(0)
export const maxSearchBarHeightListener = new Listener(0)

export const maxSearchBarExpandedInnerWidthListener = new Listener(
  defaultDimension[0],
)
export const maxSearchBarExpandedInnerHeightListener = new Listener(
  defaultDimension[1],
)

/*
  : viewportWidth when the screenWidth changed
  : subtract from screenWidth
  => 
  can use vh, but subtract the amount! hmm seems good.
  screen height - viewport height is the size of search bar.

  1. what if search bar height changes?

  2. 100vh - search bar : pretty constant inner height, used for scroll container??


  check for notch! => open simulator.

  3. 
*/

let firstRefresh = true
// recalculate!
export const refreshViewportDimension = () => {
  let screenWidth = screenWidthListener.getValue()
  let screenHeight = screenHeightListener.getValue()
  let vw = vwListener.getValue()
  let vh = vhListener.getValue()
  let maxVw = maxVwListener.getValue()
  let maxVh = maxVhListener.getValue()
  let innerWidth = innerWidthListener.getValue()
  let innerHeight = innerHeightListener.getValue()
  let searchBarWidth = searchBarWidthListener.getValue()
  let searchBarHeight = searchBarHeightListener.getValue()
  let maxSearchBarWidth = maxSearchBarWidthListener.getValue()
  let maxSearchBarHeight = maxSearchBarHeightListener.getValue()
  let maxSearchBarExpandedInnerWidth =
    maxSearchBarExpandedInnerWidthListener.getValue()
  let maxSearchBarExpandedInnerHeight =
    maxSearchBarExpandedInnerHeightListener.getValue()

  const lastScreenWidth = screenWidthListener.getValue()
  const lastScreenHeight = screenHeightListener.getValue()

  if (typeof window !== `undefined`) {
    screenWidth = window.screen.width
    screenHeight = window.screen.height

    innerWidth = window.innerWidth
    innerHeight = window.innerHeight

    const viewportMeasureElement = document.getElementById(`viewport-measure`)

    const rect = viewportMeasureElement?.getBoundingClientRect()

    /*
      Why is vw and vh measured is in floating points
      */
    vw = Math.floor(rect?.width || innerWidth)
    vh = Math.floor(rect?.height || innerHeight)

    searchBarWidth = vw - innerWidth
    searchBarHeight = vh - innerHeight

    const screenWidthChanged = lastScreenWidth !== screenWidth || firstRefresh
    const screenHeightChanged =
      lastScreenHeight !== screenHeight || firstRefresh

    firstRefresh = false

    /*
      if screenWidthChanged,
      clear maxInternal width / height.
      

    */
    if (screenWidthChanged || vw > maxVw) {
      maxVw = vw
    }

    if (screenHeightChanged || vh > maxVh) {
      maxVh = vh
    }

    if (screenWidthChanged || searchBarWidth > maxSearchBarWidth) {
      maxSearchBarWidth = searchBarWidth
    }

    if (screenWidthChanged || searchBarHeight > maxSearchBarHeight) {
      maxSearchBarHeight = searchBarHeight
    }

    maxSearchBarExpandedInnerWidth = maxVw - maxSearchBarWidth
    maxSearchBarExpandedInnerHeight = maxVh - maxSearchBarHeight
  }

  screenWidthListener.setValue(screenWidth)
  screenHeightListener.setValue(screenHeight)

  vwListener.setValue(vw)
  vhListener.setValue(vh)

  maxVwListener.setValue(maxVw)
  maxVhListener.setValue(maxVh)

  innerWidthListener.setValue(innerWidth)
  innerHeightListener.setValue(innerHeight)

  searchBarWidthListener.setValue(searchBarWidth)
  searchBarHeightListener.setValue(searchBarHeight)

  maxSearchBarWidthListener.setValue(maxSearchBarWidth)
  maxSearchBarHeightListener.setValue(maxSearchBarHeight)

  maxSearchBarExpandedInnerWidthListener.setValue(
    maxSearchBarExpandedInnerWidth,
  )
  maxSearchBarExpandedInnerHeightListener.setValue(
    maxSearchBarExpandedInnerHeight,
  )
}

if (typeof window !== `undefined`) {
  // let latestEventTime = 0
  let isWaiting = false

  window.addEventListener(
    `resize`,
    () => {
      if (isWaiting) {
        // return!
        return
      }

      // not in cooldown! pass~
      // if (latestEventTime + 100 < Date.now()) {
      // execute right away
      //  latestEventTime = Date.now()
      //  refreshViewportDimension()
      // } else {
      // in cooldown! go to waitlist and execute!
      // setTimeout and wait
      // debounce only..?
      isWaiting = true
      executeLast(() => {
        isWaiting = false
        // latestEventTime = Date.now()
        refreshViewportDimension()
      }, `quantized`)
    },
    { passive: true },
  )
}

// adds eventlistener
/*
  TODO use width height separately??
  Many elements depend on width, but not height!
*/
export const useViewportWidth = (): number => {
  const getIsMounted = useGetIsMounted()
  const isSSR = useIsSSR()
  const [viewportWidth, setViewportWidth] = React.useState(defaultDimension[0])

  React.useEffect(() => {
    if (isSSR) {
      return
    }
    setViewportWidth(innerWidthListener.getValue())
    const callback = () => {
      if (getIsMounted()) {
        setViewportWidth(innerWidthListener.getValue())
      }
    }

    return innerWidthListener.addListener(callback)
  }, [isSSR, getIsMounted])

  return viewportWidth
}
