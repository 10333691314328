import React from 'react'

import 'src/theme/global-styles.less'

import { useConsumeQueryAnchor } from 'hooks/use-anchor'
import { useQuery } from 'hooks/use-query'
import { LocationProvider } from 'hooks/use-location/provider'
import { TopNavigationBar } from 'containers/top-navigation-bar'
import { useSynchronousState } from 'hooks/use-synchronous-state'
import { useIsSSR, IsSSRProvider } from 'hooks/use-is-ssr'
import { KeyboardPoppedProvider } from 'hooks/use-keyboard-popped'
import { enableQuantizedLoop } from 'util/execute-last'
import { HasFCPProvider, useHasFCP } from 'hooks/use-has-fcp'
import { usePathname } from 'hooks/use-location'
import {
  refreshViewportDimension,
  maxVhListener,
  maxSearchBarHeightListener,
} from 'hooks/use-viewport-dimension'
import { SmartAppBanner } from 'components/smart-app-banner'

import { CartProvider } from 'hooks/use-cart-data'
import { WalletRefreshProvider } from 'hooks/use-wallet-data'
import { RewardStateProvider } from 'hooks/use-reward-state'
import { ReviewableTargetsProvider } from 'hooks/use-reviewable-targets'
import { UidProvider } from 'hooks/use-uid'
import { useAnalytics } from 'src/services/analytics'
import { useOnSessionEnter } from 'hooks/use-on-session-enter'
import { useScrollConsumer } from 'hooks/use-scroll-anchor'
import { ErrorBoundary } from 'components/error-boundary'
import { NavigationBar } from 'containers/navigation-bar'
import { setIsFullSSR } from 'hooks/use-is-full-ssr'
import { DisableOverscrollRefreshProvider } from 'hooks/use-disable-overscroll-refresh'
import { OnPopStateProvider } from 'hooks/use-on-pop-state'
import { RouteIdProvider } from 'hooks/use-route-id'
import { GlobalDiscountProvider } from 'hooks/use-global-discount'
import { OverlayProvider, OverlayElement } from 'hooks/use-overlay'
import { AuthBarrierProvider, AuthBarrierElement } from 'hooks/use-auth-barrier'
import { RewardCodeConsumer } from 'hooks/use-reward-code-consumer'
// app
import { setStatusBarColorAndroid } from 'src/services/app'
import { registerAppUrl } from 'hooks/use-consume-app-url' // not so good :)
import { registerPush, useSyncPushTokenState } from 'hooks/use-register-push'
import { registerTrackingPermission } from 'hooks/use-tracking-permission'
import { updateAppInfo, checkIfVersionSatisfied } from 'hooks/use-app-info'
import { TryDisplayMarketingConsentComponent } from 'hooks/use-try-display-marketing-consent'
import { Device } from '@capacitor/device'
import 'util/request-review' // remove later
// import { overrideBrowserOpen } from 'hooks/use-override-browser-open'
// import { SplashScreen } from '@capacitor/splash-screen'
//
// TEST
import { Capacitor } from '@capacitor/core'
import { Tosspayments } from 'src/services/tosspayments/native'

import * as styles from './style.module.less'

// app
updateAppInfo()
setStatusBarColorAndroid()
registerAppUrl()
registerPush()
registerTrackingPermission()
// overrideBrowserOpen()

// vvvvv temporary
declare global {
  interface Window {
    tosspayments?: typeof Tosspayments
  }
}

if (Capacitor.isNativePlatform()) {
  console.log(`test bind tosspayments`)
  window.tosspayments = Tosspayments
}
//

const OverlayMessageProvider = React.lazy(
  () => import(`hooks/use-overlay-message/overlay-message-provider`),
)

/*
  Android patch : set safe area bottom to 0 when android version less than 12.
  version 1.1.1 and above will have this automatically set to zero.
*/
if (
  typeof window !== `undefined` &&
  Capacitor.isNativePlatform() &&
  Capacitor.getPlatform() === `android`
) {
  ;(async () => {
    const result = await Device.getInfo()
    const androidSDKVersion = result.androidSDKVersion
    // 1.1.1 and above will have this automatically.
    const isInsetSetToZeroAutomatically = await checkIfVersionSatisfied(`1.1.1`)
    if (
      androidSDKVersion &&
      androidSDKVersion < 33 &&
      !isInsetSetToZeroAutomatically
    ) {
      // set to zero manually.
      document.documentElement.style.setProperty(
        `--safe-area-inset-bottom`,
        `0px`,
      )
    }
  })()
}

if (typeof window !== `undefined`) {
  maxVhListener.addListener((height) => {
    //
    document.documentElement.style.setProperty(`--max-vh`, `${height * 0.01}px`)
  })

  maxSearchBarHeightListener.addListener((height) => {
    //
    document.documentElement.style.setProperty(
      `--max-search-bar-height`,
      `${height}px`,
    )
  })
}

/*
  TODO move hash consumer here??
*/
export const Root = ({ children }: { children: React.ReactNode }) => {
  const isSSR = useIsSSR()
  const hasFCP = useHasFCP()
  const pathname = usePathname()
  const queryText = useQuery(`text`)
  if (pathname.indexOf(`/ssr`) === 0) {
    setIsFullSSR(true)
  }

  useConsumeQueryAnchor()
  useScrollConsumer()

  const [searchValueBox, setSearchValue] = useSynchronousState(``)
  React.useMemo(() => {
    if (pathname === `/search`) {
      setSearchValue(queryText as string, true)
    }
  }, [queryText, pathname, setSearchValue])

  const analyticsElement = useAnalytics()
  useOnSessionEnter()

  React.useMemo(() => {
    if (!isSSR) {
      // before this code, the viewport dimensions are default-mobile.
      // after this code, the viewport dimensions are screen.
      // after first resizing, the viewport dimensions are right.

      // this should be called before use-viewport-dimensions!!
      // viewport dimension listeners should listen on useEffect only!
      refreshViewportDimension()
    }
  }, [isSSR])

  React.useEffect(() => {
    if (hasFCP) {
      console.log(`hasfcp true; enabling quantizedLoop and splashScreen`)
      enableQuantizedLoop()
    }
  }, [hasFCP])

  // app
  useSyncPushTokenState()

  // use pwa-asset-generator for splash screen and icons!
  // <React.StrictMode> doesn't work well right now.
  // It doesn't fit our flow of deferred rendering
  return (
    <>
      <SmartAppBanner />
      <WalletRefreshProvider>
        <CartProvider>
          <RewardStateProvider>
            <ReviewableTargetsProvider>
              {analyticsElement}
              <div className={styles.rootContainer}>
                <KeyboardPoppedProvider>
                  <AuthBarrierProvider>
                    <RewardCodeConsumer />
                    <GlobalDiscountProvider>
                      <OverlayProvider>
                        <div className={styles.topNotchPadding} />
                        <TopNavigationBar
                          onSearchValue={setSearchValue}
                          searchValue={searchValueBox.current}
                          defaultSearchValue=""
                        />
                        {children}
                        <OverlayElement />
                        <AuthBarrierElement />
                        <NavigationBar />
                        {hasFCP ? (
                          <React.Suspense fallback={null}>
                            <OverlayMessageProvider />
                          </React.Suspense>
                        ) : null}
                        <div className={styles.bottomNotchPadding} />
                        <TryDisplayMarketingConsentComponent />
                      </OverlayProvider>
                    </GlobalDiscountProvider>
                  </AuthBarrierProvider>
                </KeyboardPoppedProvider>
              </div>
            </ReviewableTargetsProvider>
          </RewardStateProvider>
        </CartProvider>
      </WalletRefreshProvider>
    </>
  )
}

export const wrapPageElement = ({
  element,
  props,
}: {
  element: React.ReactNode
  props: {
    location: Location
  }
  // props : gatsby page props
}) => {
  const { location } = props
  return (
    <LocationProvider location={location}>
      <Root>{element}</Root>
    </LocationProvider>
  )
}

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => {
  /*
    dependency requirement order important!!
  */
  return (
    <>
      <ErrorBoundary>
        <OnPopStateProvider>
          <DisableOverscrollRefreshProvider>
            <IsSSRProvider>
              <HasFCPProvider>
                <RouteIdProvider>
                  <UidProvider>{element}</UidProvider>
                </RouteIdProvider>
              </HasFCPProvider>
            </IsSSRProvider>
          </DisableOverscrollRefreshProvider>
        </OnPopStateProvider>
      </ErrorBoundary>
      <div id="viewport-measure" />
    </>
  )
}
