import React from 'react'
import { TextField } from 'components/input/text-field'
import { Link } from 'components/link'
import { navigateBack, navigate, getInnerNavigated } from 'hooks/use-location'
import { onSearch as onSearchAnalytics } from 'src/services/analytics/events/search'
import { onSearch as onSearchActionLog } from 'model/app/action-log/on-search'
import { useIsFullSSR } from 'hooks/use-is-full-ssr'
import { Form } from 'components/form'
import { useComposeRefs } from 'hooks/use-compose-refs'
import { useOnPopState } from 'hooks/use-on-pop-state'
import LogoIcon from 'assets/images/svgs/logo.svg'
import DeleteIcon from 'assets/images/svgs/delete.svg'
import BackIcon from 'assets/images/svgs/back.svg'

import * as styles from './style.module.less'

export type SearchBarContainerType = `home` | `search`

/*
 * if it's result, everything should be the same, but back button on not focused => should be back
 * if it's result, and not focused, it should have type setting
 *
 *
 */

export interface UseSearchBarProps {
  formId: string // must provide
  searchValue?: string
  defaultSearchValue?: string
  onIsSearchingChange?: (isSearching: boolean) => void
  onSearchValue?: (value: string) => void
  onClose?: () => void
  renderCloseButton?: boolean
  searchBarContainerType?: SearchBarContainerType
  className?: string
}

export const useSearchBar = ({
  formId,
  defaultSearchValue = ``,
  onIsSearchingChange,
  searchValue,
  onSearchValue,
  onClose,
  renderCloseButton = true,
  searchBarContainerType,
  searchInputRef,
  className,
}: UseSearchBarProps & {
  searchInputRef?: React.RefObject<HTMLInputElement>
}) => {
  const isFullSSR = useIsFullSSR()
  const trimmedSearchValue = (searchValue && searchValue.trim()) || ``
  const targetURI = trimmedSearchValue
    ? `/search?text=${encodeURIComponent(trimmedSearchValue)}`
    : ``

  const textFieldRef = React.useRef<HTMLInputElement>(null)
  const textFieldComposedRef = useComposeRefs(textFieldRef, searchInputRef)
  const [isSearching, _setIsSearching] = React.useState(false)
  const setIsSearching = React.useCallback(
    (value: boolean) => {
      _setIsSearching(value)
      if (onIsSearchingChange) {
        onIsSearchingChange(value)
      }
    },
    [onIsSearchingChange],
  )

  const setSearchValue = React.useCallback(
    (value: string) => {
      if (onSearchValue) {
        onSearchValue(value)
      }
    },
    [onSearchValue],
  )

  React.useEffect(() => {
    setIsSearching(false)
  }, [defaultSearchValue, setIsSearching])

  const handleFormSubmit = React.useCallback(
    (
      e:
        | React.MouseEvent<HTMLAnchorElement, MouseEvent>
        | React.FormEvent<HTMLFormElement>,
    ) => {
      if (e && e.preventDefault) {
        e.preventDefault()
      }
      if (targetURI && trimmedSearchValue !== defaultSearchValue) {
        onSearchAnalytics(trimmedSearchValue)
        onSearchActionLog(trimmedSearchValue)
        navigate(targetURI)
      }
    },
    [targetURI, trimmedSearchValue, defaultSearchValue],
  )

  const onCloseSearchPanel = React.useCallback(
    (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (e && e.preventDefault) {
        e.preventDefault()
      }

      if (textFieldRef.current) {
        textFieldRef.current.blur()
      }

      if (defaultSearchValue) {
        if (isSearching) {
          setSearchValue(defaultSearchValue)
          setIsSearching(false)
        } else {
          navigateBack({ defaultParentUrl: `/` })
        }
      } else {
        setSearchValue(``)
        setIsSearching(false)
      }
      if (onClose) {
        onClose()
      }
    },
    [setSearchValue, onClose, setIsSearching, defaultSearchValue, isSearching],
  )

  const onPopState = React.useCallback(() => {
    if (isSearching) {
      onCloseSearchPanel()

      return true
    }
    return false
  }, [isSearching, onCloseSearchPanel])

  useOnPopState(isSearching, onPopState)

  const onFocusChange = React.useCallback(
    (focused: boolean) => {
      if (focused) {
        setIsSearching(true)
      } else if (!renderCloseButton && !searchValue) {
        setIsSearching(false)
      }
    },
    [setIsSearching, renderCloseButton, searchValue],
  )

  const clearTextButton = React.useMemo(() => {
    return (
      <button
        type="button"
        className={`${styles.clearTextButton} ${
          !trimmedSearchValue ? styles.hidden : ``
        }`}
        onClick={() => {
          setSearchValue(``)
          textFieldRef?.current?.focus()
        }}
      >
        <DeleteIcon />
      </button>
    )
  }, [setSearchValue, trimmedSearchValue])

  const searchButton = React.useMemo(() => {
    const element = `검색`

    if (!targetURI && !isFullSSR) {
      return null
    }
    return (
      // way to disable?
      <button
        type="submit"
        form={formId} // should match the form's id
        className={`${styles.searchButton} t`}
        aria-label="검색하기"
        onClick={(e) => {
          if (defaultSearchValue === searchValue) {
            e.preventDefault()
          }
        }}
      >
        {element}
      </button>
    )
  }, [targetURI, isFullSSR, formId, defaultSearchValue, searchValue])

  const collapse = !isSearching

  const closeButton = React.useMemo(() => {
    let renderLogoInstead = false
    if (
      ((isFullSSR || !getInnerNavigated()) &&
        searchBarContainerType === `search`) ||
      (isFullSSR && searchBarContainerType === `home`)
    ) {
      renderLogoInstead = true
    }

    if (!renderCloseButton) {
      return null
    }
    return (
      <Link
        to="/"
        className={styles.closeButton}
        onClick={onCloseSearchPanel}
        aria-label="뒤로 가기"
      >
        {renderLogoInstead ? (
          <LogoIcon
            alt="루트 - 수수료 없는 진짜 농수산물 직거래 플랫폼"
            width="94px"
            height="25px"
          />
        ) : (
          <BackIcon />
        )}
      </Link>
    )
  }, [isFullSSR, searchBarContainerType, onCloseSearchPanel, renderCloseButton])

  const searchBar = React.useMemo(() => {
    return (
      <section
        role="search"
        className={`${styles.searchBarContainer} ${className || ``}`}
      >
        {isSearching || defaultSearchValue || (onClose && !isFullSSR)
          ? closeButton
          : null}
        <Form
          className={styles.searchFieldContainer}
          onSubmit={handleFormSubmit}
          autoComplete="off"
          id={formId}
          name="search"
          method="get"
          action="/search"
        >
          <TextField
            name="text"
            ref={textFieldComposedRef}
            id={`use-search-bar-${formId}-content-search-text`}
            className={styles.searchField}
            label="루트에서 검색"
            placeholder={isFullSSR ? `루트에서 검색` : ``}
            value={searchValue || ``}
            onChange={setSearchValue}
            onFocusChange={onFocusChange}
            disableLabelPin
            rightAdornment={clearTextButton}
            option="narrow"
          />
        </Form>
        {isFullSSR || isSearching || defaultSearchValue ? searchButton : null}
      </section>
    )
  }, [
    formId,
    onClose,
    closeButton,
    searchButton,
    clearTextButton,
    isSearching,
    handleFormSubmit,
    searchValue,
    setSearchValue,
    onFocusChange,
    defaultSearchValue,
    isFullSSR,
    textFieldComposedRef,
    className,
  ])

  return [searchBar, collapse]
}
