import React from 'react'
import { routeIdListener } from 'util/url'

const RouteIdContext = React.createContext<number>(routeIdListener.getValue())

export const RouteIdProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [routeId, setRouteId] = React.useState<number>(
    routeIdListener.getValue(),
  )

  React.useEffect(() => {
    return routeIdListener.addListener(setRouteId)
  }, [])

  return (
    <RouteIdContext.Provider value={routeId}>
      {children}
    </RouteIdContext.Provider>
  )
}

export const useRouteId = () => {
  return React.useContext(RouteIdContext)
}
