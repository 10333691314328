import React from 'react'

import { useIsSSR } from 'hooks/use-is-ssr'
import { hookRefreshesWith } from 'util/hook-refreshes-with'
import type { ParsedLocation } from './types'
import { defineSimpleTextReducer } from './reducers/simple-query'
import { AnchorProvider } from './reducers/anchor'
import { StateProvider } from './reducers/state'
import { PaymentQueryProvider } from './reducers/payment-query'
import { PathnameSearchProvider } from './reducers/pathname-search'
import { PathnameProvider } from './reducers/pathname'
import {
  replacedQueryListener,
  replacedAnchorListener,
  replacedStateListener,
  getState,
  parseQuery,
} from '../../util/url'

const defaultParsedLocation = {
  query: {},
  search: ``,
  hash: ``,
  pathname: ``,
  state: {},
}

export const ParsedLocationContext = React.createContext<ParsedLocation>(
  defaultParsedLocation,
)

let locationReadValue: ParsedLocation = defaultParsedLocation

export const getLocation = () => {
  return locationReadValue
}

const providers = [
  AnchorProvider,
  StateProvider,
  PaymentQueryProvider,
  PathnameSearchProvider,
  PathnameProvider,
] // + simpleText
const useContexts: Record<string, () => string> = {}

const simpleTextQueries = [
  `comment`,
  `type`,
  `target`,
  `location`,
  `text`,
  `user`,
  `post`,
  `product`,
  `context`,
  `title`,
  `anchor`,
  `reward-code`, // reward-code
  `label`, // administrator
  `s`, // sessionId
  `overlaySlug`, // overlay
]

simpleTextQueries.forEach((key) => {
  const { Provider, useContext } = defineSimpleTextReducer(key)
  providers.push(Provider)
  useContexts[key] = useContext
})

export const useQuery = (key: string) => {
  const useContext = useContexts[key]
  if (!useContext) {
    throw new Error(`use query provider ${key} not found!`)
  }

  return useContext()
}

const renderReducerProviders = (
  location: ParsedLocation,
  _children: React.ReactNode,
) => {
  let children = _children
  for (let i = providers.length - 1; i >= 0; i -= 1) {
    const Provider = providers[i]

    children = <Provider location={location}>{children}</Provider>
  }
  return children
}

export const useParsedLocation = () => {
  return React.useContext(ParsedLocationContext)
}

export const LocationProvider = ({
  location,
  children,
}: {
  location: Location
  children: React.ReactNode
}) => {
  const isSSR = useIsSSR()

  const updateCounterBox = React.useRef<number>(0)

  const locationUpdatedCounterBox = React.useRef<number>(0)
  const replacedQueryUpdatedCounterBox = React.useRef<number>(0)
  const replacedAnchorUpdatedCounterBox = React.useRef<number>(0)
  const replacedStateUpdatedCounterBox = React.useRef<number>(0)

  const [replacedQuery, setReplacedQuery] = React.useState(
    replacedQueryListener.getValue(),
  )
  const [replacedAnchor, setReplacedAnchor] = React.useState(
    replacedAnchorListener.getValue(),
  )
  const [replacedState, setReplacedState] = React.useState(
    replacedStateListener.getValue(),
  )

  React.useEffect(() => {
    return replacedQueryListener.addListener((query) => {
      updateCounterBox.current += 1
      replacedQueryUpdatedCounterBox.current = updateCounterBox.current
      setReplacedQuery(query)
    })
  }, [])

  React.useEffect(() => {
    return replacedAnchorListener.addListener((anchor) => {
      updateCounterBox.current += 1
      replacedAnchorUpdatedCounterBox.current = updateCounterBox.current
      setReplacedAnchor(anchor)
    })
  }, [])

  React.useEffect(() => {
    return replacedStateListener.addListener((state) => {
      updateCounterBox.current += 1
      replacedStateUpdatedCounterBox.current = updateCounterBox.current
      setReplacedState(state)
    })
  }, [])

  React.useMemo(() => {
    updateCounterBox.current += 1
    locationUpdatedCounterBox.current = updateCounterBox.current
    hookRefreshesWith(location)
  }, [location])

  /*
    query: Record<string, string>
    hash: string
    pathname: string
    state: Navigation.State


    change triggers : 
      query : url.replaceQuery
      hash : url.replaceAnchor
      state : url.updateState
      
      all + pathname : location change
  */
  const parsedLocation = React.useMemo<ParsedLocation>(() => {
    const anchor = isSSR
      ? ``
      : locationUpdatedCounterBox.current >=
        replacedAnchorUpdatedCounterBox.current
      ? location?.hash || ``
      : replacedAnchor

    const state = isSSR
      ? null
      : locationUpdatedCounterBox.current >=
        replacedStateUpdatedCounterBox.current
      ? getState()
      : replacedState

    const search = isSSR
      ? ``
      : locationUpdatedCounterBox.current >=
        replacedQueryUpdatedCounterBox.current
      ? location?.search || ``
      : replacedQuery

    const pathname = location?.pathname || `` // pathname should exist in any circumstances tho.
    if (!pathname) {
      console.error(`pathname not defined!!! problem!!`)
    }

    const parsedQuery = parseQuery(search)

    return {
      search,
      query: parsedQuery,
      hash: anchor,
      state,
      pathname,
    }
    // consume location
  }, [isSSR, location, replacedAnchor, replacedState, replacedQuery])

  React.useMemo(() => {
    locationReadValue = parsedLocation
  }, [parsedLocation])

  return (
    <ParsedLocationContext.Provider value={parsedLocation}>
      {renderReducerProviders(parsedLocation, children)}
    </ParsedLocationContext.Provider>
  )
}
