import React from 'react'
import { select } from 'util/select'
import { useData } from 'hooks/use-data'
import { Link } from 'components/link'
import { useThrottledRealTimeSearchValue } from 'hooks/use-throttled-real-time-search-value'
import { HorizontalScrollButtonBlock } from 'components/horizontal-scroll-button-block'
import { useStateRef } from 'hooks/use-state-ref'
import { hookRefreshesWith } from 'util/hook-refreshes-with'

import * as styles from './style.module.less'

const countMatchingCharacters = (a: string, b: string) => {
  let count = 0
  for (let i = 0; i < b.length; i += 1) {
    const character = b[i]
    if (a.includes(character)) {
      count += 1
    }
  }

  return count
}

export const SearchHelperCategoryList = ({
  searchValue,
  onLoadComplete,
}: {
  searchValue: string
  onLoadComplete: (valueExists: boolean) => void
}) => {
  const throttledSearchValue = useThrottledRealTimeSearchValue(searchValue)
  const [categoryList, categoryListLoadingState] = useData<
    Label.PopulatedCategory[]
  >(
    throttledSearchValue
      ? `/search/v2/categories-by-name?text=${encodeURIComponent(
          throttledSearchValue,
        )}`
      : null,
    {
      cacheReadConsistency: `session`,
    },
  )
  const categoriesElement = React.useMemo(() => {
    if (!categoryList || categoryList.length === 0) {
      return null
    }

    return categoryList.map((category) => {
      const [id, name, extraNames = []]: [string, string, string[]] = select(
        category,
        [`id`, `public.display.name`, `public.display.extraNames`],
      )

      let selectedName = name
      let currentMaxMatchingCharacters = countMatchingCharacters(
        throttledSearchValue,
        name,
      )
      for (let i = 0; i < extraNames.length; i += 1) {
        const extraName = extraNames[i]
        const matchingCharacters = countMatchingCharacters(
          throttledSearchValue,
          extraName,
        )
        if (matchingCharacters > currentMaxMatchingCharacters) {
          selectedName = extraName
          currentMaxMatchingCharacters = matchingCharacters
        }
      }

      return (
        <Link
          className={`${styles.categoryCard} t6`}
          to={`/search?text=${encodeURIComponent(name)}`}
          aria-label={`${name} 검색결과 보기`}
          key={id}
        >
          {selectedName}
        </Link>
      )
    })
  }, [categoryList, throttledSearchValue])

  const horizontalRef = useStateRef<HTMLUListElement>(null)

  const loadCompleteConsumedBox = React.useRef(false)
  React.useMemo(() => {
    hookRefreshesWith(searchValue)
    loadCompleteConsumedBox.current = false
  }, [searchValue])
  React.useEffect(() => {
    if (
      searchValue === throttledSearchValue &&
      categoryListLoadingState !== `empty` &&
      categoryListLoadingState !== `loading`
    ) {
      onLoadComplete(!!(categoryList && categoryList.length > 0))
    }
  }, [
    categoryListLoadingState,
    categoryList,
    throttledSearchValue,
    searchValue,
    onLoadComplete,
  ])

  if (!searchValue) {
    return null
  }

  if (!categoriesElement) {
    return null
  }

  return (
    <section>
      <div className={`${styles.categoryTitle} t6`}>카테고리 자동완성</div>
      <HorizontalScrollButtonBlock
        listRef={horizontalRef}
        elementWidth={220}
        refreshAlways
        overlay
      />
      <ul className={styles.categoryList} ref={horizontalRef}>
        {categoriesElement}
      </ul>
    </section>
  )
}
