exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activities-index-tsx": () => import("./../../../src/pages/activities/index.tsx" /* webpackChunkName: "component---src-pages-activities-index-tsx" */),
  "component---src-pages-administrator-index-tsx": () => import("./../../../src/pages/administrator/index.tsx" /* webpackChunkName: "component---src-pages-administrator-index-tsx" */),
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-browser-not-supported-index-tsx": () => import("./../../../src/pages/browser-not-supported/index.tsx" /* webpackChunkName: "component---src-pages-browser-not-supported-index-tsx" */),
  "component---src-pages-contact-editor-index-tsx": () => import("./../../../src/pages/contact-editor/index.tsx" /* webpackChunkName: "component---src-pages-contact-editor-index-tsx" */),
  "component---src-pages-featured-editor-index-tsx": () => import("./../../../src/pages/featured-editor/index.tsx" /* webpackChunkName: "component---src-pages-featured-editor-index-tsx" */),
  "component---src-pages-global-discount-editor-index-tsx": () => import("./../../../src/pages/global-discount-editor/index.tsx" /* webpackChunkName: "component---src-pages-global-discount-editor-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-index-tsx": () => import("./../../../src/pages/join/index.tsx" /* webpackChunkName: "component---src-pages-join-index-tsx" */),
  "component---src-pages-label-editor-index-tsx": () => import("./../../../src/pages/label-editor/index.tsx" /* webpackChunkName: "component---src-pages-label-editor-index-tsx" */),
  "component---src-pages-me-edit-introduction-tsx": () => import("./../../../src/pages/me/edit-introduction.tsx" /* webpackChunkName: "component---src-pages-me-edit-introduction-tsx" */),
  "component---src-pages-me-edit-name-tsx": () => import("./../../../src/pages/me/edit-name.tsx" /* webpackChunkName: "component---src-pages-me-edit-name-tsx" */),
  "component---src-pages-me-edit-profile-image-tsx": () => import("./../../../src/pages/me/edit-profile-image.tsx" /* webpackChunkName: "component---src-pages-me-edit-profile-image-tsx" */),
  "component---src-pages-me-index-tsx": () => import("./../../../src/pages/me/index.tsx" /* webpackChunkName: "component---src-pages-me-index-tsx" */),
  "component---src-pages-orders-index-tsx": () => import("./../../../src/pages/orders/index.tsx" /* webpackChunkName: "component---src-pages-orders-index-tsx" */),
  "component---src-pages-p-create-tsx": () => import("./../../../src/pages/p/create.tsx" /* webpackChunkName: "component---src-pages-p-create-tsx" */),
  "component---src-pages-p-edit-tsx": () => import("./../../../src/pages/p/edit.tsx" /* webpackChunkName: "component---src-pages-p-edit-tsx" */),
  "component---src-pages-p-index-tsx": () => import("./../../../src/pages/p/index.tsx" /* webpackChunkName: "component---src-pages-p-index-tsx" */),
  "component---src-pages-post-list-index-tsx": () => import("./../../../src/pages/post-list/index.tsx" /* webpackChunkName: "component---src-pages-post-list-index-tsx" */),
  "component---src-pages-pr-index-tsx": () => import("./../../../src/pages/pr/index.tsx" /* webpackChunkName: "component---src-pages-pr-index-tsx" */),
  "component---src-pages-push-notification-sender-index-tsx": () => import("./../../../src/pages/push-notification-sender/index.tsx" /* webpackChunkName: "component---src-pages-push-notification-sender-index-tsx" */),
  "component---src-pages-review-override-editor-index-tsx": () => import("./../../../src/pages/review-override-editor/index.tsx" /* webpackChunkName: "component---src-pages-review-override-editor-index-tsx" */),
  "component---src-pages-reward-code-editor-index-tsx": () => import("./../../../src/pages/reward-code-editor/index.tsx" /* webpackChunkName: "component---src-pages-reward-code-editor-index-tsx" */),
  "component---src-pages-sales-all-index-tsx": () => import("./../../../src/pages/sales/all/index.tsx" /* webpackChunkName: "component---src-pages-sales-all-index-tsx" */),
  "component---src-pages-sales-cancel-requested-index-tsx": () => import("./../../../src/pages/sales/cancel-requested/index.tsx" /* webpackChunkName: "component---src-pages-sales-cancel-requested-index-tsx" */),
  "component---src-pages-sales-canceled-index-tsx": () => import("./../../../src/pages/sales/canceled/index.tsx" /* webpackChunkName: "component---src-pages-sales-canceled-index-tsx" */),
  "component---src-pages-sales-delivered-index-tsx": () => import("./../../../src/pages/sales/delivered/index.tsx" /* webpackChunkName: "component---src-pages-sales-delivered-index-tsx" */),
  "component---src-pages-sales-new-index-tsx": () => import("./../../../src/pages/sales/new/index.tsx" /* webpackChunkName: "component---src-pages-sales-new-index-tsx" */),
  "component---src-pages-sales-pre-confirm-canceled-index-tsx": () => import("./../../../src/pages/sales/pre-confirm-canceled/index.tsx" /* webpackChunkName: "component---src-pages-sales-pre-confirm-canceled-index-tsx" */),
  "component---src-pages-sales-shipped-direct-other-provider-index-tsx": () => import("./../../../src/pages/sales/shipped-direct-other-provider/index.tsx" /* webpackChunkName: "component---src-pages-sales-shipped-direct-other-provider-index-tsx" */),
  "component---src-pages-sales-shipped-index-tsx": () => import("./../../../src/pages/sales/shipped/index.tsx" /* webpackChunkName: "component---src-pages-sales-shipped-index-tsx" */),
  "component---src-pages-sales-shipping-cancel-requested-index-tsx": () => import("./../../../src/pages/sales/shipping-cancel-requested/index.tsx" /* webpackChunkName: "component---src-pages-sales-shipping-cancel-requested-index-tsx" */),
  "component---src-pages-sales-shipping-index-tsx": () => import("./../../../src/pages/sales/shipping/index.tsx" /* webpackChunkName: "component---src-pages-sales-shipping-index-tsx" */),
  "component---src-pages-sales-untrackable-index-tsx": () => import("./../../../src/pages/sales/untrackable/index.tsx" /* webpackChunkName: "component---src-pages-sales-untrackable-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-pages-shopping-index-tsx": () => import("./../../../src/pages/shopping/index.tsx" /* webpackChunkName: "component---src-pages-shopping-index-tsx" */),
  "component---src-pages-ssr-index-tsx": () => import("./../../../src/pages/ssr/index.tsx" /* webpackChunkName: "component---src-pages-ssr-index-tsx" */),
  "component---src-pages-ssr-p-index-tsx": () => import("./../../../src/pages/ssr/p/index.tsx" /* webpackChunkName: "component---src-pages-ssr-p-index-tsx" */),
  "component---src-pages-ssr-post-list-index-tsx": () => import("./../../../src/pages/ssr/post-list/index.tsx" /* webpackChunkName: "component---src-pages-ssr-post-list-index-tsx" */),
  "component---src-pages-ssr-pr-index-tsx": () => import("./../../../src/pages/ssr/pr/index.tsx" /* webpackChunkName: "component---src-pages-ssr-pr-index-tsx" */),
  "component---src-pages-ssr-search-index-tsx": () => import("./../../../src/pages/ssr/search/index.tsx" /* webpackChunkName: "component---src-pages-ssr-search-index-tsx" */),
  "component---src-pages-ssr-shopping-index-tsx": () => import("./../../../src/pages/ssr/shopping/index.tsx" /* webpackChunkName: "component---src-pages-ssr-shopping-index-tsx" */),
  "component---src-pages-ssr-story-index-tsx": () => import("./../../../src/pages/ssr/story/index.tsx" /* webpackChunkName: "component---src-pages-ssr-story-index-tsx" */),
  "component---src-pages-ssr-u-index-tsx": () => import("./../../../src/pages/ssr/u/index.tsx" /* webpackChunkName: "component---src-pages-ssr-u-index-tsx" */),
  "component---src-pages-story-index-tsx": () => import("./../../../src/pages/story/index.tsx" /* webpackChunkName: "component---src-pages-story-index-tsx" */),
  "component---src-pages-u-index-tsx": () => import("./../../../src/pages/u/index.tsx" /* webpackChunkName: "component---src-pages-u-index-tsx" */)
}

