import { Listener } from 'util/listener'

export type QueryString = string

export type QueryDict = {
  [key: string]: string
}

// inherit
export interface State {
  [key: string]: any
}

export const getCurrentPath = () => {
  if (typeof window !== `undefined`) {
    const originReplacedString =
      window?.location?.href.replace(window?.location?.origin, ``) || ``
    const [hashRemovedString] = originReplacedString.split(`#`)
    return hashRemovedString
  }
  return ``
}

export const joinPath = (front: string, back: string) => {
  return `${front.replace(/\/$/, ``)}/${back.replace(/^\//, ``)}`
}

export const joinQueryParam = (_url: string, param: string) => {
  let url = _url
  if (url.indexOf(`?`) !== -1) {
    if (url[url.length - 1] !== `&`) {
      url += `&`
    }
  } else {
    url += `?`
  }

  url += param
  return url
}

export const reload = () => {
  if (typeof window !== `undefined`) {
    window.location.reload()
  }
}

// TODO why this exists? why not just use tge URLSearchParams?
export const parseQuery = (queryString: QueryString | null): QueryDict => {
  if (!queryString) {
    return {}
  }

  const query: QueryDict = {}
  ;[...new URLSearchParams(queryString).entries()].forEach(([k, v]) => {
    query[k] = v
  })

  return query
}

/*
  TODO need to throttle replaceStates. target limit is 100 in 30 sec. 333ms per replace.


*/

let currentState: State | null = null
let currentRouteId = -1
type TimedFunctionHandle = number | null
const stateFlushCooldown = 500
let stateFlushDebounceHandle: TimedFunctionHandle = null

const clearStateFlushDebounceHandle = () => {
  if (stateFlushDebounceHandle !== null) {
    window.clearTimeout(stateFlushDebounceHandle)
  }
  stateFlushDebounceHandle = null
}

export const routeIdListener = new Listener<number>(currentRouteId)

// route changed! drop current state changes.
export const onRouteChange = (routeId: number) => {
  currentState = null
  currentRouteId = routeId
  clearStateFlushDebounceHandle()
  routeIdListener.setValue(routeId)
}

export const getState = (): State => {
  if (currentState === null) {
    if (typeof window !== `undefined`) {
      const windowState = window?.history?.state
      if (windowState) {
        currentState = windowState
      } else {
        currentState = {}
      }
    } else {
      currentState = {}
    }
  }
  return currentState as State
}

export const pushState = (object: State, dummy: string, url?: string) => {
  if (typeof window !== `undefined`) {
    // save previous state
    stateFlushDebounceHandle = null
    window.history.replaceState(getState(), ``)
    //
    currentState = null
    window.history.pushState(object, dummy, url)
  }
}

let defaultSearch = ``
let defaultAnchor = ``
if (typeof window !== `undefined`) {
  defaultSearch = window?.location?.search || ``
  defaultAnchor = window?.location?.hash || ``
}
export const replacedQueryListener = new Listener<QueryString>(defaultSearch)

export const replacedAnchorListener = new Listener<string>(defaultAnchor)

export const replacedStateListener = new Listener<State | null>(getState())

/*
  Not good design :)
*/
// last state before flush is gone :)
const registerStateFlush = () => {
  const routeIdOnFlushRequest = currentRouteId
  if (stateFlushDebounceHandle === null) {
    // register
    stateFlushDebounceHandle = window.setTimeout(() => {
      const routeIdOnFlushCallback = currentRouteId
      if (routeIdOnFlushRequest === routeIdOnFlushCallback) {
        // ready to flush~!
        stateFlushDebounceHandle = null
        window.history.replaceState(getState(), ``)
      }
    }, stateFlushCooldown)
  }
}

/*  
  if direct set, write right away instead of debouncing.
*/
export const updateState = (object: State, direct = false) => {
  currentState = {
    ...getState(),
    ...object,
  }

  if (!direct) {
    replacedStateListener.setValue(getState())
  }

  if (typeof window !== `undefined`) {
    if (direct) {
      window.history.replaceState(getState(), ``)
    } else {
      registerStateFlush()
    }
  }
}

/*
  anchors : same as query. replace only if changes.
*/
export const replaceAnchor = (anchor: string) => {
  const targetHash = anchor ? `#${anchor}` : ``
  const url = new URL(window.location.href)
  if (url.hash !== targetHash) {
    url.hash = targetHash

    clearStateFlushDebounceHandle()

    window.history.replaceState(getState(), ``, url.href)
  }

  replacedAnchorListener.setValue(targetHash)
}

/*
  Change only if change exists :)
*/
export const replaceQuery = (
  keyOrKeysObject: string | Record<string, string | undefined>,
  value?: string,
) => {
  const url = new URL(window.location.href)

  let keysObject = keyOrKeysObject
  if (typeof keyOrKeysObject === `string`) {
    keysObject = { [keyOrKeysObject]: value }
  }

  let changed = false
  Object.entries(keysObject).forEach(([k, v]) => {
    const currentValue = url.searchParams.get(k)

    if (typeof v === `undefined` || v === null) {
      // need to delete!
      if (typeof currentValue !== `undefined` && typeof currentValue !== null) {
        url.searchParams.delete(k)
        changed = true
      }
    } else if (currentValue !== v) {
      url.searchParams.set(k, v)
      changed = true
    }
  })

  if (changed) {
    clearStateFlushDebounceHandle()
    window.history.replaceState(getState(), ``, url.href)
    replacedQueryListener.setValue(window?.location?.search || ``)
  }
}
