// extracted by mini-css-extract-plugin
export var desktop = "by_nf";
export var homeLink = "by_nd";
export var leftContainer = "by_bT";
export var logoIconLink = "by_c";
export var rightContainer = "by_bV";
export var rootContainer = "by_b";
export var search = "by_bW";
export var searchHelperPanelAnchor = "by_nh";
export var selected = "by_b4";
export var signinBlock = "by_ng";
export var topContainer = "by_bR";
export var topNavigationBarContainerPaddingDummy = "by_nc";
export var widthWrapper = "by_bQ";