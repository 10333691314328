import { request } from 'util/request'
import deploymentConfig from 'values/deployment.json'

const { apiServerHost } = deploymentConfig

export type RewardCodeSubmitResultCode =
  | `CODE_NOT_FOUND`
  | `ALREADY_CONSUMED`
  | `EXPIRED`
  | `DISABLED`
  | `SUCCESS`

// compatibility
export type RewardCodeSubmitResult =
  | RewardCodeSubmitResultCode
  | {
      code: RewardCodeSubmitResultCode
      message?: string
    }

const getMessageFromCode = (code: string) => {
  let message = `문제가 발생했어요!`

  switch (code) {
    case `CODE_NOT_FOUND`:
      message = `잘못된 코드입니다`
      break
    case `ALREADY_CONSUMED`:
      message = `이미 사용된 코드입니다`
      break
    case `EXPIRED`:
      message = `만료된 코드입니다`
      break
    case `DISABLED`:
      message = `비활성화된 코드입니다`
      break
    case `SUCCESS`:
      message = `코드 사용 승인되었습니다`
      break
    default:
      message = `문제가 발생했어요!`
  }
  return message
}

export const submitRewardCode = async (code: string) => {
  const res = await request<RewardCodeSubmitResult>(
    `POST`,
    apiServerHost,
    `/reward-code/submit`,
    {
      auth: true,
      data: {
        code,
      },
    },
  )

  if (typeof res.body === `string`) {
    const code = res.body

    return {
      code,
      message: getMessageFromCode(code),
    }
  }

  if (!res.body.message) {
    return {
      code: res.body.code,
      message: getMessageFromCode(res.body.code),
    }
  }

  return res.body
}
