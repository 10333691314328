import React from 'react'
import { useThrottledEvent } from 'hooks/use-throttled-event'
import { useIsSSR } from 'hooks/use-is-ssr'
import { useGetter } from 'hooks/use-getter'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { useStateRef } from 'hooks/use-state-ref'
import { useSynchronousState } from 'hooks/use-synchronous-state'
import { updateState, getState } from 'util/url'
import { getRelativeOffset } from 'util/scroll'
import { usePathnameSearch } from 'hooks/use-location'
import { hookRefreshesWith } from 'util/hook-refreshes-with'

type TryScrollRestore = () => void
type TimedFunctionHandle = number | undefined

export interface ScrollAnchorHandle {
  key: string
  getIsLoadComplete: () => boolean
  focusElementRefBox: React.MutableRefObject<HTMLElement | null>
}

let anchors: ScrollAnchorHandle[] | null = null
let anchorSetRouteUpdatedAt = -1

let _tryScrollRestore: TryScrollRestore | null = null

if (typeof window !== `undefined` && window.history.scrollRestoration) {
  window.history.scrollRestoration = `manual`
}

let waitLoadHandle: TimedFunctionHandle

let disableScrollConsumerRouteUpdatedAt = -1

const getWindowRouteUpdatedAt = () => {
  if (typeof window !== `undefined`) {
    return window.routeUpdatedAt || 0
  }
  return 0
}

/*
  disable scroll restore based on sections.
  1. vertical infinite lists already restores current position based on anchor.
  2. home / search might..? because content height static and loading slow, scroll jumping might be a bad experience.
*/
export const useDisableScrollConsumer = () => {
  React.useMemo(() => {
    disableScrollConsumerRouteUpdatedAt = getWindowRouteUpdatedAt()
  }, [])
}
const getDisableScrollConsumer = () => {
  return getWindowRouteUpdatedAt() === disableScrollConsumerRouteUpdatedAt
}

export const useScrollConsumer = () => {
  const pathnameSearch = usePathnameSearch()
  const isSSR = useIsSSR()
  const getIsSSR = useGetter(isSSR)
  const getIsMounted = useGetIsMounted()
  const [isScrollRestoredBox, setIsScrollRestored] =
    useSynchronousState<boolean>(false)
  const [renderedRouteUpdatedAtBox, setRenderedRouteUpdatedAt] =
    useSynchronousState<number>(-1)
  const renderedRouteUpdatedAt = renderedRouteUpdatedAtBox.current
  // const scrollCountBeforeRenderBox = React.useRef(0)

  React.useMemo(() => {
    hookRefreshesWith(pathnameSearch)
    // reset values on route change
    if (getWindowRouteUpdatedAt() !== renderedRouteUpdatedAtBox.current) {
      setRenderedRouteUpdatedAt(getWindowRouteUpdatedAt(), true)
      setIsScrollRestored(false, true)
      // scrollCountBeforeRenderBox.current = 0
    }
  }, [
    pathnameSearch,
    renderedRouteUpdatedAtBox,
    setRenderedRouteUpdatedAt,
    setIsScrollRestored,
  ])

  const tryScrollRestore = React.useCallback<TryScrollRestore>(() => {
    if (isScrollRestoredBox.current) {
      return
    }

    if (getDisableScrollConsumer()) {
      return
    }

    if (getIsMounted()) {
      let isLoadComplete = true
      if (!anchors) {
        return
      }

      // if all anchors loaded and ready
      anchors.forEach(({ getIsLoadComplete }) => {
        if (!getIsLoadComplete()) {
          isLoadComplete = false
        }
      })
      if (isLoadComplete) {
        const scrollAnchor = getState().scrollAnchor
        const scrollAnchorOffset = getState().scrollAnchorOffset || 0

        // find anchor that was last focused
        const handle = anchors.find((e) => e.key === scrollAnchor)
        if (waitLoadHandle) {
          window.clearTimeout(waitLoadHandle)
        }

        // if anchor that was last focused present
        if (handle) {
          // scroll into the ref
          const refBox = handle.focusElementRefBox
          if (refBox.current) {
            refBox.current.scrollIntoView()
          }
          // scroll by offset inside the anchor
          window.scrollBy(0, scrollAnchorOffset)
          isScrollRestoredBox.current = true
        } else if (!scrollAnchor) {
          // if no anchor present! might be topmost scroll.
          // just scroll to offset
          window.scrollTo(0, scrollAnchorOffset)
          isScrollRestoredBox.current = true
        } else {
          // no scroll anchor. wait.
          // need timeout..?

          // clear anchor, set current position as anchor and start recording.
          waitLoadHandle = window.setTimeout(() => {
            if (getIsMounted() && !isScrollRestoredBox.current) {
              updateState({
                scrollAnchor: null,
                scrollAnchorOffset: window.scrollY,
              })
              isScrollRestoredBox.current = true
            }
          }, 2000)
        }
      }
    }
  }, [getIsMounted, isScrollRestoredBox])

  React.useMemo(() => {
    _tryScrollRestore = tryScrollRestore
  }, [tryScrollRestore])

  React.useEffect(() => {
    hookRefreshesWith(renderedRouteUpdatedAt)
    if (!isSSR) {
      const handle = window.setTimeout(() => {
        if (getIsMounted() && !isScrollRestoredBox.current) {
          tryScrollRestore()
        }
      }, 300)
      return () => {
        window.clearTimeout(handle)
      }
    }
  }, [
    isSSR,
    tryScrollRestore,
    getIsMounted,
    isScrollRestoredBox,
    renderedRouteUpdatedAt,
  ])

  /*
    Saves anchor position
  */
  const onScroll = React.useCallback(() => {
    if (getIsSSR()) {
      return
    }

    if (getDisableScrollConsumer()) {
      return
    }
    // set current scroll
    // get last anchor that's past point
    // replace state if current state is not it
    if (getIsMounted()) {
      if (isScrollRestoredBox.current) {
        // do scroll
        const currentScroll = window.scrollY
        let closestScrollPosition = 0
        let closestScrollKey: string | null = null
        if (anchors) {
          anchors.forEach(({ focusElementRefBox, key }) => {
            const element = focusElementRefBox.current
            if (element) {
              const offset = getRelativeOffset(element)
              if (offset) {
                const offsetTop = offset[1]
                if (offsetTop <= currentScroll + 50) {
                  if (!closestScrollKey || offsetTop > closestScrollPosition) {
                    closestScrollPosition = offsetTop
                    closestScrollKey = key
                  }
                }
              }
            }
          })

          if (closestScrollKey) {
            updateState({
              scrollAnchor: closestScrollKey,
              scrollAnchorOffset: currentScroll - closestScrollPosition,
            })
          } else {
            updateState({
              scrollAnchor: null,
              scrollAnchorOffset: currentScroll,
            })
          }
        }
      }
      /* 
      else {
        // scroll not restored! detect scroll.
        scrollCountBeforeRenderBox.current += 1
        console.log(scrollCountBeforeRenderBox.current)
        if (scrollCountBeforeRenderBox.current > 4) {
          console.log(`user scrolled before restore. skipping restoration.`)
          isScrollRestoredBox.current = true
        }
      }
      */
    }
  }, [getIsMounted, getIsSSR, isScrollRestoredBox])

  /*
    TODO optimize : 
    - use intersectionObserver
  */
  useThrottledEvent(`scroll`, onScroll, isSSR ? null : window, 300, {
    passive: true,
  })
}

export const useScrollAnchor = <T extends HTMLElement>(
  key: string,
  _isLoadComplete: boolean,
) => {
  const pathnameSearch = usePathnameSearch()
  /*
        1. register anchor!
        2. provide 
    */
  const isSSR = useIsSSR()

  // states
  const focusElementRefBox = useStateRef<T>(null)
  const focusElement = focusElementRefBox.current

  const [isScrollRestoreTriggeredBox, setIsScrollRestoreTriggered] =
    useSynchronousState<boolean>(false)
  const [isLoadCompleteBox, setIsLoadComplete] = useSynchronousState(false)
  const isLoadComplete = isLoadCompleteBox.current

  const [renderedRouteUpdatedAtBox, setRenderedRouteUpdatedAt] =
    useSynchronousState<number>(-2)
  const renderedRouteUpdatedAt = renderedRouteUpdatedAtBox.current

  React.useMemo(() => {
    // reset values on route change
    if (getWindowRouteUpdatedAt() !== renderedRouteUpdatedAtBox.current) {
      setRenderedRouteUpdatedAt(getWindowRouteUpdatedAt(), true)
      setIsScrollRestoreTriggered(false, true)
      setIsLoadComplete(false, true)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    pathnameSearch,
    renderedRouteUpdatedAtBox,
    setRenderedRouteUpdatedAt,
    setIsLoadComplete,
  ])

  React.useEffect(() => {
    hookRefreshesWith(renderedRouteUpdatedAt)
    if (_isLoadComplete) {
      setIsLoadComplete(_isLoadComplete)
    }
  }, [_isLoadComplete, setIsLoadComplete, renderedRouteUpdatedAt])

  const getIsLoadComplete = useGetter(isLoadComplete)

  React.useMemo(() => {
    // register anchor
    const handle = {
      key,
      getIsLoadComplete,
      focusElementRefBox,
    }

    if (anchorSetRouteUpdatedAt === getWindowRouteUpdatedAt() && anchors) {
      anchors.push(handle)
    } else {
      anchors = [handle]
      anchorSetRouteUpdatedAt = getWindowRouteUpdatedAt()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [key, getIsLoadComplete, focusElementRefBox, renderedRouteUpdatedAt])

  React.useEffect(() => {
    return () => {
      if (anchors) {
        const index = anchors.findIndex((e) => e.key === key)
        if (index !== -1) {
          anchors.splice(index, 1)
        }
      }
    }
  }, [key, renderedRouteUpdatedAt])

  React.useEffect(() => {
    // trigger scroll restore on load complete
    if (
      !isSSR &&
      !isScrollRestoreTriggeredBox.current &&
      isLoadComplete &&
      focusElement
    ) {
      isScrollRestoreTriggeredBox.current = true
      if (_tryScrollRestore) {
        _tryScrollRestore()
      } else {
        console.warn(`try restore was undefined`)
      }
    }
  }, [key, isLoadComplete, isSSR, isScrollRestoreTriggeredBox, focusElement])

  return focusElementRefBox
}
