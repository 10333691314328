import { request } from 'util/request'
import deploymentConfig from 'values/deployment.json'
import { PushNotifications } from '@capacitor/push-notifications'
import { Preferences } from '@capacitor/preferences'
import { createUuidSecure } from 'util/uuid'
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from 'capacitor-native-settings'
import { checkIfVersionSatisfied } from 'hooks/use-app-info'

const { apiServerHost, environmentType } = deploymentConfig

// register push

/*
  call when 
  uid change OR
  token change! :)

  token should always be prepared,,?
*/

export type PushNotificationPermissionState =
  | `prompt`
  | `prompt-with-rationale`
  | `granted`
  | `denied`

let latestPushNotificationPermissionState: PushNotificationPermissionState | null =
  null

export const getLatestPushNotificationPermissionState = () => {
  return latestPushNotificationPermissionState
}

export const openNotificationSettings = async () => {
  // needs deployment!
  if (await checkIfVersionSatisfied(`1.1.0`)) {
    NativeSettings.open({
      optionAndroid: AndroidSettings.AppNotification,
      optionIOS: IOSSettings.App,
    })
  }
}

export const checkPermissions = async () => {
  /*
    Checks permission, stores lastestPushNotificationPermission to disc
  */
  const { receive } = await PushNotifications.checkPermissions()
  await Preferences.set({
    key: `lastestPushNotificationPermission`,
    value: receive,
  })
  latestPushNotificationPermissionState = receive

  if (receive === `granted`) {
    await PushNotifications.register()
  }

  return receive
}

export const requestPermissions = async () => {
  /*
    Requests permission, stores lastestPushNotificationPermission to disc
    Automatically registers pushNotification
  */

  const { receive } = await PushNotifications.requestPermissions()
  await Preferences.set({
    key: `lastestPushNotificationPermission`,
    value: receive,
  })
  latestPushNotificationPermissionState = receive

  if (receive === `granted`) {
    await PushNotifications.register()
  }

  return receive
}

export const updatePushTokenState = async ({
  uniqueInstallationId,
  pushToken,
}: {
  uniqueInstallationId: string
  pushToken: string | null
}) => {
  const params = new URLSearchParams({
    uniqueInstallationId,
  })
  if (pushToken) {
    params.set(`pushToken`, pushToken)
  }

  console.log(`updating push token state! `)

  try {
    await request(`POST`, apiServerHost, `/update-push-token-state?${params}`, {
      auth: true,
    })
  } catch (e) {
    console.error(`error on action log : onSessionEnter`)
    console.error(e)
  }
}

/*
  creates uniqueInstallationId if not set previously.
  saves pushToken if given, try reads pushToken if set previously.

  sync trigger places : uid change, token change.
*/
export const syncPushTokenState = async (_pushToken?: string) => {
  let uniqueInstallationId = (
    await Preferences.get({ key: `uniqueInstallationId` })
  ).value
  if (!uniqueInstallationId) {
    console.log(`UniqueInstallationId not found! setting uniqueInstallationId!`)
    uniqueInstallationId = createUuidSecure(40)
    await Preferences.set({
      key: `uniqueInstallationId`,
      value: uniqueInstallationId,
    })
    console.log(`UniqueInstallationgId set to ${uniqueInstallationId}`)
  }

  // if pushToken provided, save it!
  // Why read? -> uid change!
  // else, read!

  let pushToken: string | null = null
  if (_pushToken) {
    await Preferences.set({
      key: `pushToken`,
      value: _pushToken,
    })
    pushToken = _pushToken
  } else {
    const lastestPushNotificationPermission = (
      await Preferences.get({ key: `lastestPushNotificationPermission` })
    ).value

    // latest permission null or granted, read the cached value.
    // if latest permission state change, we sync push token null (deregister)
    if (
      !lastestPushNotificationPermission ||
      lastestPushNotificationPermission === `granted`
    ) {
      pushToken = (await Preferences.get({ key: `pushToken` })).value
    }
    // try read
  }

  await updatePushTokenState({
    pushToken,
    uniqueInstallationId,
  })
}

declare global {
  interface Window {
    updatePushTokenState: typeof updatePushTokenState
  }
}

if (typeof window !== `undefined` && environmentType === `development`) {
  window.updatePushTokenState = updatePushTokenState
}
