type Dimension = [number, number]

/*
  SSR with defaultDimension, but elements shouldn't render defaultDimension dependent when SSR.
*/
export const defaultDimension: Dimension = [375, 667]
export const getScreenDimension = (): Dimension => {
  if (typeof window !== `undefined`) {
    const width = window.screen.width

    const height = window.screen.height
    return [width, height]
  }

  return defaultDimension
}
