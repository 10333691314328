import React from 'react'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { useGetter } from 'hooks/use-getter'

export type TimedCallbackType = `interval` | `timeout`
export type TimedFunction<T> = (args: T, end?: () => void) => void

type TimedFunctionHandle = number | undefined

const getRegisterFunction = (type: TimedCallbackType) => {
  if (type === `interval`) {
    return window.setInterval
  }
  return window.setTimeout
}

const getClearFunction = (type: TimedCallbackType) => {
  if (type === `interval`) {
    return window.clearInterval
  }
  return window.clearTimeout
}

type EndTimedMethod = () => void
type StartTimedMethod<T> = (args: T) => EndTimedMethod

const useTimedCallback = <T>(
  timedFunction: TimedFunction<T> | null,
  time: number,
  type: TimedCallbackType = `interval`,
): [StartTimedMethod<T>, EndTimedMethod, boolean] => {
  const [enabled, setEnabled] = React.useState(false)

  const getIsMounted = useGetIsMounted()
  const getEnabled = useGetter(enabled)
  const getTime = useGetter(time)
  const handle = React.useRef<TimedFunctionHandle>()

  const end = React.useCallback<EndTimedMethod>(() => {
    if (getEnabled()) {
      setEnabled(false)
      getClearFunction(type)(handle.current)
    }
  }, [getEnabled, type])

  const start = React.useCallback<StartTimedMethod<T>>(
    (args: T) => {
      if (getEnabled()) {
        end() // end!
      }

      setEnabled(true)
      handle.current = getRegisterFunction(type)(() => {
        if (!getIsMounted()) return

        if (type === `interval`) {
          // if interval, pass end callback
          if (timedFunction) {
            timedFunction(args, end)
          }
        } else {
          // if timeout, disable enabled
          setEnabled(false)
          if (timedFunction) {
            timedFunction(args)
          }
        }
      }, getTime())

      return end
    },
    [getTime, getEnabled, getIsMounted, type, end, timedFunction],
  )

  // cleanup
  React.useEffect(() => {
    return end
  }, [end])

  return [start, end, enabled]
}

export const useInterval = <T = void>(
  timedFunction: TimedFunction<T> | null,
  time: number,
) => {
  return useTimedCallback(timedFunction, time, `interval`)
}

export const useTimeout = <T = void>(
  timedFunction: TimedFunction<T> | null,
  time: number,
) => {
  return useTimedCallback(timedFunction, time, `timeout`)
}
