import React from 'react'
import { useRewardState } from 'hooks/use-reward-state'
import {
  useReviewableTargets,
  getShouldDisplayReviewableTargetDot,
} from 'hooks/use-reviewable-targets'
import {
  registerNotificationStateChangeListener,
  getNotificationExistsState,
} from 'model/app/notification'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { useIsSSR } from 'hooks/use-is-ssr'

/*
  TODO strange!
  why not use the widely used listener pattern?
*/
export const useNotificationExists = () => {
  const isSSR = useIsSSR()
  const getIsMounted = useGetIsMounted()
  const rewardState = useRewardState()
  const reviewableTargets = useReviewableTargets()
  const [notificationExistsState, setNotificationExistsState] = React.useState(
    getNotificationExistsState(),
  )

  React.useEffect(() => {
    setNotificationExistsState(getNotificationExistsState())
    return registerNotificationStateChangeListener((e) => {
      if (getIsMounted()) {
        setNotificationExistsState(e)
      }
    })
  }, [getIsMounted])

  const state =
    React.useMemo<API.Notification.GetNotificationExistsResponse>(() => {
      if (isSSR) {
        return {
          ...notificationExistsState,
        }
      }

      const result = {
        ...notificationExistsState,
      }

      if (rewardState === `requestable`) {
        result.reward = true
      }

      if (
        reviewableTargets.totalRateRewardAmount > 0 &&
        getShouldDisplayReviewableTargetDot()
      ) {
        result.reward = true
      }

      return result
    }, [isSSR, notificationExistsState, rewardState, reviewableTargets])

  return state
}
