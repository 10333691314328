import React from 'react'

/*
    Compose forwarded ref and mutable ref
*/
export const useComposeRefs = <T>(
  ref1: React.ForwardedRef<T>,
  ref2?: React.MutableRefObject<T> | ((ref: T) => void),
) => {
  const setRef = React.useCallback(
    (node: T) => {
      if (ref1) {
        if (`current` in ref1) {
          // mutable ref
          /* eslint-disable-next-line no-param-reassign */
          ;(ref1 as React.MutableRefObject<T>).current = node
        } else {
          // function ref
          ;(ref1 as (instance: T | null) => void)(node)
        }
      }
      if (ref2) {
        if (typeof ref2 === `function`) {
          ref2(node)
        } else {
          /* eslint-disable-next-line no-param-reassign */
          ref2.current = node
        }
      }
    },
    [ref1, ref2],
  )

  return setRef
}
