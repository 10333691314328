import React from 'react'
import { useComposeRefs } from 'hooks/use-compose-refs'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'
import { useGetter } from 'hooks/use-getter'
import { useIsFullSSR } from 'hooks/use-is-full-ssr'
import { useFieldIsFocusedHandle } from 'hooks/use-keyboard-popped'

import * as styles from './style.module.less'

type TextFieldShapeOption = `narrow` | `default`

type onChangeHandler = (
  value: string,
  extraOptions?: { target: HTMLInputElement; publishedNullValue: boolean },
) => void

type onFocusChangeHandler = (focused: boolean) => void

type Autocomplete = React.HTMLProps<HTMLInputElement>['autoComplete']
type Type = React.HTMLProps<HTMLInputElement>['type']
type OnKeyDown = React.HTMLProps<HTMLInputElement>['onKeyDown']
type OnClick = React.HTMLProps<HTMLInputElement>['onClick']
type Name = React.HTMLProps<HTMLInputElement>['name']
type EntryKetHint = React.InputHTMLAttributes<HTMLInputElement>['enterKeyHint']

interface TextFieldProps {
  id: string // id is must for text fields
  className?: string

  // value
  label: string
  value: string
  helperText?: string | null
  error?: string | null | boolean // error code
  nullValue?: string
  placeholder?: string | null
  onChange: onChangeHandler
  onFocusChange?: onFocusChangeHandler
  disabled?: boolean

  // html
  type?: Type
  autoComplete?: Autocomplete
  onKeyDown?: OnKeyDown
  onClick?: OnClick
  name?: Name
  enterKeyHint?: EntryKetHint

  // interface
  disableLabelPin?: boolean
  leftAdornment?: React.ReactNode
  rightAdornment?: React.ReactNode
  publishNullValue?: boolean
  displayNullValue?: boolean
  prefix?: string | null
  suffix?: string | null
  size?: number
  option?: TextFieldShapeOption
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      id,
      className: _className,
      // value
      label,
      helperText,
      error,
      value,
      nullValue,
      placeholder,
      onChange,
      onFocusChange,
      disabled = false,
      // html
      type = `text`,
      autoComplete = `off`,
      onKeyDown,
      onClick,
      name,
      enterKeyHint,
      // interface
      disableLabelPin = false,
      leftAdornment = null,
      rightAdornment = null,
      publishNullValue = false, // empty value is filled with null value!
      displayNullValue = true, // if false, using nullValue only for disable style!
      prefix = null,
      suffix = null,
      size = 20,
      option = `default`, // narrow or default,
    },
    externalRef,
  ) => {
    const fieldIsFocusedHandle = useFieldIsFocusedHandle()
    const isFullSSR = useIsFullSSR()
    const inputRef = React.useRef<HTMLInputElement>(null)
    const ref = useComposeRefs(externalRef, inputRef)
    const useNullValue = typeof nullValue !== `undefined`
    const [focused, setFocused] = React.useState(false)
    const getIsMounted = useGetIsMounted()
    const legendSpanRef = React.useRef(null)

    const onFocus = React.useCallback(() => {
      fieldIsFocusedHandle(id, true)
      setFocused(true)
      if (onFocusChange) {
        onFocusChange(true)
      }
    }, [onFocusChange, fieldIsFocusedHandle, id])

    const onBlur = React.useCallback(() => {
      fieldIsFocusedHandle(id, false)

      if (!getIsMounted()) return
      if (useNullValue && inputRef.current) {
        if (
          value &&
          (typeof value === `string` ? value.trim() : value) === nullValue &&
          !publishNullValue && // if null value set only for display! no replacing it with nullValue!
          displayNullValue
        ) {
          inputRef.current.value = ``
          onChange(inputRef.current.value, {
            target: inputRef.current,
            publishedNullValue: false,
          })
        } else if (
          (!value || (typeof value === `string` && !value.trim())) &&
          publishNullValue
        ) {
          // replacing with nullValue!
          inputRef.current.value = nullValue
          onChange(inputRef.current.value, {
            target: inputRef.current,
            publishedNullValue: true,
          })
        }
      }

      setFocused(false)
      if (onFocusChange) {
        onFocusChange(false)
      }
    }, [
      fieldIsFocusedHandle,
      id,
      onFocusChange,
      useNullValue,
      value,
      nullValue,
      inputRef,
      onChange,
      getIsMounted,
      publishNullValue,
      displayNullValue,
    ])

    React.useEffect(() => {
      return () => {
        fieldIsFocusedHandle(id, false)
      }
    }, [fieldIsFocusedHandle, id])

    const getOnFocus = useGetter(onFocus)
    const getOnBlur = useGetter(onBlur)

    const commonClass = `${option === `narrow` ? styles.narrow : ``} ${
      prefix ? styles.prefixed : ``
    } ${suffix ? styles.suffixed : ``}`

    let displayValue = value
    if (!focused && useNullValue && !value && displayNullValue) {
      // display empty value with nullValue
      displayValue = nullValue
    }
    displayValue = displayValue || ``

    const disableStyle =
      useNullValue &&
      displayValue &&
      (typeof displayValue === `string`
        ? displayValue.trim()
        : displayValue) === nullValue
    const labelUp = (focused || displayValue) && !disableLabelPin
    const className = `${_className} ${
      disableStyle || disabled ? styles.disabled : ``
    }`

    const hasHelperText = !!helperText
    const hasError = !!error

    const onChangeWrapper = React.useCallback(
      ({ target }: { target: HTMLInputElement }) => {
        onChange(target.value, {
          target,
          publishedNullValue: false,
        })
      },
      [onChange],
    )

    const inputElement = React.useMemo(() => {
      return (
        <input
          autoComplete={autoComplete || `off`}
          aria-invalid={hasError ? `true` : `false`}
          id={id}
          type={type}
          value={displayValue}
          name={name}
          onChange={onChangeWrapper}
          aria-describedby={hasHelperText ? `${id}-helper-text` : undefined}
          disabled={disabled}
          ref={ref}
          size={size}
          onFocus={() => getOnFocus()()}
          onBlur={() => getOnBlur()()}
          placeholder={
            // while full ssr, disable label render but display placeholder instead.
            // on other scenarios, skip placeholder render when label exists and not focused (display placeholder only when focused)
            isFullSSR || !(label && !focused) ? placeholder || undefined : ``
          }
          className={`${commonClass}`}
          onKeyDown={onKeyDown}
          onClick={onClick}
          onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
            // https://stackoverflow.com/a/51076231
            const target = e.target as HTMLInputElement
            if (typeof document !== `undefined`) {
              if (document.activeElement === target) {
                target.blur()
              }
            }
          }}
          enterKeyHint={enterKeyHint}
        />
      )
    }, [
      autoComplete,
      commonClass,
      hasError,
      id,
      type,
      displayValue,
      name,
      hasHelperText,
      disabled,
      ref,
      size,
      getOnFocus,
      getOnBlur,
      label,
      focused,
      placeholder,
      onKeyDown,
      onClick,
      onChangeWrapper,
      isFullSSR,
      enterKeyHint,
    ])
    /*
   css class
   element or not.
   focused or not.
   error or not.
   helper or not


   legend span only when text input
   */
    // border-radius for fieldSet, padding for input
    /* eslint-disable jsx-a11y/label-has-for */
    return (
      <div
        className={`${styles.rootContainer} ${className || ``} ${
          disableLabelPin ? styles.disableLabelPin : ``
        } ${leftAdornment ? styles.leftAdornmentExists : ``} ${
          rightAdornment ? styles.rightAdornmentExists : ``
        }`}
      >
        <div className={`${styles.inputContainer} ${commonClass}`}>
          {leftAdornment ? (
            <div
              className={`${styles.leftAdornmentContainer} ${styles.adornmentContainer} input-style ${commonClass}`}
            >
              {leftAdornment}
            </div>
          ) : null}
          {prefix ? (
            <span
              className={`${styles.prefixContainer} input-style ${commonClass}`}
            >
              <span className={`${styles.prefixInnerContainer} ${commonClass}`}>
                {prefix}
              </span>
            </span>
          ) : null}
          {inputElement}
          {suffix ? (
            <span
              className={`${styles.suffixContainer} input-style ${commonClass}`}
            >
              <span className={`${styles.suffixInnerContainer} ${commonClass}`}>
                {suffix}
              </span>
            </span>
          ) : null}
          {rightAdornment ? (
            <div
              className={`${styles.rightAdornmentContainer} ${styles.adornmentContainer} input-style ${commonClass}`}
            >
              {rightAdornment}
            </div>
          ) : null}

          {label ? (
            <fieldset
              aria-hidden="true"
              className={`${focused && styles.fieldsetFocused} 
            ${error && styles.fieldsetError} 
            ${commonClass}`}
            >
              <legend className={`${labelUp && styles.legendUp}`}>
                <span ref={legendSpanRef}>{label}</span>
              </legend>
            </fieldset>
          ) : null}
        </div>
        {label ? (
          <label
            htmlFor={id}
            id={`${id}-label`}
            className={`${labelUp && styles.labelUp} 
        ${
          (focused && !disableLabelPin && styles.labelFocused) ||
          styles.labelPlaceholder
        } 
        ${error && styles.labelError} 
        ${commonClass}
        ${isFullSSR ? styles.labelFullSsr : ``}
        input-style
        `}
          >
            {value && disableLabelPin ? null : label}
          </label>
        ) : null}
        {helperText ? (
          <p
            id={`${id}-helper-text`}
            className={`${styles.helperText} helper-text ${
              error ? styles.helperTextError : styles.helperTextNormal
            } ${commonClass}`}
          >
            {helperText}
          </p>
        ) : null}
      </div>
    )
  },
)
