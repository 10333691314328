import React from 'react'
import type { ParsedLocation } from '../types'
/*

*/

interface PaymentQuery {
  category?: string
  easyPay?: string
  cardCompany?: string
  transactionCreateResult?: string
  pg_token?: string // kakao
  orderId?: string // tossPayment
  // toss success
  paymentKey?: string
  amount?: string
  // toss fail
  code?: string
  message?: string
}

export const PaymentQueryContext = React.createContext<PaymentQuery>({})

export const PaymentQueryProvider = ({
  location,
  children,
}: {
  location: ParsedLocation
  children: React.ReactNode
}) => {
  const paymentQueryBox = React.useRef<PaymentQuery>({})

  React.useMemo(() => {
    const {
      category,
      easyPay,
      cardCompany,
      transactionCreateResult,
      /* eslint-disable-next-line camelcase */
      pg_token, // kakao
      orderId, // tossPayment
      // toss success
      paymentKey,
      amount,
      // toss fail
      code,
      message,
      //
    } = location.query

    const expectedPaymentQuery: PaymentQuery = {
      category,
      easyPay,
      cardCompany,
      transactionCreateResult,
      /* eslint-disable-next-line camelcase */
      pg_token, // kakao
      orderId, // tossPayment
      // toss success
      paymentKey,
      amount,
      // toss fail
      code,
      message,
    }

    let equal = true
    const keys = [
      ...Object.keys(expectedPaymentQuery),
      ...Object.keys(paymentQueryBox.current),
    ]

    keys.forEach((key) => {
      if (
        expectedPaymentQuery[key as keyof PaymentQuery] !==
        paymentQueryBox.current[key as keyof PaymentQuery]
      ) {
        equal = false
      }
    })

    if (!equal) {
      paymentQueryBox.current = expectedPaymentQuery
    }
  }, [location])

  return (
    <PaymentQueryContext.Provider value={paymentQueryBox.current}>
      {children}
    </PaymentQueryContext.Provider>
  )
}

export const usePaymentQuery = () => {
  return React.useContext(PaymentQueryContext)
}
