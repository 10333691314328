import React from 'react'
import { Capacitor } from '@capacitor/core'
import { navigate } from 'hooks/use-location'
import { getMobileWebOS } from 'util/environment'
import { useIsSSR } from 'hooks/use-is-ssr'
import { useAsyncEffect } from 'hooks/use-async-effect'
import { useGetIsMounted } from 'hooks/use-get-is-mounted'

export const playStoreUrl = `https://play.google.com/store/apps/details?id=kr.co.roout.app`
export const appStoreUrl = `https://apps.apple.com/kr/app/id6446498982`

/*
    
    if slug provided, open slug in app.
    else, open current slug in app if installed.
    else, open store. great!

    best if called inside synchronous methods;

    Where do we call this?
    App redirect interfaces : web client render => log in overlay, in home menu modal;

    No automatic open!! 
*/

interface InstalledRelatedApp {
  id: string
  platform: string
  url: string
  version: string
}

declare global {
  interface Navigator {
    getInstalledRelatedApps: () => Promise<InstalledRelatedApp[]>
  }
}

export const getCanOpenInApp = async () => {
  let appInstalled = false
  if (window?.navigator?.getInstalledRelatedApps) {
    const installedRelatedApps =
      await window.navigator.getInstalledRelatedApps()
    if (installedRelatedApps.find((e) => e.id === `kr.co.roout.app`)) {
      // found!
      appInstalled = true
    }
  }

  return appInstalled
}

export const openInApp = async (slug?: string) => {
  if (Capacitor.isNativePlatform()) {
    // just navigate!
    if (slug) {
      navigate(slug)
    }
    // if no slug, do nothing.
    return
  }

  const mobileOS = getMobileWebOS()
  const canOpenInApp = await getCanOpenInApp()

  if (mobileOS === `android`) {
    if (canOpenInApp) {
      window.location.protocol = `roout:`
    } else {
      window.open(playStoreUrl, `_self`)
    }
  } else if (mobileOS === `ios`) {
    if (canOpenInApp) {
      // getInstalledRelatedApps not supported in ios tho
      window.location.protocol = `roout:`
    } else {
      window.open(appStoreUrl, `_self`)
    }
  }
}

export const useCanOpenInApp = () => {
  const isSSR = useIsSSR()
  const getIsMounted = useGetIsMounted()
  const [canOpenInApp, setCanOpenInApp] = React.useState(false)
  // eslint-disable-next-line
  useAsyncEffect(async () => {
    if (isSSR) {
      return
    }

    if (Capacitor.isNativePlatform()) {
      return
    }

    const canOpenInApp = await getCanOpenInApp()
    if (getIsMounted()) {
      setCanOpenInApp(canOpenInApp)
    }
  }, [getIsMounted, isSSR])
  return canOpenInApp
}
