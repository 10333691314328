import React from 'react'
import { useViewportWidth } from 'hooks/use-viewport-dimension'
import { navigateBack, getInnerNavigated } from 'hooks/use-location'
import { useSearchBar, UseSearchBarProps } from 'hooks/use-search-bar'
import { breakpoints } from 'src/theme'
import { useIsSSR } from 'hooks/use-is-ssr'
import Back from 'assets/images/svgs/back.svg'
import LogoIcon from 'assets/images/svgs/logo.svg'
import { Link } from 'components/link'
import { useIntersectionObserver } from 'hooks/use-intersection-observer'
import { useIsFullSSR } from 'hooks/use-is-full-ssr'
import { useBoxedCallback } from 'hooks/use-boxed-callback'
import { useStateRef } from 'hooks/use-state-ref'

import * as searchBarStyles from 'src/hooks/use-search-bar/style.module.less'
import * as styles from './style.module.less'

export const BackButton = ({
  className,
  onClick,
}: {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  className?: string
}) => {
  const isSSR = useIsSSR() // ssr, try render logo?
  const innerNavigated = getInnerNavigated() // if inner navigated, back!

  const viewportWidth = useViewportWidth()

  const renderBackButton = !isSSR && innerNavigated
  const renderLogo =
    !renderBackButton && viewportWidth < breakpoints.smallTablet

  if (!renderBackButton && !renderLogo) {
    return null
  }

  return (
    <Link
      className={styles.backButton}
      to="/"
      aria-label="뒤로 가기"
      onClick={
        onClick ||
        ((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          if (e && e.preventDefault) {
            e.preventDefault()
          }
          navigateBack({ defaultParentUrl: `/` })
        })
      }
    >
      {!isSSR && innerNavigated ? (
        <Back className={className} />
      ) : (
        <LogoIcon
          className={className}
          alt="루트 - 수수료 없는 진짜 농수산물 직거래 플랫폼"
          width="94px"
          height="25px"
        />
      )}
    </Link>
  )
}

interface HeaderProps {
  id?: string
  className?: string
  halfTransparentAtTop?: boolean
  totallyTransparentAtTop?: boolean
  left?: React.ReactNode
  center?: React.ReactNode
  right?: React.ReactNode
  bottom?: React.ReactNode
  includePadding?: boolean
  hideLeftDesktop?: boolean
  hideRightDesktop?: boolean
  hideCenterDesktop?: boolean
  hideBottomDesktop?: boolean
  useSearchBarProps?: UseSearchBarProps
}

const defaultSearchBarProps: UseSearchBarProps = {
  formId: `default-search-bar-id`,
}

export const Header = (props: HeaderProps) => {
  const {
    id,
    className,
    halfTransparentAtTop = false,
    totallyTransparentAtTop = false,
    left = null,
    center = null,
    right = null,
    bottom = null,
    includePadding = true,
    hideLeftDesktop = false,
    hideRightDesktop = false,
    hideCenterDesktop: _hideCenterDesktop = false,
    hideBottomDesktop = false,
    useSearchBarProps,
  } = props

  const isFullSSR = useIsFullSSR()

  const searchInputRef = React.useRef<HTMLInputElement>(null)
  const searchBarProps = React.useMemo(() => {
    const base = useSearchBarProps || defaultSearchBarProps
    return {
      className: searchBarStyles.headerSearchBarContainer,
      ...base,
      searchInputRef,
    }
  }, [searchInputRef, useSearchBarProps])
  const [searchBar] = useSearchBar(searchBarProps)

  const viewportWidth = useViewportWidth()
  const isDesktop = viewportWidth >= breakpoints.smallTablet

  const isRenderingCenterSearchBar =
    useSearchBarProps && (!isDesktop || isFullSSR)

  const centerElement = React.useMemo(() => {
    if (isRenderingCenterSearchBar) {
      return searchBar
    }
    if (!center) return null
    if (typeof center === `string`) {
      // overridden in upload-post
      return <h1 className="t center-text">{center}</h1>
    }

    return center
  }, [isRenderingCenterSearchBar, center, searchBar])

  /*
    Not really a good design.
    search Input should focus only when user touched search bar icon in mobile environment to open search bar.
  */
  const prevIsRenderingCenterSearchBarBox = React.useRef(
    isRenderingCenterSearchBar,
  )
  React.useEffect(() => {
    if (
      !prevIsRenderingCenterSearchBarBox.current &&
      isRenderingCenterSearchBar &&
      searchInputRef.current
    ) {
      searchInputRef.current.focus()
    }
  }, [isRenderingCenterSearchBar])

  // mobile : header exist regardless of element??
  // is there a header without elements? hmm

  const hideCenterDesktop = _hideCenterDesktop || useSearchBarProps

  const leftExists = !!left
  const rightExists = !!right
  const centerExists = !!center || !!useSearchBarProps
  const topExists = !!(leftExists || rightExists || centerExists)
  const bottomExists = !!bottom
  const anyExists = !!(topExists || bottomExists)

  const hideTopDesktop =
    (!leftExists || hideLeftDesktop) &&
    (!rightExists || hideRightDesktop) &&
    (!centerExists || hideCenterDesktop)

  const hideAllDesktop = hideTopDesktop && (!bottomExists || hideBottomDesktop)

  const leftContainerClass = `${styles.leftContainer} ${
    !leftExists ? styles.empty : ``
  } ${hideLeftDesktop ? styles.hideDesktop : ``}`
  const rightContainerClass = `${styles.rightContainer} ${
    !rightExists ? styles.empty : ``
  } ${hideRightDesktop ? styles.hideDesktop : ``}`
  const centerContainerClass = `${styles.headerCenterContainer} ${
    !centerExists ? styles.empty : ``
  } ${hideCenterDesktop ? styles.hideDesktop : ``} ${
    useSearchBarProps ? styles.search : `` // hide center desktop regardless of hideCenterDesktop?
  }`
  // use search bar is only for mobile!
  // desktop navigation should be done in top-navigation-bar
  const topContainerClass = `${styles.topContainer} ${
    !topExists ? styles.empty : ``
  } ${hideTopDesktop ? styles.hideDesktop : ``}`
  const bottomContainerClass = `${styles.bottomContainer} ${
    !bottomExists ? styles.empty : ``
  } ${hideBottomDesktop ? styles.hideDesktop : ``}`

  const allContainerClass = `${className || ``} ${
    !anyExists ? styles.empty : ``
  }  
    ${hideAllDesktop ? styles.hideDesktop : ``}  
  ${hideCenterDesktop ? styles.hideCenterDesktop : ``} 
    ${hideBottomDesktop ? styles.hideBottomDesktop : ``}
  `

  const [isHeaderAtTop, setIsHeaderAtTop] = React.useState(true)

  const headerIntersectionTagBox = useStateRef<HTMLDivElement>(null)
  const headerIntersectionTag = headerIntersectionTagBox.current
  const intersectionRefs = React.useMemo(() => {
    return [headerIntersectionTag]
  }, [headerIntersectionTag])

  const onIntersectionObserver = useBoxedCallback(
    (entries: IntersectionObserverEntry[]) => {
      /*
        only listening one!
      */
      const entry = entries[0]
      if (entry) {
        if (entry.isIntersecting) {
          setIsHeaderAtTop(true)
        } else {
          setIsHeaderAtTop(false)
        }
      }
    },
    [],
  )

  useIntersectionObserver(
    intersectionRefs,
    onIntersectionObserver,
    `body`,
    `0px`,
    0.1,
  )

  if (!anyExists) {
    return null
  }

  return (
    <>
      <header
        className={`${styles.rootContainer} ${allContainerClass} ${
          isHeaderAtTop ? styles.atTop : styles.scrolledDown
        } ${totallyTransparentAtTop ? styles.totallyTransparentAtTop : ``} ${
          halfTransparentAtTop ? styles.halfTransparentAtTop : ``
        } ${
          !halfTransparentAtTop && !totallyTransparentAtTop
            ? styles.blurAtTop
            : ``
        }`}
        id={id}
      >
        <div className={styles.widthWrapper}>
          <div className={topContainerClass}>
            <div className={centerContainerClass}>{centerElement}</div>
            <div className={leftContainerClass}>{left}</div>
            <div className={rightContainerClass}>{right}</div>
          </div>
          <div className={bottomContainerClass}>{bottom}</div>
        </div>
      </header>
      <div
        className={styles.headerIntersectionTag}
        ref={headerIntersectionTagBox}
      />
      {includePadding && !totallyTransparentAtTop ? (
        <div
          className={`${styles.headerPaddingDummy} ${
            !topExists ? styles.empty : ``
          } ${hideTopDesktop ? styles.hideDesktop : ``}`}
        />
      ) : null}
    </>
  )
}
