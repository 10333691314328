import { select } from 'src/util/select'
import {
  handleDefaultEvent,
  getProductData,
  getCommerceObject,
  GetProductDataArgs,
  GetCommerceObjectArgs,
} from 'src/services/analytics/events/util'

import analyticsConfig from 'values/analytics.json'

const { enableAnalytics } = analyticsConfig

export const onAddToCart = (args: GetProductDataArgs) => {
  const { productData } = args
  const [price] = select(productData, [`public.content.price`])
  const data = getProductData(args)

  handleDefaultEvent(`add_to_cart`, {
    ecommerce: {
      currency: `KRW`,
      value: price,
      items: [data],
    },
  })
}

export const onChangeCount = (
  // eslint-disable-next-line
  args: GetProductDataArgs,
  // eslint-disable-next-line
  targetCount: number,
) => {
  if (!enableAnalytics) {
    console.log(`onChangeCount`)
  }

  if (enableAnalytics) {
    if (window.logAnalytics) {
      // needs custom implementation
      console.log(`onChangeCount not implemented`)
    }
  }
}

export const onChangeMessage = (
  // eslint-disable-next-line
  args: GetProductDataArgs,
  // eslint-disable-next-line
  targetMessage: string,
) => {
  if (!enableAnalytics) {
    console.log(`onChangeMessage`)
  }

  if (enableAnalytics) {
    if (window.logAnalytics) {
      // needs custom implementation
      console.log(`onChangeMessageonChangeMessage not implemented`)
    }
  }
}

export const onRemoveFromCart = (args: GetProductDataArgs) => {
  const data = getProductData(args)
  handleDefaultEvent(`remove_from_cart`, {
    ecommerce: {
      value: data.price,
      currency: `KRW`,
      items: [data],
    },
  })
}

export const onOpenPaymentOverlayFAB = () => {
  if (!enableAnalytics) {
    console.log(`onOpenPaymentOverlayFAB`)
  }

  if (enableAnalytics) {
    if (window.logAnalytics) {
      // needs custom implementation
      console.log(`onOpenPaymentOverlayFAB not implemented`)
    }
  }
}

export const onOpenCartItems = (args: GetCommerceObjectArgs) => {
  handleDefaultEvent(`view_cart`, {
    ecommerce: getCommerceObject(args),
  })
}
