import { registerPlugin } from '@capacitor/core'
import { select } from 'util/select'
import tossPaymentsConfig from 'values/tosspayments-config.json'
import { navigate } from 'hooks/use-location'

const { clientKey, testClientKey } = tossPaymentsConfig

export interface TosspaymentsPlugin {
  /*
    amount : Number
 
    orderId: String, 
    orderName: String, 
    customerEmail: String?, 
    customerName: String?,
    taxFreeAmount: Number?
  */

  requestPayment(options: {
    paymentType: `card` | `easyPay` | `account`
    clientKey: string //
    customerKey: string // uid
    amount: number
    orderId: string // orderSetId
    orderName: string // paymentItemName
    customerName: string
    //  customerEmail : string
    taxFreeAmount: number
    //

    customerMobilePhone?: string
    cardCompany?: CardCompany // Card
    easyPay?: EasyPay // EasyPay

    validHours?: number // account
    // TODO : cashReceipt currently always being set to 소득공제 hmm...
    cashReceiptType?: `미발급` | `소득공제` | `지출증빙` // cashReceipt number not being provided for now. hmm,,,
  }): Promise<{
    orderId: string
    amount?: number // if success
    paymentKey?: string // if success
    errorCode?: string // if fail
    errorMessage?: string // if fail
  }>
}

export const Tosspayments = registerPlugin<TosspaymentsPlugin>(`Tosspayments`)

/*
  TODO handle account! => it might be the same with card,,? hmm

  1. tosspayment requestPayment 
*/
export const requestPayment = async (
  paymentData:
    | API.Order.TossAccountOrderCreatedPaymentData
    | API.Order.TossCardPhoneOrderCreatedPaymentData,
  {
    customerMobilePhone,
    customerName,
    userId,
  }: {
    customerMobilePhone?: string
    customerName: string
    userId: string
  },
) => {
  const { paymentMethodInfo, specification, orderSetId } = paymentData
  const { isTest, category /* , gateway */ } = paymentMethodInfo
  // are we using category and gateway?? hmm

  const { cardCompany } = paymentMethodInfo as OrderCreatedCardPaymentMethodInfo
  const { easyPay } = paymentMethodInfo as OrderCreatedEasyPayPaymentMethodInfo

  const [totalPaymentAmount, totalPaymentTaxFreeAmount, paymentItemName] =
    select(specification, [
      `totalPaymentAmount.totalAmount`,
      `totalPaymentAmount.taxFreeAmount`,
      `paymentItemName`,
    ])

  if (category === `direct`) {
    throw new Error(`Invalid category : ${category}`)
  }

  const url = new URL(window.location.href)
  if (url.hash) {
    // replace hash!
    url.searchParams.set(`anchor`, url.hash.replace(/^#/, ``))
    url.hash = ``
  }

  url.searchParams.set(`category`, category)
  if (easyPay) {
    url.searchParams.set(`easyPay`, easyPay)
  }

  if (cardCompany) {
    url.searchParams.set(`cardCompany`, cardCompany)
  }

  const successUrl = new URL(url)
  successUrl.searchParams.set(`transactionCreateResult`, `approval`)
  const failUrl = new URL(url)
  failUrl.searchParams.set(`transactionCreateResult`, `fail`)

  // eslint-disable-next-line
  const { orderId, amount, paymentKey, errorCode, errorMessage } =
    await Tosspayments.requestPayment({
      paymentType: category,
      clientKey: isTest ? testClientKey : clientKey,
      customerKey: userId,
      amount: totalPaymentAmount,
      orderId: orderSetId,
      orderName: paymentItemName,
      customerName: customerName.substring(0, 10),
      taxFreeAmount: totalPaymentTaxFreeAmount,
      //
      cardCompany,
      easyPay,
      //
      customerMobilePhone,
      validHours: 1,
      cashReceiptType: `소득공제`,
    })

  if (paymentKey) {
    // set amount
    // set paymentKey
    successUrl.searchParams.set(`orderId`, orderId)
    successUrl.searchParams.set(`paymentKey`, paymentKey)
    successUrl.searchParams.set(`amount`, `${amount}`)
    // window.location.href = `${successUrl}`
    navigate(successUrl.pathname + successUrl.search + successUrl.hash)
  } else {
    // set code
    // set message
    failUrl.searchParams.set(`orderId`, orderId)
    failUrl.searchParams.set(`code`, `${errorCode}`)
    failUrl.searchParams.set(`message`, `${errorMessage}`)
    // window.location.href = `${failUrl}`
    navigate(failUrl.pathname + failUrl.search + failUrl.hash)
  }
}
